export const styles = {
  root: {
    width: "100%",
  },
  machineInfoContainer: {
    display: "flex",
    marginBottom: "2rem",
    alignItems: "center",
    mb: 3,
  },
  circleDivider: {
    mx: 2,
    borderRadius: 50,
    width: "5px",
    backgroundColor: "text.secondary",
    height: "5px",
  },
  topContainer: {
    a: {
      ":hover": {
        cursor: "pointer",
      },
    },
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    color: "text.secondary",
  },
}
