export const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: 1,
  },
  text: {
    pb: 5,
    fontWeight: 700,
    color: "text.secondary",
  },
  image: {
    marginTop: "8px",
    marginBottom: "8px",
  },
}
