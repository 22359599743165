import React from "react";
import BaseCard from "core/components/AppCard/BaseCard";
import { getDepartmentsSelector } from "store/Department/departmentSlice";
import { RootState } from "store/rootReducer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import DepartmentsTabComponent from "../DepartmentsTabComponent";
import ProdLinesAccordion from "../DepartmentsTabComponent/subcomponents/ProdLinesAccordion";
import { styles } from "./styles";

const MachinesDatatableCard = () => {
  const sitesState = (state: RootState) => state.sites
  const { selectedSiteId } = useSelector(sitesState)

  const selectedDepartment = useSelector((state: RootState) =>
    getDepartmentsSelector(state, selectedSiteId),
  )

  const { t } = useTranslation()

  const currentDepartment = useSelector(
    (state: RootState) => state.departments?.departmentId,
  )

  return (
    <BaseCard title={t("category.machines")} sx={styles.container}>
      <DepartmentsTabComponent
        value={currentDepartment ?? false}
        departmentsCollection={selectedDepartment}
      />
      {selectedDepartment[0]?.production_line_collection?.map(
        (prodLine, prodLineIndex: number) => (
          <ProdLinesAccordion
            key={prodLine.production_line_id}
            prodLineId={prodLine.production_line_id}
            index={prodLineIndex}
            machineCollection={prodLine.machine_collection}
          />
        ),
      )}
    </BaseCard>
  )
}

export default MachinesDatatableCard
