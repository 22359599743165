import React from "react"

import AppTypography from "core/components/AppTypography"
import { RootState } from "store/rootReducer"
import { tooltipTextHelper } from "core/helpers"
import { useSelector } from "react-redux"

import { Box, Tooltip } from "@mui/material"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import { styles } from "./styles"
import { AppIcon } from "./subcomponents/AppIcon"

interface IGraphBaseHeaderProps {
  title: string
  iconCode: string
  tooltipText?: { en: string; el: string }
}

export type AppIconProps = {
  iconCode?: string
  sx?: any
}

const GraphBaseHeader = ({
  title,
  iconCode,
  tooltipText,
}: IGraphBaseHeaderProps) => {
  const { language } = useSelector((state: RootState) => state.global)
  // I want to make a jsx element out of the iconCode so I can use it as a component

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerContainer}>
        <Box sx={styles.headerLabel}>
          <Box sx={styles.headerTemperature}>
            <AppIcon iconCode={iconCode} />

            <AppTypography
              textTransform="capitalize"
              sx={styles.headerTempLabel}
            >
              {title}
            </AppTypography>
            <Tooltip title={tooltipTextHelper(language, tooltipText)}>
              <InfoOutlinedIcon sx={styles.infoIcon} />
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default GraphBaseHeader
