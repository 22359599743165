import { apiSlice } from "store/queryApi";
import { DepartmentCollection } from "models/interfaces/department";
import { IInitialStateProduction } from "types/store";
import { ISite } from "models/interfaces/site";
import { organizationApiSlice } from "store/Organisation/organizationSlice";
import ProductionLineAPI, {
    IProductionLineInfo,
} from "core/api/ProductionLineApi";
import { RootState } from "store/rootReducer";

import { createSlice } from "@reduxjs/toolkit";

export const initialState: IInitialStateProduction = {
  productionLineId: null,
  productionLineSelected: null,
  productionLinesCollection: [],
  productionLineCollectionDatatable: null,
}

const productionLineSlice = createSlice({
  name: "production_Lines",
  initialState,
  reducers: {
    setProductionLine: (state, action) => {
      state.productionLineId = action.payload
    },
    setSelectedProductionLine: (state, action) => {
      state.productionLineSelected = action.payload
    },
    setProductionLineCollectionDatatable: (state, action) => {
      state.productionLineCollectionDatatable = action.payload
    },
  },
})

const productionLineApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProductionLineInfo: builder.query<IProductionLineInfo, string>({
      query: (productionLineId) => ({
        api: ProductionLineAPI.getProductionLineInfo(productionLineId),
      }),
      keepUnusedDataFor: 5,
    }),
  }),
})

export const getProductionLineSelector = (
  state: RootState,
  departmentId: string | null,
) => {
  if (!departmentId) return []
  const { organizationId = null } = state.organizations ?? {}
  const { selectedSiteId = null } = state.sites ?? {}

  if (
    organizationApiSlice.endpoints.getOrganization.select({
      uuid: organizationId,
    })(state).data === undefined
  )
    return []

  const site: ISite | undefined =
    organizationApiSlice.endpoints.getOrganization
      .select({ uuid: organizationId })(state)
      ?.data?.site_collection?.find(
        (site: ISite) => site.site_id === selectedSiteId,
      ) ?? undefined

  if (!site) return []

  const productionLines =
    site.department_collection.find(
      (department: DepartmentCollection) =>
        department.department_id === departmentId,
    )?.production_line_collection ?? []
  return productionLines
}

export const { useGetProductionLineInfoQuery } = productionLineApiSlice

export const {
  setProductionLine,
  setSelectedProductionLine,
  setProductionLineCollectionDatatable,
} = productionLineSlice.actions

export default productionLineSlice.reducer
