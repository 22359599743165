import * as yup from "yup"

export const schema = yup.object().shape({
  email: yup.string().required("error.required").email("error.invalidMail"),
  password: yup
    .string()
    .required("error.required")
    .min(5, "error.passwordLength"),
})
export type FormData = yup.InferType<typeof schema>
