import AppTypography from "core/components/AppTypography"
import { t } from "i18next"
import React from "react"
import { DurationButtons } from "../../DurationButtons"
import { Box } from "@mui/material"

const MachineHealthHeader = () => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      px: 1,
      pt: 1,
    }}
  >
    <AppTypography variant="h2">{t("machine.health.text")}</AppTypography>
    <DurationButtons />
  </Box>
)

export default MachineHealthHeader
