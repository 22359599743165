export const styles = {
  container: {
    position: "relative",
    right: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  triangle: {
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderBottom: "8px solid",
    borderBottomColor: "primary.main",
    margin: "0 auto",
  },
  selected: {
    position: "relative",
    display: "flex",
    flex: 1,
    width: "100%",
    borderRadius: 1,
    borderBottom: "4px solid",
    borderBottomColor: "primary.main",
  },
}
