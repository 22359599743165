import * as React from "react"

import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"

import { RootState } from "../../../store/rootReducer"

const ProtectedRoute = ({ children }: any) => {
  const selectState = (state: RootState) => state.global

  const { isUserLogged } = useSelector(selectState)

  return isUserLogged ? children : <Navigate to="/login" replace />
}
export default ProtectedRoute
