import React from "react"

import { Box } from "@mui/material"

import logo from "../../../../assets/logos/cb_logo_orange.png"
import { styles } from "./styles"

const Logo = () => (
  <Box data-testid="logo" sx={styles.logoContainer}>
    <img src={logo} alt="logo" style={styles.logo} />
  </Box>
)

export default Logo
