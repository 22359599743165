export const styles = {
  container : {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    typography : {
      py : 2
    },
}
