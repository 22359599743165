export const styles = {
  container: {
    display: "flex",
    mt: 1,
    ml: 1,
    justifyContent: "space-between",
    alignItems: "center",
    ":hover": {
      cursor: "pointer",
    },
  },
  machineNameLabel: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "0.875rem",
    textOverflow: "ellipsis",
    width: "100%",
    fontWeight: "700",
    lineHeight: "1rem",
    color: "primary.main",
    textTransform: "capitalize",
    textDecoration: "underline",
  },
  machineStatusContainer: {
    borderRadius: "4px 0 0 4px",
    height: "1rem",
    width: "4.813rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  machineStatusLabel: {
    textAlign: "center",
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
  },
  bookmarkIcon: {
    fontSize: "large",
    color: "primary.main",
    ml: 0.5,
  },
  titleIconContainer: {
    display: "flex",
    alignItems: "center",
  },
}
