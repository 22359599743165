import React from "react"
import BarChart from "core/components/Plots/BarPlot"

import {
    selectSiteMachines,
    useGetMachineBarStateDurationQuery,
} from "store/Machines/machinesSlice"
import { useSelector } from "react-redux"

import { styles } from "./styles"

import MachineHealthHeader from "./subcomponents/MachineHealthHeader"
import { MachineCollection } from "models/organization"
import { RootState } from "store/rootReducer"
import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import { useAppGetOrganizationQuery } from "core/hooks/apiHooks"

const ChartJsBarChart = () => {
  const selectOrganizationState = (state: RootState) => state.organizations

  const { machineStatusHistoryDuration } = useSelector(
    (state: RootState) => state.machines,
  )
  const { organizationId } = useSelector(selectOrganizationState)

  const { organizationData } = useAppGetOrganizationQuery(organizationId)

  const machines: MachineCollection[] =
    useSelector((state: RootState) =>
      selectSiteMachines(state, { ...organizationData }),
    ) || []

  const machinesLength = machines?.length

  const {
    data = [],
    isFetching,
    isError,
    refetch,
  } = useGetMachineBarStateDurationQuery(
    { machines, machineStatusHistoryDuration },
    {
      skip: machinesLength === 0,
    },
  )

  return (
    <AppGraphWrapper
      baseCardStyle={styles.card}
      graphContainerStyle={styles.boxChartContainer}
      restHeaderProps={{ customHeader: <MachineHealthHeader /> }}
      isLoading={isFetching}
      hasError={isError}
      onRetry={refetch}
    >
      <BarChart data={data} />
    </AppGraphWrapper>
  )
}

export default ChartJsBarChart
