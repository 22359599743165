import { Theme } from "@mui/material"

export const styles = {
  logoContainer: {
    marginTop: (theme: Theme) => theme?.spacing(5),
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    width: "auto",
    height: 64,
    marginBottom: 40,
  },
}
