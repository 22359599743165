export const styles = {
  baseCard: {
    boxShadow: 0,
    width: "100%",
    m: 1,
  },
  graphContainer: {
    ml: 1,
    pr: 1,
    boxShadow: 0,
  },
}
