/* eslint-disable camelcase */
import React, { useEffect } from "react"

import { Asset } from "models/interfaces/machine"
import ChartJsAcceleration from "core/components/AppGraphViews/ChartJsAcceleration"
import ChartJsTemperature from "core/components/AppGraphViews/ChartJsTemperature"
import LastUpdate from "core/components/AppCard/BaseCard/subcomponents/LastUpdate"
import { millisecondsToSeconds, subDays } from "date-fns"
import { reformAssetData } from "core/dataActions"
import { RootState } from "store/rootReducer"
import { setSelectedAsset } from "store/Assets/assetsSlice"
import { useAppGetRMSQuery, useAppGetTempQuery } from "core/hooks/apiHooks"
import { useDispatch, useSelector } from "react-redux"
import { useGetMachineCardStatusInfoQuery } from "store/Machines/machinesSlice"
import { useTranslation } from "react-i18next"

import { Box, Divider, Skeleton, Tabs } from "@mui/material"

import AssetTab from "./AssetTab"
import MachineSummaryRow from "../MachineSummaryRow"
import { styles } from "./styles"
import CurrentTemperature from "../CurrentTemperature"

type RMSParamsType = {
  fromEpoch: number
  toEpoch: number
  axis?: string
  assetId?: string
}

const getPastDayData = (twelveHours = 1) => {
  const DAYS_FACTOR = 1
  const toEpoch = new Date().valueOf()
  const fromCorrected = twelveHours * DAYS_FACTOR
  const fromEpoch = subDays(toEpoch, fromCorrected).valueOf()
  return {
    fromEpoch: millisecondsToSeconds(fromEpoch),
    toEpoch: millisecondsToSeconds(toEpoch),
  }
}

const AssetsTabsDetailView = () => {
  const selectStateAssets = (state: RootState) => state.assets

  const machinesState = (state: RootState) => state.machines
  const { machineSelected } = useSelector(machinesState)
  const { machine_id: machineId = "", asset_collection: availableAssets = [] } =
    machineSelected

  const { assetSelected } = useSelector(selectStateAssets)

  const value = React.useRef("0")

  const time = getPastDayData()
  const [rmsParams, setRMSParams] = React.useState<RMSParamsType>(time)

  const dispatch = useDispatch()

  const selectedAssetAxis = useSelector(
    (state: RootState) => state.assets.assetAxisSelected,
  )

  const totalNumOfAvailAssets: number = availableAssets.length

  useEffect(() => {
    let defaultSelectedAsset: { asset_id: string } = {
      asset_id: "",
    }
    if (totalNumOfAvailAssets > Number(value.current)) {
      defaultSelectedAsset = availableAssets[parseInt(value.current)]
    } else {
      // eslint-disable-next-line prefer-destructuring
      defaultSelectedAsset = availableAssets[0]
      value.current = "0"
    }

    if (assetSelected.asset_id !== defaultSelectedAsset.asset_id) {
      dispatch(setSelectedAsset(defaultSelectedAsset))
    }

    setRMSParams((prevState) => ({
      ...prevState,
      axis: selectedAssetAxis,
      assetId: defaultSelectedAsset.asset_id,
    }))
  }, [machineSelected, selectedAssetAxis, assetSelected])

  const {
    data: accelData = null,
    isFetching: isAccelLoading,
    isError: isAccelError,
    fulfilledTimeStamp = undefined,
    refetch,
  } = useAppGetRMSQuery(rmsParams, {
    skip: !rmsParams?.assetId && !!selectedAssetAxis,
  })

  // OMIT AXIS FROM RMS PARAMS IN ORDER TO CREATE TEMPERATURE QUERY PARAMS
  const { axis: _test, ...tempParams } = rmsParams

  const {
    temperatureData,
    isError,
    isFetching,
    refetch: refetchTemp,
    fulfilledTimeStamp: temperatureTimestamp,
  } = useAppGetTempQuery(
    {
      ...tempParams,
      start_time: tempParams.fromEpoch,
      end_time: tempParams.toEpoch,
    },
    { skip: !tempParams?.assetId && !!selectedAssetAxis },
  )

  const { data, isMachineFetching } = useGetMachineCardStatusInfoQuery(
    machineId,

    {
      skip: !machineId,
      selectFromResult: ({ data, isFetching }) => ({
        data,
        isMachineFetching: isFetching,
      }),
    },
  )

  const {
    state: machineStatus = "",
    duration: machineTimeInCurrentStatus = 0,
  } = data || {}

  const handleChange = async (
    event: React.SyntheticEvent,
    assetIndex: string,
  ) => {
    value.current = assetIndex
    const newSelectedAsset = availableAssets[parseInt(assetIndex)]

    if (newSelectedAsset) {
      dispatch(setSelectedAsset(newSelectedAsset))
    }
  }

  const { t } = useTranslation()

  const assetsPerMachine = availableAssets.length
  const lastAssetIndex = availableAssets.length - 1
  const isLastUpdateLoading = isFetching || isAccelLoading

  return (
    <Box sx={styles.root}>
      <MachineSummaryRow
        isLoading={isMachineFetching}
        status={machineStatus}
        numOfMachineAssets={assetsPerMachine}
        timeInStatus={machineTimeInCurrentStatus}
      />
      <Divider sx={{ mt: 4 }} />

      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "0.625rem",
          paddingRight: "0.5rem",
        }}
      >
        {isLastUpdateLoading ? (
          <Skeleton variant="text" width={100} sx={{ fontSize: "0.75rem" }} />
        ) : (
          <LastUpdate
            lastUpdate={
              !!fulfilledTimeStamp &&
              !!temperatureTimestamp &&
              fulfilledTimeStamp < temperatureTimestamp
                ? fulfilledTimeStamp
                : temperatureTimestamp
            }
          />
        )}
      </Box>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        sx={styles.tablist}
        value={Number(value.current)}
        onChange={handleChange}
      >
        {availableAssets.map(({ asset_id }: Asset, index: number) => (
          <AssetTab
            showDivider={index !== lastAssetIndex}
            key={asset_id}
            assetId={asset_id}
            index={index}
          />
        ))}
      </Tabs>

      <ChartJsAcceleration
        onRetry={refetch}
        axisLabels={{
          yLabel: t("measure.unit.acceleration.label"),
          xLabel: "",
        }}
        accelData={reformAssetData(accelData)}
        isLoading={isAccelLoading}
        hasError={isAccelError}
      />
      <Divider sx={{ my: 3 }} />

      <ChartJsTemperature
        customSubheader={
          <CurrentTemperature assetId={assetSelected.asset_id} />
        }
        isLoading={isFetching}
        onRetry={refetchTemp}
        isError={isError}
        axisLabels={{
          yLabel: "°C",
          xLabel: "",
        }}
        data={reformAssetData(temperatureData)}
      />
    </Box>
  )
}

export default AssetsTabsDetailView
