/* eslint-disable react/jsx-no-useless-fragment */
import React from "react"

import { IAppCardLoader } from "types/cards"
import { useTranslation } from "react-i18next"

import { Box, CircularProgress, IconButton } from "@mui/material"
import RefreshIcon from "@mui/icons-material/Refresh"

import AppTypography from "../AppTypography"
import { styles } from "./styles"

const AppCardLoader = (props: IAppCardLoader) => {
  const {
    isLoading,
    hasError,
    onRetry,
    disabled = false,
    style,
    children,
  } = props

  const { t } = useTranslation()

  return !disabled ? (
    isLoading ? (
      <Box sx={{ ...styles.container, ...style }}>
        <CircularProgress />
      </Box>
    ) : hasError ? (
      <Box sx={{ ...styles.container, ...style }}>
        <AppTypography
          capitalizeFirstLetter
          typographyProps={{ color: "error" }}
        >
          {t("error")}
        </AppTypography>
        <IconButton onClick={onRetry} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      </Box>
    ) : (
      <>{children}</>
    )
  ) : null
}

export default AppCardLoader
