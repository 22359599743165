import React from "react"

import { useTranslation } from "react-i18next"
import BaseCard from "../AppCard/BaseCard"
import { styles } from "./styles"

interface IMachineDashboard {
  children?: React.ReactNode
  lastUpdate?: number
}

const MachineDashboardCard = ({
  children = undefined,
  lastUpdate,
}: IMachineDashboard) => {
  // interface IToggleOptions {
  //   title: string
  //   label: string
  // }
  // const toggleOptions: IToggleOptions[] = [
  //   { title: "important.machines.only", label: "importance" },
  //   { title: "defective.machines.only", label: "defective" },
  // ]
  // const appliedFilters = useSelector(
  //   (state: RootState) => state.filters.appliedFilters,
  // )
  const { t } = useTranslation()
  // const dispatch = useAppDispatch()
  // const handleOnChange = ({
  //   target: { name },
  // }: ChangeEvent<HTMLInputElement>) => {
  //   dispatch(setAppliedFilter(name))
  // }
  return (
    <BaseCard
      sx={styles.basecard}
      testId="machine_title"
      lastUpdate={lastUpdate}
      title={t("machines.text")}
    >
      {/* <MachineFilter /> */}
      {/* <FormGroup sx={styles.switchFormGroup}>
        {toggleOptions.map(({ title, label }: IToggleOptions) => (
          <FormControlLabel
            key={label}
            sx={{ height: "16px" }}
            control={
              <Switch
                checked={!!appliedFilters && appliedFilters.includes(label)}
                name={label}
                onChange={handleOnChange}
              />
            }
            label={t(title)}
          />
        ))}
      </FormGroup> */}
      {children}
    </BaseCard>
  )
}
export default MachineDashboardCard
