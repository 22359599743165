import React from "react"
import AppCardLoader from "core/components/AppCardLoader"
import BaseCard from "core/components/AppCard/BaseCard"
import { Box, SxProps } from "@mui/material"
import { styles } from "./styles"
import NoDataFound from "core/components/NoDataFound"
import BaseCardHeader, {
  BaseCardHeaderProps,
} from "../BaseCard/subcomponents/BaseCardHeader"

type restHeaderPropsType = Omit<
  BaseCardHeaderProps,
  "showDivider" | "boxStyle" | "lastUpdate"
>

interface AppGraphWrapperProps {
  children: React.ReactNode
  baseCardStyle?: SxProps
  graphContainerStyle?: SxProps
  isLoading: boolean
  hasError: boolean
  onRetry?: () => void
  noDataCheck?: boolean | undefined
  restHeaderProps?: restHeaderPropsType
  subheader?: React.ReactNode
}

const AppGraphWrapper = ({
  children,
  isLoading,
  hasError,
  subheader,
  onRetry,
  noDataCheck = false,
  graphContainerStyle = {},
  baseCardStyle = {},
  restHeaderProps,
}: AppGraphWrapperProps) => {
  if (noDataCheck) {
    return (
      <BaseCard
        sx={{ ...styles.baseCard, ...baseCardStyle }}
        customHeader={<BaseCardHeader {...restHeaderProps} />}
      >
        <Box sx={{ ...styles.graphContainer, ...graphContainerStyle }}>
          {subheader}
          <AppCardLoader
            isLoading={isLoading}
            hasError={hasError}
            onRetry={onRetry}
          >
            <NoDataFound
              style={{
                height: "100%",
                boxShadow: 0,
                marginTop: "32px",
              }}
            />
          </AppCardLoader>
        </Box>
      </BaseCard>
    )
  }

  return (
    <BaseCard
      sx={{ ...styles.baseCard, ...baseCardStyle }}
      customHeader={<BaseCardHeader {...restHeaderProps} />}
    >
      <Box sx={{ ...styles.graphContainer, ...graphContainerStyle }}>
        {subheader}
        <AppCardLoader
          isLoading={isLoading}
          hasError={hasError}
          onRetry={onRetry}
        >
          <div style={{ width: "98%" }}>{children}</div>
        </AppCardLoader>
      </Box>
    </BaseCard>
  )
}

export default AppGraphWrapper
