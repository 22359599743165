export const styles = {
  button: {
    ":hover": {
      backgroundColor: "#67AAFF",
      borderColor: "#67AAFF",
      color: "#ffffff",
    },
    position: "fixed",
    bottom: 32,
    right: 32,
    display: "flex",
  },
}
