import React from "react"

import NoDataFoundSVG from "assets/icons/no-data-found.svg"
import UnderTrainingSVG from "assets/icons/under-training.svg"
import { useTranslation } from "react-i18next"

import { Box } from "@mui/material"

import AppTypography from "../AppTypography"
import { styles } from "./styles"

interface NoDataFoundProps {
  errorType?: "training" | "noData"
  style?: any
}

const NoDataFound = ({ style, errorType }: NoDataFoundProps) => {
  const { t } = useTranslation()

  const imgToShow = errorType === "training" ? UnderTrainingSVG : NoDataFoundSVG
  const textToShow =
    errorType === "training" ? "machine.under.training" : "noData.text"

  return (
    <Box sx={{ ...styles.container, ...style }}>
      <img src={imgToShow} style={styles.image} />
      <AppTypography sx={styles.text} textTransform="capitalize">
        {t(textToShow)}
      </AppTypography>
    </Box>
  )
}

export default NoDataFound
