import React from "react"

import { ThermostatOutlined } from "@mui/icons-material"

import LineChart from "core/components/ChartJS"
import { useTranslation } from "react-i18next"

import { useTheme } from "@mui/material"
import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import AppTypography from "core/components/AppTypography"
import { styles } from "./styles"

interface ChartJsTemperatureProps {
  data?: Array<{ x: number; y: number }>
  isLoading?: boolean
  customSubheader?: React.ReactNode
  isError?: boolean
  thresholds?: any
  zoomEnabled?: boolean
  axisLabels?: {
    xLabel: string
    yLabel: string
  }
  onRetry?: () => void
}

const ChartJsTemperature = ({
  data = [],
  isLoading = false,
  isError = false,
  zoomEnabled = false,
  customSubheader,
  thresholds = {},
  axisLabels = {
    xLabel: "",
    yLabel: "",
  },
  onRetry,
}: ChartJsTemperatureProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const graphLineColor = theme.palette.primary.main

  const hasThresholds = thresholds

  const graphConfig = {
    scales: {
      y: {
        title: {
          color: theme.palette.text.secondary,
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
      x: {
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
    },
  }

  const dataCheck = !data || data.length === 0

  return (
    <AppGraphWrapper
      subheader={customSubheader}
      isLoading={isLoading}
      hasError={isError}
      onRetry={onRetry}
      noDataCheck={dataCheck}
      graphContainerStyle={styles.graphContainer}
      baseCardStyle={styles.baseCard}
      restHeaderProps={{
        headerLabel: (
          <AppTypography variant="subtitle3">{`${t(
            "temperature.text",
          )}`}</AppTypography>
        ),
        preTitleIcon: (
          <ThermostatOutlined fontSize="small" sx={styles.preTitle} />
        ),
      }}
    >
      <LineChart
        config={graphConfig}
        axisLabels={axisLabels}
        zoomEnabled={zoomEnabled}
        lineColor={graphLineColor}
        upperBoundOffset={10}
        graphData={data}
        thresholds={hasThresholds}
      />
    </AppGraphWrapper>
  )
}

export default ChartJsTemperature
