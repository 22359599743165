import { IGetSiteInfo } from "types/sitesApi"
import BAPI from ".."
import { GenericApiCallType } from "types/misc"

const SitesAPI = {
  async getSiteInfo(siteID: string): GenericApiCallType<IGetSiteInfo> {
    return BAPI.get(`site/${siteID}`, {
      timeout: 3000,
      timeoutErrorMessage: "No response from server",
    })
  },
}
export default SitesAPI
