import React from "react"
import MachineHealthChart from "../../MachineHealthChart"
import { useAppPostGetTimeseries } from "core/hooks/apiHooks"
import { useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { minutesToMilliseconds } from "date-fns"
import { getSymbolValues, interpolateGraphData } from "../helpers"

const DynamicHealthBarChartGraph = (props: any) => {
  const {
    requestParams,
    refresh_interval: interval = 5,
    assetId: selectedAssetId,
  } = props

  const { globalSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )

  const requestBody = {
    ...requestParams[0].requestBody,
    ...{
      end_time: new Date(globalSelectedRange.toEpoch * 1000).toISOString(),
      start_time: new Date(globalSelectedRange.fromEpoch * 1000).toISOString(),
      symbols_values: getSymbolValues({
        requestParams,
        startTime: globalSelectedRange.fromEpoch,
        endTime: globalSelectedRange.toEpoch,
      }),
    },
  }

  const responseData = useAppPostGetTimeseries(requestBody, {
    skip: !selectedAssetId,
    pollingInterval: minutesToMilliseconds(interval),
  })

  const sanitizedData = interpolateGraphData(
    globalSelectedRange.fromEpoch,
    globalSelectedRange.toEpoch,
    responseData.data,
  )

  const refactoredData = {
    ...responseData,
    healthData: sanitizedData.data.map((item: any) => ({
      date: new Date(item.date).getTime() / 1000,
      value: item.value,
    })),
  }

  const myProps = { ...props, externalData: refactoredData }

  return <MachineHealthChart {...myProps} />
}
export default DynamicHealthBarChartGraph
