import React from "react"

import AppTypography from "core/components/AppTypography"
import { useTranslation } from "react-i18next"

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
} from "@mui/material"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

import { styles } from "./styles"
import GraphBaseHeader from "../../MachineVibrationGraph/subcomponents/GraphBaseHeader"

interface AppGraphContainerProps {
  accordionTitle: string
  graphsToRender: { [key: string]: React.ReactNode[] }
}

const AppGraphContainer = ({
  accordionTitle,
  graphsToRender = {},
}: AppGraphContainerProps) => {
  const { t } = useTranslation()
  const hasGraphsToRender = Object.keys(graphsToRender).length > 0

  return (
    <Accordion
      defaultExpanded={true}
      sx={styles.accordionContainer}
      disableGutters
    >
      <AccordionSummary
        sx={styles.accordionDetails}
        expandIcon={<ArrowDropDownIcon sx={{ color: "text.secondary" }} />}
      >
        <Box sx={styles.accordionTitle}>
          <AppTypography variant="h3" capitalizeFirstLetter>
            {t(`${accordionTitle}`)}
          </AppTypography>
        </Box>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          ...styles.accordionPadding,
          ...{
            [".MuiGrid-container:last-of-type"]: {
              mb: 5,
            },
          },
        }}
      >
        <GraphBaseHeader />
        {hasGraphsToRender &&
          Object.entries(graphsToRender).map((graph: any) => (
            <Grid
              key={graph[0]}
              container
              alignItems="flex-end"
              columnSpacing={4}
              sx={{
                px: 2,
              }}
            >
              {graph[1].map((graphItem: any, index: number) => (
                <Grid
                  id="item"
                  item
                  sx={{ py: 4 }}
                  md={12}
                  sm={12}
                  lg={12}
                  key={index}
                >
                  {graphItem}
                </Grid>
              ))}
            </Grid>
          ))}
      </AccordionDetails>
    </Accordion>
  )
}

export default AppGraphContainer
