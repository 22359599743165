import React from "react"

import { batch } from "react-redux"
import { DepartmentCollection } from "models/interfaces/department"
import {
  differenceInCalendarDays,
  differenceInMinutes,
  differenceInSeconds,
  format,
  millisecondsToHours,
  millisecondsToMinutes,
  minutesToMilliseconds,
  secondsToHours,
  subDays,
  subMinutes,
  subMonths,
  subWeeks,
  toDate,
} from "date-fns"
import { EUROPEAN_PROJECTS_URLS } from "core/constants/ui"
import { exportCategoryTitles } from "core/components/AppDrawerNavigation/constants/categoryTitles"
import { getGroupBy } from "screens/Facilities/subcomponents/Dynamic/helpers"
import { IProductionLine } from "models/interfaces/productionLine"
import { MachineCollection } from "models/organization"
import { NavigateFunction } from "react-router-dom"
import {
  setDepartmentsCollectionDatatable,
  setSelectedDepartment,
} from "store/Department/departmentSlice"
import { setMachinesCollectionDatatable } from "store/Machines/machinesSlice"
import {
  setProductionLineCollectionDatatable,
  setSelectedProductionLine,
} from "store/Production_lines/productionLineSlice"
import { setSitesCollectionDatatable } from "store/Sites/sitesSlice"
import { TimeUnit, TooltipLabelStyle, TooltipOptions } from "chart.js"
import { toast } from "react-toastify"

import { Action, Store } from "@reduxjs/toolkit"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { Theme } from "@mui/material"

type dataType = {
  type: string
  content: string
}

type selectedLanguageType = "en" | "el"
export type tooltipTextType = { en: string; el: string } | undefined
type durationObjectType = {
  years: number
  months: number
  weeks: number
  days: number
  hours: number
  minutes: number
  seconds: number
  [key: string]: any
}

let store: Store
const findValueDeep = require("deepdash/findValueDeep")
interface IMillisecondsToStr {
  milliseconds: number
  t: any
}

const LABEL_SCALE_FACTOR = 120

interface IGetAppEpoch {
  fromMinute: number
}

export const addOpacityInHexColor = (opacity: string, color: string) =>
  color + opacity
export const capitalizeFistLetter = (string: string) => {
  if (!string) return ""
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const isUrlAndImage = (url: string | undefined) => {
  if (!url) return false
  const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png)/g
  return regex.test(url)
}

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms))
export const debugLog = (value: string) => {
  if (process.env.NODE_ENV === "development") {
    console.debug(value)
  }
}

export const enableMocks = () => {
  const { worker } = require("../../mocks/browser")
  worker.start({
    onUnhandledRequest: "warn", // "warn" | "error" | "bypass"
  })
}

export const getIsTheUrlEUProject = () =>
  EUROPEAN_PROJECTS_URLS.some((url) => window.location.href.includes(url))

export const capitalizeFistLetterOfEveryWord = (sentence: string) =>
  !!sentence
    ? sentence
        .split(" ")
        .map((word) => word[0].toUpperCase() + word.substring(1))
        .join(" ")
    : ""
export const loadNotificationFromLocalstorage = () =>
  localStorage.getItem("notifications")
export const getDayOfWeek = (num: number) => {
  if (typeof num !== "number") return ""

  const today = new Date()
  const day = subDays(today, num)
  return format(day, "EEEE")
}

export const getWeekOfMonth = (num: number) => {
  const today = new Date()
  const week = subWeeks(today, num)
  return format(week, "EEEE")
}

export const getMonthOfYear = (num: number) => {
  const today = new Date()
  const month = subMonths(today, num)
  return format(month, "EEEE")
}

export const getMonthOfLastYear = (num: number) => {
  const today = new Date()
  const month = subMonths(today, num)
  return format(month, "EEEE")
}

export const formatDurationLargeScale = (seconds: number) => {
  const durationObject: durationObjectType = {
    years: 31536000,
    months: 2592000,
    weeks: 604800,
    days: 86400,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  }

  for (const key in durationObject) {
    if (seconds >= durationObject[key]) {
      const amount = Math.floor(seconds / durationObject[key])
      return `${amount} ${key}`
    }
  }
}

export const getAllScreensFromConfigUI = () => {
  const configUI = store.getState().ui.configuration

  const extraScreens = configUI?.extraScreens ?? []

  const allScreens = [
    { machineDetailScreen: configUI.machineDetailScreen },
    ...extraScreens,
  ]

  return allScreens
}

export const filterDuplicateStringsInArray = (array: string[]) =>
  array.filter((item, index) => array.indexOf(item) === index)

export const saveNotificationToLocalstorage = (data: any) => {
  const localNotifications = loadNotificationFromLocalstorage()
  if (!!localNotifications) {
    const notificationObject = JSON.parse(localNotifications)
    if (Array.isArray(notificationObject)) {
      localStorage.setItem(
        "notifications",
        JSON.stringify([...notificationObject, data]),
      )
    }

    return
  }

  localStorage.setItem("notifications", JSON.stringify([data]))
}

export const tooltipTextHelper = (
  selectedLanguage: selectedLanguageType,
  tooltipText: tooltipTextType,
) => {
  switch (selectedLanguage) {
    case "en":
      return tooltipText?.en
    case "el":
      return tooltipText?.el
  }
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / k ** i).toFixed(dm)) + " " + sizes[i]
}

export const notificationType = (data: dataType) => {
  const { type, content } = data
  switch (type) {
    case "warning":
      toast.warning(content)
      break
    case "info":
      toast.info(content)
      break
    case "success":
      toast.success(content)
      break
    case "critical":
      toast.error(content)
      break
    default:
      break
  }

  saveNotificationToLocalstorage(data)
}

export const filterByValue = (array: any[], search: string | number) => {
  if (!Array.isArray(array)) return
  return array.filter((obj: any) =>
    Object.keys(obj).some((key) => {
      if (!obj[key]) return
      return obj[key]
        .toString()
        .toLowerCase()
        .includes(search.toString().toLowerCase())
    }),
  )
}

export const isOdd = (num: number) => num % 2 !== 0
export const convertObjectToArray = (object: any) =>
  Object.keys(object).map((key) => ({
    ...object[key],
  }))
export const getUTCUnixTimeInSeconds = ({ fromMinute }: IGetAppEpoch) => {
  const MILLIS_FROM = minutesToMilliseconds(fromMinute)
  const now = new Date()
  return differenceInSeconds(now, toDate(MILLIS_FROM))
}

export const isIncludedInUrl = (urlsTitles: string[]) =>
  urlsTitles.some((url) => window.location.href.includes(url))

export const millisecondsToDays = (date: number) =>
  Math.floor(millisecondsToHours(date) / 24)
export const getDurationInState = (date: number) => {
  const hours = secondsToHours(date)
  if (hours > 24) {
    const days = hours / 24
    return days % 1 !== 0 ? days.toFixed(1) : days
  }
  return hours.toFixed(1)
}

export const getDurationInStateLabel = (date: number) => {
  const hours = secondsToHours(date)
  if (hours <= 1) return "hour.text"
  if (hours > 1 && hours <= 24) return "hour.text.plural"
  if (hours > 24 && hours < 48) return "day.text"
  return "day.text.plural"
}

export const millisecondsToStr = ({ milliseconds, t }: IMillisecondsToStr) => {
  if (milliseconds === -1) return t("not.available.text")
  const isPlural = (number: number, textCode: string) =>
    number > 1 ? t(textCode + ".plural") : t(textCode)
  let temp = Math.floor(milliseconds / 1000)
  const years = Math.floor(temp / 31536000)
  if (years) {
    return years + " " + isPlural(years, "year.text")
  }

  const days = Math.floor((temp %= 31536000) / 86400)
  if (days) {
    return days + " " + isPlural(days, "day.text")
  }

  const hours = Math.floor((temp %= 86400) / 3600)
  if (hours) {
    return hours + " " + isPlural(hours, "hour.text")
  }

  const minutes = Math.floor((temp %= 3600) / 60)
  if (minutes) {
    return minutes + " " + isPlural(minutes, "minute.text")
  }

  const seconds = temp % 60
  if (seconds) {
    return seconds + " " + isPlural(seconds, "second.text")
  }

  return t("just.now.text")
}

export const getUTCOffsetMillis = () => {
  const d = new Date()
  return d.getTimezoneOffset() * 60000
}

export const removeDiacritics = (str: string): string => {
  if (!str) return ""
  const diacritics = "ΆΈΉΊΌΎΏάέήίόύώ"
  const nonDiacritics = "ΑΕΗΙΟΥΩαεηιουω"

  const chars = str.split("")

  chars.forEach((char, index) => {
    const diacriticIndex = diacritics.indexOf(char)
    if (diacriticIndex !== -1) {
      chars[index] = nonDiacritics[diacriticIndex]
    }
  })

  return chars.join("")
}

export const writeSelectionsFromMachineId = (machineId: string) => {
  const { siteSelected } = store.getState().sites
  const { dispatch } = store
  findValueDeep(
    siteSelected,
    (value: any, key: any, parent: any, context: any) => {
      if (value === machineId) {
        const paths = context.path.split(".")
        const departmentLineIndex = parseInt(
          paths
            .find((path: string) => path.includes("departmentCollection"))
            .replace(/\D/g, ""),
        )
        const productionLineIndex = parseInt(
          paths
            .find((path: string) => path.includes("productionLineCollection"))
            .replace(/\D/g, ""),
        )
        const selectedDepartment =
          siteSelected.departmentCollection[departmentLineIndex]
        const selectedProductionLine =
          selectedDepartment.productionLineCollection[productionLineIndex]
        batch(() => {
          dispatch(setSelectedDepartment(selectedDepartment))
          dispatch(setSelectedProductionLine(selectedProductionLine))
        })
        return false
      }
    },
  )
}

export const isCalpak = () => isIncludedInUrl(["calpak"])
export const isLevelUp = () => isIncludedInUrl(["localhost", "level-up"])
export const isProductionRelease = (): boolean => true

export const getFlatLevelOfOrganizationsData = () => {
  const { organizationSelected } = store.getState().organizations
  const { dispatch } = store
  const { siteinformation } = organizationSelected
  const sitesFlat: any[] = []
  const departmentsFlat: any[] = []
  const productionLinesFlat: any[] = []
  const machinesFlat: any[] = []
  siteinformation.forEach((site: any) => {
    sitesFlat.push(site)
    site.departmentCollection.forEach((department: DepartmentCollection) => {
      departmentsFlat.push(department)
      department.productionLineCollection?.forEach(
        (productionLine: IProductionLine) => {
          productionLinesFlat.push(productionLine)
          productionLine.machineCollection?.forEach(
            (machine: MachineCollection) => {
              machinesFlat.push(machine)
            },
          )
        },
      )
    })
  })
  batch(() => {
    dispatch(setSitesCollectionDatatable(sitesFlat))
    dispatch(setDepartmentsCollectionDatatable(departmentsFlat))
    dispatch(setProductionLineCollectionDatatable(productionLinesFlat))
    dispatch(setMachinesCollectionDatatable(machinesFlat))
  })
}

export const getStatusColor = (status: string, theme: Theme) => {
  switch (status?.toLowerCase()) {
    case "critical":
      return theme?.palette?.error
    case "warning":
      return theme?.palette?.warning
    case "normal":
      return theme?.palette?.success
    case "undertraining":
      return theme?.palette?.training
    default:
      return theme?.palette?.primary
  }
}

export const validateRTKToken = (params: any) => {
  if (!params) {
    console.warn("RTK params are empty.\nQuery will be skipped.")
    console.warn(params)
    return skipToken
  }

  if (typeof params !== "object") {
    console.warn("RTK params are not an object.\nQuery will be skipped.")
    console.warn(params)
    return skipToken
  }

  if (Array.isArray(params)) {
    console.warn("RTK params are Array.\nQuery will be skipped.")
    console.warn(params)
    return skipToken
  }

  for (const prop in params) {
    const typeOfProp = typeof params[prop]

    if (
      typeOfProp !== "number" &&
      typeOfProp !== "string" &&
      typeOfProp !== "boolean"
    ) {
      console.warn("RTK params are invalid.\nQuery will be skipped.")
      console.warn(params)
      console.warn(typeOfProp)
      return skipToken
    }
  }

  return params
}

export const injectStore = (_store: Store) => {
  store = _store
}

type DurationType = "month" | "months" | "days" | undefined
export const selectDurationType = (): DurationType => {
  const { machineStatusHistoryDuration } = store.getState().machines
  switch (machineStatusHistoryDuration) {
    case "12_month":
      return "months"
    case "1_month":
      return "month"
    case "1_week":
      return "days"
  }
}

export const getBarChartLabels = (element: any) => {
  const { machineStatusHistoryDuration } = store.getState().machines
  const today = new Date()
  switch (machineStatusHistoryDuration) {
    case "12_month": {
      const month = subMonths(today, element.month)
      return format(month, "MMM yy")
    }

    case "1_month": {
      const week = subWeeks(today, element.week)
      const prevWeek = subWeeks(today, element.week + 1)
      return `${format(prevWeek, "d MMM")} - ${format(week, "d MMM")}`
    }

    case "1_week": {
      const day = subDays(today, element.day)
      return format(day, "EEEE")
    }
  }
}

export const raise = (error: string): never => {
  throw new Error(error)
}

export const replaceUnderscores = (name: string): string =>
  name.trim().replace(/_/g, " ").toLowerCase()

export const calculateUnit = (input: number): TimeUnit => {
  const now: Date = new Date()
  const from: Date = subMinutes(now, input)
  const difference: number = differenceInCalendarDays(now, from)

  if (difference <= 1) return "hour"
  if (difference > 1 && difference <= 91) return "day"
  return "month"
}

export const clearLocalStorageWithExceptions = (keysToKeep: string[]): void => {
  const localStorageKeys: string[] = Object.keys(localStorage)
  const localStorageKeysToClear: string[] = localStorageKeys.filter(
    (key: string) => !keysToKeep.includes(key),
  )
  localStorageKeysToClear.forEach((key: string) => localStorage.removeItem(key))
}

export const calculateGraphUpperOffsetValue = (
  maxValue: number,
  thresholds: { critical: number; warning: number },
  multiplier = 1.1,
) =>
  thresholds.critical > maxValue
    ? thresholds.critical * multiplier
    : maxValue * multiplier

export const createPredictionBody = ({
  globalSelectedRange,
  requestBody,
  predictBody,
}: any) => {
  const getSymbolValues = () => {
    const payload = {
      ...requestBody.symbols_values,
      [requestBody.symbols_names.indexOf("group_by")]: getGroupBy({
        requestBody: requestBody,
        startTime: globalSelectedRange.fromEpoch,
        endTime: globalSelectedRange.toEpoch,
      }),
    }

    return Object.values(payload) as any
  }

  const indexOfMeasurement = requestBody.symbols_names.indexOf("measurement")

  const symbolsValues = getSymbolValues().toSpliced(
    indexOfMeasurement,
    1,
    predictBody.symbols_values[indexOfMeasurement],
  )

  const START_TIME = new Date(
    globalSelectedRange.fromEpoch * 1000,
  ).toISOString()

  return {
    originalDataParams: {
      ...requestBody,
      start_time: START_TIME,
      symbols_values: getSymbolValues(),
    },
    predictionDataParams: {
      ...requestBody,
      ...predictBody,
      start_time: START_TIME,
      symbols_values: symbolsValues,
    },
  }
}

export const navigateToLandingPage = (navigate: NavigateFunction) => {
  const availableScreens = exportCategoryTitles().map(
    (category) => category.idsAvailable[0],
  )

  getIsTheUrlEUProject()
    ? navigate(`/${availableScreens[0]}`)
    : navigate("/dashboard")
}

export const getTimeFormat = (value?: number) => {
  const { globalSelectedRange } = store.getState().global

  const from = new Date(globalSelectedRange.fromEpoch * 1000)
  const to = new Date(globalSelectedRange.toEpoch * 1000)

  const difference = differenceInCalendarDays(to, from)

  let formatLabel = "MMM yyyy"

  if (difference <= 1) {
    formatLabel = "HH:mm"
  } else if (difference <= 14) {
    formatLabel = "dd MMM HH:mm"
  } else if (difference <= 181) {
    formatLabel = "dd MMM"
  } else if (difference <= 360) {
    formatLabel = "MMM dd"
  }

  if (!value) {
    return formatLabel
  }

  return format(value, formatLabel)
}

export const getHealthBarGraphResolution = () => {
  const { globalSelectedRange } = store.getState().global

  const from = new Date(globalSelectedRange.fromEpoch * 1000)
  const to = new Date(globalSelectedRange.toEpoch * 1000)

  const difference = differenceInCalendarDays(to, from)

  if (difference <= 1) return 100
  if (difference <= 7) return 20
  if (difference <= 14) return 10
  if (difference <= 31) return 2
  return 1
}

export const getMaxTicksForGraphs = (graphsInCurrentRow = 1) => {
  const calculateTicks = () =>
    Math.floor(window.innerWidth / (LABEL_SCALE_FACTOR * graphsInCurrentRow))

  const [maxTicksGraphLabels, setMaxTicksGraphLabels] =
    React.useState(calculateTicks)

  const handleWindowResize = () => {
    setMaxTicksGraphLabels(calculateTicks())
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [window.innerWidth])

  return maxTicksGraphLabels
}

export const getTooltip = (
  timeRawData: number[],
  timeFormat = "dd/MM/yyyy HH:mm:ss",
) =>
  ({
    enabled: true,
    mode: "index",
    callbacks: {
      title: (context: any) => {
        const index = context[0].dataIndex
        const hoverLabel = format(timeRawData[index], timeFormat)
        return hoverLabel ?? ""
      },
      label: (context: any) => {
        const label = context.dataset.label || ""
        if (label) {
          return `${label}: ${context.parsed.y}`
        }
        return ""
      },
    },
  } as TooltipOptions<"line">)

export const shouldPollData = (
  queryParams: any,
  actionToDispatch: Action,
  timestamp: number | undefined,
  interval: number,
) => {
  const { shouldPoll } = queryParams

  if (shouldPoll && timestamp) {
    const timeStampToMinutes = millisecondsToMinutes(interval)
    const diff = differenceInMinutes(new Date(), timestamp)
    if (diff >= timeStampToMinutes) {
      store.dispatch(actionToDispatch)
    }
  }
}
const findValueByDate = (
  dataset: DataSetEntry[],
  date: string,
): number | string => {
  if (!date) return ""
  const matchingEntry = dataset.find((entry) => entry.date === date)
  return matchingEntry ? matchingEntry.value.toFixed(4) : ""
}

const getIsDateBeyondLast = (realData: any, date: string): boolean => {
  if (!date) return false
  const lastDate = realData[realData.length - 1]?.date
  if (!lastDate) return false
  return date > lastDate
}

type DataSetEntry = {
  date: string
  value: number
}

export const getPredictionTooltip = (
  realData: [{ date: string; value: number }] | [],
  predictionData: [{ date: string; value: number }] | [],
) =>
  ({
    enabled: true,
    mode: "index",
    callbacks: {
      labelColor: (tooltipItem: any, chart: any) => {
        console.debug(tooltipItem)
        console.debug(chart)
        return {
          borderColor: "transparent",
          backgroundColor: "transparent",
        } as TooltipLabelStyle
      },
      title: (context: any) => {
        const indexOfPredictionDataset = 1

        if (context.datasetIndex === indexOfPredictionDataset) {
          return null
        }
        const timeFormat = "dd/MM/yyyy HH:mm:ss"

        const index = context[0].dataIndex
        const rawDate = context[0].raw.x

        const indexPrediction = index < predictionData.length ? index : 0

        const isDateBeyondLast = getIsDateBeyondLast(
          realData,
          predictionData[indexPrediction].date,
        )

        const result = isDateBeyondLast
          ? format(new Date(predictionData[indexPrediction].date), timeFormat)
          : format(rawDate, timeFormat)

        return result
      },
      label: (context: any) => {
        const indexOfPredictionDataset = 1

        if (context.datasetIndex === indexOfPredictionDataset) {
          return null
        }

        const index = context?.dataIndex
        const realDataLabel = `True : ${context.parsed.y.toFixed(4)}`
        const prediction = findValueByDate(predictionData, realData[index].date)
        const predictionLabel = prediction ? `Prediction : ${prediction}` : ""

        const indexPrediction = index < predictionData.length ? index : 0

        const isDateBeyondLast = getIsDateBeyondLast(
          realData,
          predictionData[indexPrediction].date,
        )

        const predictionLabelSolo = `Prediction ${findValueByDate(
          predictionData,
          predictionData[indexPrediction].date,
        )}`

        const result = isDateBeyondLast
          ? [predictionLabelSolo]
          : [realDataLabel, predictionLabel]

        return result
      },
    },
  } as TooltipOptions<"line">)
