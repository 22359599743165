export const styles = {
  baseContainer: (selected: boolean) => ({
    outline: selected ? "1px solid" : "none",
    outlineColor: selected ? "text.secondary" : "none",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    backgroundColor: "background.paper",
    borderRadius: "0.5rem",
    height: 160,
    width: 256,
    margin: 1,
    display: " flex",
    flexDirection: "column",
    ":hover": {
      cursor: selected ? "default" : "pointer",
      outline: "1px solid",
      outlineColor: "text.secondary",
    },
  }),

  container: {
    flexGrow: "1",
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    pt: "1.5rem",
  },
  containerLeft: {
    width: "5.5rem",
    mr: 2,
    borderRight: "1px solid",
    ml: 2,
    borderColor: "divider",
  },
  containerRight: {
    width: "6.5rem",
  },
  infoBoxContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
    whiteSpace: "pre",
  },
}
