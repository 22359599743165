// import "./core/helpers/wdyr"
import "./index.css"

import React from "react"

import { persistor, store } from "./store/store" //remain above ".App" import. See CB-1130fdsa

import { createRoot } from "react-dom/client"
import { enableMocks, injectStore } from "core/helpers"
import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"

import App from "./App"

import reportWebVitals from "./reportWebVitals"

injectStore(store)

const container =
  document.getElementById("root") || document.getElementById("div")
const root = createRoot(container!)

export const ENABLE_MOCKS = false

if (ENABLE_MOCKS && process.env.NODE_ENV === "development") enableMocks()

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWerenderbVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// eslint-disable-next-line no-console
reportWebVitals(console.log)
