const axisLabel = {
  fontSize: "0.875rem",
  px: 2,
  cursor: "pointer",
  borderRight: "1px solid",
  borderColor: "divider",
}
export const styles = {
  axisLabel: axisLabel,
  axisLabelSelected: {
    ...axisLabel,
    fontWeight: "bold",
  },
}
