export const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: "2.5rem",
    mt: 2,
    cursor: "pointer",
  },
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textAlign: "left",
    ml: 2,
    width: "6rem",
    "::before": {
      opacity: 0,
    },
    boxShadow: "0",
  },
  statusBar: {
    height: "100%",
    width: "0.25rem",
  },
  serialText: {
    borderLeft: "1px solid #E6E6E6 ",
    pl: 3,
    width: "26rem",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  text: {
    px: 2,
  },
  iconTextAssets: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
    pl: 3,
    borderLeft: "1px solid #E6E6E6 ",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  icon: {
    fontSize: "1.25rem",
  },
}
