export const styles = {
  container: {
    padding: 1,
    borderRadius: 1,
    display: "flex",
    alignItems: "center",
    borderLeft: "8px solid",
    height: "11.5rem",
  },
  headerContainer: { display: "flex", justifyContent: "space-between" },
  headerLabel: { marginRight: 2 },
  specsContainer: { flex: 1, marginLeft: 1 },
  machinePhotoContainer: {
    display: "flex",
    width: "152px",
    height: "152px",
  },
}
