import BAPI from "..";

interface getUIConfig {
  yaml_file_name: string
}

const UIAPI = {
  async getUIConfig(body: getUIConfig) {
    return BAPI.post("get_ui_config", body)
  },
}

export default UIAPI
