import React from "react"

import { useDispatch, useSelector } from "react-redux"

import { Avatar, Box, Toolbar } from "@mui/material"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"

import logoCoreBeat from "../../../assets/logos/beatLogo.svg"

import { RootState } from "../../../store/rootReducer"
import { setIsSettingsOpen } from "../../../store/rootSlice"
import { styles } from "./styles"
import { navigateToLandingPage } from "core/helpers"
import { useNavigate } from "react-router-dom"

const AppHeader = () => {
  const { isSettingsOpen } = useSelector((state: RootState) => state.global)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSettingsOpen = () => {
    dispatch(setIsSettingsOpen(!isSettingsOpen))
  }

  const logo = logoCoreBeat

  return (
    <Toolbar sx={styles.toolbar}>
      <Box sx={styles.container}>
        <Box
          sx={styles.icon}
          component="img"
          onClick={() => navigateToLandingPage(navigate)}
          src={logo}
          alt="corebeat icon"
        />
      </Box>

      {/* <HeaderSearchBar /> */}
      <Avatar
        data-cy="settingsButton"
        sx={styles.settings}
        onClick={handleSettingsOpen}
      >
        <Box sx={styles.personIcon}>
          <PersonOutlineIcon />
        </Box>
      </Avatar>
    </Toolbar>
  )
}

export default AppHeader
