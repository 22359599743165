export const styles = {
  tab: {
    fontWeight: 700,
    minHeight: "20px",
    height: "20px",
    fontSize: "1rem",
    textAlign: "start",
    color: "primary.main",
    display: "flex",
    borderRight: "1px solid",
    borderColor: "divider",
    ":last-of-type": {
      borderRight: "none",
    },
  },
}
