import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react"
import { AxiosError } from "axios"

const bapiBaseQuery =
  (): BaseQueryFn<
    {
      api: any
    },
    unknown,
    unknown
  > =>
  async ({ api }) => {
    try {
      const result = await api
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export const apiSlice = createApi({
  baseQuery: bapiBaseQuery(),
  endpoints: () => ({}),
})
