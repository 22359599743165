export const WIDTH_ANOMALY_DETECTION_GRAPH = 1200

export const DEFAULT_MARGIN = 3

export const DEFAULT_SPACING_FACTOR = 8

export const DEFAULT_ANIMATION_DURATION = 200 //ms
export const GRID_SPACING = 2

export const DRAWER_WIDTH = 150
export const ROW_HEIGHT = 600

export const EUROPEAN_PROJECTS_URLS = [
  "level-up",
  "moses",
  "prelude",
  "localhost",
  "127.0.0.1",
]

export const MOCKING_URL_PATH = "mocked-backend"
export const DYNAMIC_UI_URL_PATH = "dynamic-ui"
