import React from "react"

import { IBaseCard } from "types/cards"

import Paper from "@mui/material/Paper"

import BaseCardHeader from "./subcomponents/BaseCardHeader"
import { styles } from "./styles"

const BaseCard = (props: Omit<IBaseCard, "headerLabel" | "boxStyle">) => {
  const {
    children,
    size,
    sx = {},
    title = "",
    showDivider = false,
    testId = "",
    lastUpdate,
    preTitleIcon,
    afterTitleIcon,
    customHeader,
  } = props

  let cardStyle
  switch (size) {
    case "small":
      cardStyle = styles.small
      break
    case "large":
      cardStyle = styles.large
      break
    default:
      cardStyle = styles.empty
  }

  return (
    <Paper data-testid={testId} sx={{ ...cardStyle, ...sx }}>
      {!customHeader ? (
        <BaseCardHeader
          preTitleIcon={preTitleIcon}
          afterTitleIcon={afterTitleIcon}
          lastUpdate={lastUpdate}
          showDivider={showDivider}
          headerLabel={title}
        />
      ) : (
        customHeader
      )}

      {children}
    </Paper>
  )
}

export default BaseCard
