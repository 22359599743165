export const styles = {
  baseCard: {
    width: "100%",
    m: 0,
    pt: 0,
  },
  graphContainer: {
    m: 0,
    mt: 2,
    pr: 1,
  },
  subtitleText: {
    textAlign: "center",
  },
}
