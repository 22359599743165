import React from "react"

interface ICircleComponent {
  center: { x: number; y: number }
  radius: number
  circumference?: number
  item?: { strokeDashoffset: number; color: string }
  strokeWidth?: number
  color?: string
  rotate?: number
}

const CircleComponent = ({
  center,
  radius,
  circumference,
  item = { strokeDashoffset: 0, color: "#E2E2E2" },
  strokeWidth = 6,
  color = "#E2E2E2",
  rotate = 0,
}: ICircleComponent) => (
  <circle
    aria-label="circle"
    style={{ transform: `rotate(${rotate}deg)`, transformOrigin: "center" }}
    cx={center.x}
    cy={center.y}
    r={radius}
    strokeDasharray={!!circumference ? circumference : undefined}
    strokeDashoffset={
      !!circumference ? circumference - item.strokeDashoffset : undefined
    }
    strokeWidth={strokeWidth}
    fill="none"
    stroke={!!item ? item.color : color}
  />
)

export default CircleComponent
