type TimeObject = {
  date: string
  value: number
}

export const DEFAULT_SPAN_GAPS = 9999999

export const getSpanGraph = ({ spanGaps, startTime, endTime }: any) => {

  if (Array.isArray(spanGaps)) {
    const sorted = [...spanGaps].sort(
      (a: any, b: any) => a.threshold - b.threshold,
    )

    const selectedRange = endTime - startTime

    const selectedOrderBy =
      sorted.find((item: any) => selectedRange <= item.threshold)?.value ??
      sorted[sorted.length - 1].value

    return selectedOrderBy
  }

  return spanGaps
}

export const getGroupBy = ({ requestBody, startTime, endTime }: any) => {
  const { symbols_names, symbols_values } = requestBody
  const indexOfGroupBy = symbols_names.indexOf("group_by")

  if (indexOfGroupBy === -1) return false

  const groupByValues = symbols_values[indexOfGroupBy]

  if (Array.isArray(groupByValues)) {
    const sorted = [...groupByValues].sort(
      (a: any, b: any) => a.threshold - b.threshold,
    )

    const selectedRange = endTime - startTime

    const selectedOrderBy =
      sorted.find((item: any) => selectedRange <= item.threshold)?.value ??
      sorted[sorted.length - 1].value

    return selectedOrderBy
  }

  return groupByValues
}

export const interpolateGraphData = (
  startDate: number,
  endDate: number,
  dataObject: TimeObject[],
) => {
  const newData: TimeObject[] = []
  const currentDate = new Date(startDate * 1000)

  // set minutes and seconds to 0 for each hour
  currentDate.setMinutes(0, 0)

  while (currentDate.getTime() <= endDate * 1000) {
    // const dateString = currentDate.toISOString();

    const existingObject = dataObject.find(
      (obj) => new Date(obj.date).getTime() === currentDate.getTime(),
    )

    if (existingObject) {
      newData.push(existingObject)
    } else {
      newData.push({ date: currentDate.toISOString(), value: 3 })
    }

    currentDate.setHours(currentDate.getHours() + 1)
  }

  return { data: newData }
}

export const getSymbolValues = ({ requestParams, startTime, endTime }: any) => {
  const payload = {
    ...requestParams[0].requestBody.symbols_values,
    [requestParams[0].requestBody.symbols_names.indexOf("group_by")]:
      getGroupBy({
        requestBody: requestParams[0].requestBody,
        startTime,
        endTime,
      }),
  }

  return Object.values(payload)
}
