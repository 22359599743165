import React from "react"

import { DEFAULT_ANIMATION_DURATION } from "core/constants/ui"
import { useSelector } from "react-redux"

import { Paper, Slide } from "@mui/material"

import AssetsTabsDetailView from "./subcomponents/AssetsTabsDetailView"
import HeaderDetailView from "./subcomponents/HeaderDetailView"
import { RootState } from "../../../store/rootReducer"

const AppDrawerDetails = () => {
  const selectState = (state: RootState) => state.global
  const { isDrawerDetailsOpen } = useSelector(selectState)

  return (
    <Slide
      timeout={DEFAULT_ANIMATION_DURATION}
      easing="ease-in-out"
      direction="left"
      in={isDrawerDetailsOpen}
      mountOnEnter
      unmountOnExit
    >
      <Paper sx={{ px: 1, minHeight: "calc(100vh - 290px)" }}>
        <HeaderDetailView />
        <AssetsTabsDetailView />
      </Paper>
    </Slide>
  )
}

export default AppDrawerDetails
