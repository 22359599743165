import { isDynamicUIEnabled } from "core/helpers/dynamic_ui"
import { RootState } from "store/rootReducer"
import { store } from "store/store"

export type categoryTitle = {
  titleTranslations: string
  iconCode: string
  idsAvailable: string[]
}

export const getDynamicScreens = (state: RootState) => {
  const configUI = state.ui.configuration
  const extraScreens = configUI?.extraScreens ?? []

  return [
    { machineDetailScreen: configUI.machineDetailScreen },
    ...extraScreens,
  ]
}

export const getDynamicUrls = () =>
  getDynamicScreens(store.getState()).map((screen) => Object.keys(screen)[0])

export const exportCategoryTitles = () => {
  const state = store.getState()
  const configUI = state.ui.configuration
  const screensToHide = configUI?.screensToHide ?? []

  const allScreens = getDynamicScreens(state)

  const defaultCategoryTitles: categoryTitle[] = [
    {
      titleTranslations: "category.dashboard",
      iconCode: "dashboard",
      idsAvailable: ["dashboard"],
    },
    {
      titleTranslations: "category.facilities",
      iconCode: "factory",
      idsAvailable: ["facilities", "machineDetailScreen"],
    },
  ]

  if (isDynamicUIEnabled()) {
    allScreens.forEach((screen) => {
      const screenName = Object.keys(screen)[0]

      defaultCategoryTitles.push({
        titleTranslations: screen[screenName].title,
        iconCode: screen[screenName].iconCode,
        idsAvailable: [screenName],
      })
    })
  }

  return defaultCategoryTitles.filter(
    (categoryTitle) => !screensToHide.includes(categoryTitle.idsAvailable[0]),
  )
}
