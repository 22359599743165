export const styles = {
  errorMessage: {
    color: "error.contrastText",
    height: "fit-content",
  },
  errorContainer: {
    width: "530px",
    height: "48px",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    backgroundColor: "error.main",
  },
  icon: {
    marginLeft: 10 / 8,
    color: "error.contrastText",
  },
}
