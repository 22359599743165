import { useEffect } from "react"

import { getToken } from "libraries/firebaseInit"
import { useDispatch } from "react-redux"
import { setFmcToken } from "store/rootSlice"

const FmcNotifications = () => {
  const dispatch = useDispatch()

  // To load once
  useEffect(() => {
    let fmcToken
    ;(async () => {
      fmcToken = await getToken()
      if (fmcToken) {
        dispatch(setFmcToken(fmcToken))
      }

      console.debug("FMC token ", fmcToken)
      return fmcToken
    })()
  }, [])

  return <></>
}

FmcNotifications.propTypes = {}

export default FmcNotifications
