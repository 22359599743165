export const styles = {
  tab: {
    fontWeight: 700,
    minHeight: "20px",
    fontSize: "0.875rem",
    color: "primary.main",
    display: "flex",
    my: 3,
    borderRight: "1px solid",
    borderColor: "divider",
    "&.Mui-selected": {
      color: "text.primary",
      fontWeight: 700,
    },
    ":last-of-type": {
      borderRight: "none",
    },
  },
}
