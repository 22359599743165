import { useTheme } from "@mui/material/styles"
export const styles = (theme = useTheme()) => ({
  container: {
    height: "100%",
    width: "5.938rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 auto",
    // cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "5rem",
    },
  },
  svgContainer: {
    svg: { display: "block" },
  },

  labelText: {
    fontSize: "0.875rem",
  },
  text: {
    fontSize: "0.813rem",
    fontFamily: "Roboto-Light",
  },
})
