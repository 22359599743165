import React from "react"

import { ChartOptions } from "chart.js"
import LineChart from "core/components/ChartJS"
import { reformAssetData } from "core/dataActions"
import { RootState } from "store/rootReducer"
import { secondsToMilliseconds, toDate } from "date-fns"
import { useAppGetVibrationQuery } from "core/hooks/apiHooks"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { useTheme } from "@mui/material"

import { fontSpecs } from "../helpers"

import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import SimpleHeader from "../MachineDetailScreen/subcomponents/SimpleHeader"

const labelsScaleFactor = 120

const MachineVibrationGraph = ({
  selectedAssetId: assetId,
  title = "",
}: any) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const {
    showThresholds,
    globalGraphUnit,
    globalSelectedRange: vibrationGraphSelectedRange,
  } = useSelector((state: RootState) => state.global)

  const { assetAxisSelected, assetTypeVelocity } = useSelector(
    (state: RootState) => state.assets,
  )
  const isAcceleration = assetTypeVelocity === "acceleration"

  const [_, setMaxTicksGraphLabels] = React.useState(
    Math.trunc(window.innerWidth / labelsScaleFactor),
  )
  const handleWindowResize = () => {
    setMaxTicksGraphLabels(Math.trunc(window.innerWidth / labelsScaleFactor))
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [[window.innerWidth]])

  const minDateToDisplay = toDate(
    secondsToMilliseconds(vibrationGraphSelectedRange.fromEpoch),
  ).toISOString()
  const maxDateToDisplay = toDate(
    secondsToMilliseconds(vibrationGraphSelectedRange.toEpoch),
  ).toISOString()

  const graphConfig: ChartOptions<"line"> = {
    scales: {
      x: {
        offset: false,
        min: minDateToDisplay,
        max: maxDateToDisplay,
        title: {
          display: false,
        },
        type: "time",

        time: {
          unit: globalGraphUnit,
          displayFormats: {
            hour: "HH:mm",
            day: "dd/MM/yyyy",
          },
        },

        ticks: {
          autoSkip: false,
          font: {
            size: 10,
          },
          color: theme.palette.text.secondary,
          maxTicksLimit: 9,
        },
      },
      y: {
        ticks: {
          display: false,
          font: {
            size: 10,
          },

          color: theme.palette.text.secondary,
          maxTicksLimit: 6,
        },
        title: {
          display: true,
          font: fontSpecs,
          text: (isAcceleration
            ? t("measure.unit.acceleration.label")
            : t("measure.unit.velocity.label")) as string,
        },
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
  }

  const dataSelector = isAcceleration
    ? `rms_${assetAxisSelected}`
    : `velocity_${assetAxisSelected}`

  const {
    vibrationData = [],
    isFetching,
    isError,
    refetch,
  } = useAppGetVibrationQuery(
    {
      assetId,
      axis: assetAxisSelected,
      ...vibrationGraphSelectedRange,
      shouldPoll: true,
    },

    assetTypeVelocity,
    dataSelector,
    {
      skip: !assetId,
    },
  )

  const graphData = reformAssetData(vibrationData)

  const thresholdCritical =
    vibrationData.length > 0
      ? graphData
          .map((item: any) => item.y)
          .reduce((a: any, b: any) => Math.max(a, b)) * 1.1
      : 0

  const thresholdWarning = thresholdCritical * 0.8

  return (
    <AppGraphWrapper
      subheader={<SimpleHeader title={title} />}
      isLoading={isFetching}
      hasError={isError}
      onRetry={refetch}
      noDataCheck={!vibrationData.length}
    >
      <div style={{ height: "85%" }}>
        <LineChart
          datasetLabel={t(isAcceleration ? "acceleration.text" : "velocity")}
          zoomEnabled={false}
          config={graphConfig}
          upperBoundOffset={0.5}
          lineColor={theme.palette.primary.main}
          graphData={graphData}
          thresholds={
            showThresholds
              ? {
                  warning: thresholdWarning,
                  critical: thresholdCritical,
                }
              : null
          }
        />
      </div>
    </AppGraphWrapper>
  )
}

export default MachineVibrationGraph
