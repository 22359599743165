import { apiSlice } from "store/queryApi";
import DashboardAPI from "core/api/DashboardApi";
import { IFindOrganizationById } from "types/dashboardApi";
import { IOrganization } from "models/interfaces/organization";
import { RootState } from "store/rootReducer";

import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

const organizationAdapter = createEntityAdapter({
  selectId: (organization: any) => organization.organizationId,
})

export const initialState = organizationAdapter.getInitialState({
  organizationId: null,
  organizationsCollection: [],
  organizationSelected: null,
}) as any

export const organizationSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {
    setOrganizationId: (state, action) => {
      state.organizationId = action.payload
    },
    setOrganizationSelected: (state, action) => {
      state.organizationSelected = action.payload
    },
    addOrganization: organizationAdapter.addOne,
  },
})

export const organizationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganization: builder.query<IOrganization, IFindOrganizationById>({
      query: ({ uuid }: IFindOrganizationById) => ({
        api: DashboardAPI.findOrganisationById({
          uuid,
        }),
      }),
      // one year
      keepUnusedDataFor: 31557600,
    }),
  }),
})

export const { useGetOrganizationQuery, useLazyGetOrganizationQuery } = organizationApiSlice

export const { selectById: selectOrgById } = organizationAdapter.getSelectors(
  (state: RootState) => state.organizations,
)

export const { setOrganizationId, addOrganization, setOrganizationSelected } =
  organizationSlice.actions
// export default organizationReducer
export default organizationSlice.reducer
