import {
  IGenerateAssetOverallInformation,
  IGenerateConditionMonitoringCard,
  IGetBeatBoxRMSByBeatBoxIDFormedTimeFrame,
  IPostGetTimeseriesParams,
  RMS,
  temperature,
} from "types/assetsApi"

import BAPI from "../api"

const AssetsAPI = {
  generateAssetOveralInfrormation(params: IGenerateAssetOverallInformation) {
    const { machineId, assetId } = params
    return BAPI.get(
      `/view/asset/information/machine/${machineId}/asset/${assetId}/info/overal`,
    )
  },

  getRMS(params: RMS) {
    const { assetId, axis, fromEpoch, toEpoch } = params
    if (fromEpoch && toEpoch) {
      return BAPI.get(
        `/rms/${assetId}?axis=${axis}&start_time=${fromEpoch}&end_time=${toEpoch}`,
      )
    }

    return BAPI.get(`rms/${assetId}`)
  },

  getTemp(params: temperature) {
    const { assetId, start_time, end_time } = params
    if (start_time && end_time) {
      return BAPI.get(
        `/temp/${assetId}?start_time=${start_time}&end_time=${end_time}`,
      )
    }

    return BAPI.get(`temp/${assetId}`)
  },

  postGetTsData(body: IPostGetTimeseriesParams) {
    return BAPI.post("/get_ts_data", body)
  },

  getVelocity(params: RMS) {
    const { assetId, axis, fromEpoch, toEpoch } = params
    if (fromEpoch && toEpoch) {
      return BAPI.get(
        `/vel/${assetId}?select_axis=${axis}&from_epoch=${fromEpoch}&to_epoch=${toEpoch}`,
      )
    }

    return BAPI.get(`vel/${assetId}`)
  },

  getBeatBoxRMSByBeatBoxIDFormedTimeFrame(
    params: IGetBeatBoxRMSByBeatBoxIDFormedTimeFrame,
  ) {
    const {
      beatBoxId,
      fromEpoch,
      toEpoch,
      select,
      frequencyHour = 0,
      orderBy = "time",
      orderByType = "DESC",
    } = params

    return BAPI.get(
      `/view/historical/beatbox/rms/measurements/${beatBoxId}/time-frame?frequency_hour=${frequencyHour}&fromEpoch=${fromEpoch}&orderBy=${orderBy}&orderByType=${orderByType}&select=${select}&toEpoch=${toEpoch}`,
    )
  },

  generateConditionMonitoringCard(params: IGenerateConditionMonitoringCard) {
    const { machineId, assetId } = params

    return BAPI.get(
      `/view/asset/information/machine/${machineId}/asset/${assetId}/info/conditionMonitoring`,
    )
  },

  getAssetInfo(assetId: string) {
    return BAPI.get(`/asset/${assetId}`)
  },
  getPrediction() {
    return BAPI.get("/prediction")
  },
}

export default AssetsAPI
