export const styles = {
  graphsContainer: {
    padding: 1,
    paddingRight: 20 / 8,
    textAlign: "center",
    minHeight: "calc(100vh - 630px)",
  },

  headerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },

  lastUpdate: {
    color: "text.secondary",
    fontSize: "0.75rem",
  },
}
