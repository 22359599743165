export const styles = {
  tablist: {
    ".MuiTabs-flexContainer": {
      justifyContent: "flex-start",
      alignItems: "center",
      textAlign: "start",
      display: "flex",
      my: 0,
    },

    button: {
      fontSize: "1rem",
      color: "text.secondary",
      my: 0,
    },
    ".MuiTab-root": {
      textTransform: "capitalize",
      px: 1,
      display: "flex",
      alignItems: "flex-start",
      minWidth: "fit-content",
    },

    ".MuiTabs-indicator": {
      display: "none",
    },
  },

  boxContainer: {
    ".MuiTabs-root": {
      m: 0,
      minHeight: "20px",
      textAlign: "start",
    },

    display: "flex",
    justifyContent: "flex-start",
    textAlign: "start",
    mb: 1,
  },
  baseCard: {
    alignItems: "center",
    justifyContent: "flex-start",
    height: "11rem",
    p: 1,
    pt: 0,
    pb: 2,
  },
  imgAndInfoContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",
    height: "100%",
    flexGrow: 4,
    ml: 1,
  },
  siteNameText: {
    alignSelf: "flex-start",
    textTransform: "capitalize",
    justifySelf: "flex-end",
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
  },
}
