import { createSlice } from "@reduxjs/toolkit";

interface RequestBody {
  table_name: string
  symbols_names: Array<string>
  symbols_values: Array<string>
  column_name: string
  timestamp_name: string
  start_time: Date
  end_time: Date
}

interface RequestParams {
  serviceName: string
  url: string
  requestType: string
  offset: {
    value: number
    unit: string
  }
  requestBody: RequestBody
}

interface Graph {
  titleCode: string
  thresholds: {
    warning: number
    critical: number
  }
  type: string
  iconCode: string
  infoCode: string
  measureUnitCode: string
  assetId: string
  tooltipText: {
    en: string
    el: string
  }
  requestParams: RequestParams
}

interface UITypes {
  isLoading: boolean
  thresholdError: { value: boolean; message: string }
  hasNotification: boolean
  configuration: {
    screensToHide?: Array<string>
    landingPage?: string
    extraScreens?: Array<any>
    machineDetailScreen: {
      title: Array<string>
      machines: Array<{
        id: string
        rows: Array<{
          title: { en: string; el: string }
          heightFactor: number
          columns: Array<{
            graph: Array<Graph>
            type: string
            iconCode: string
            measureUnitCode: string
            infoCode: string
            assetId: string
          }>
        }>
      }>
    }
  }
}

const initialState: UITypes = {
  isLoading: false,
  thresholdError: { value: false, message: "" },
  hasNotification: false,
  configuration: {
    machineDetailScreen: {
      title: [],
      machines: [],
    },
  },
}

const UISlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setThresholdError: (state, action) => {
      state.thresholdError = action.payload
    },
    setDynamicUI: (state, action) => {
      state.configuration = action.payload
    },
    setHasNotification: (state, action) => {
      state.hasNotification = action.payload
    },
  },
})

export const {
  setIsLoading,
  setThresholdError,
  setDynamicUI,
  setHasNotification,
} = UISlice.actions

export default UISlice.reducer
