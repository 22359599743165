import axios from "axios"
import { getNewToken } from "core/auth/auth"

import Config from "../config"

const axiosConfig = () => {
  axios.interceptors.request.use(
    async (reqConfig) => {
      const token = localStorage.getItem("token")

      if (token) {
        reqConfig!.headers!.Authorization = `Bearer ${token}`
      }
      return reqConfig
    },
    (error) => {
      Promise.reject(error)
    },
  )

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error?.config
      if (error?.response?.status === 401 && !originalRequest.retry) {
        originalRequest.retry = true
        const token = await getNewToken()
        localStorage.setItem("token", token)
        return axios(originalRequest)
      }
      return Promise.reject(error)
    },
  )

  axios.defaults.baseURL = Config.baseUrl
  axios.defaults.headers.post["Content-Type"] = "application/json"
}

export default axiosConfig
