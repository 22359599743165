import React from "react"
import { Tab } from "@mui/material"
import { setSelectedSiteId, useGetSiteInfoQuery } from "store/Sites/sitesSlice"
import { styles } from "./styles"
import { useDispatch } from "react-redux"
import AppTypography from "core/components/AppTypography"

const SiteTab = ({ id, ...rest }: any) => {
  const dispatch = useDispatch()

  const { siteName, siteId } = useGetSiteInfoQuery(id, {
    skip: !id,
    selectFromResult: ({ data }) => ({
      siteName: data?.site_name,
      siteId: data?.site_id,
    }),
  })
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    dispatch(setSelectedSiteId(newValue))
  }
  return (
    <Tab
      {...rest}
      sx={styles.tab}
      label={<AppTypography variant="h2">{siteName}</AppTypography>}
      onClick={(e) => handleChange(e, siteId)}
    />
  )
}

export default SiteTab
