
export const mockedResponses = {
  get_ts_data: {
    meta: {
      query_data_for_validation: {
        table_name: "core_beat_test_table",
        symbols_names: ["corporation", "production_line", "machine"],
        symbols_values: ["CORP1", "PL3", "M1"],
        column_name: "value",
        timestamp_name: "ts",
        start_time: "2023-06-01T00:00:00.000000Z",
        end_time: "2023-06-30T00:00:00.000000Z",
      },
      query_timestamp: "2023-06-22T13:20:57.481020Z",
      warnings: [],
    },
    data: [
      {
        value: 18.015392303466797,
        timestamp: "2023-06-01T00:00:02.100000Z",
      },
      {
        value: 16.050777435302734,
        timestamp: "2023-06-01T00:00:03.900000Z",
      },
    ],
  },
  get_ui_config: {
    configUI: {
      machineDetailScreen: {
        title: {
          el: "δυναμική σελιδα μηχανων",
          en: "dynamic machine detail",
        },
        machines: [
          {
            id: "01GWVZB4MPRKSVEFKM39XHMCE6",
            rows: [
              {
                title: {
                  en: "Spindle-1",
                  el: "Δυναμική Λεπτομέρεια Μηχανής",
                },
                heightFactor: 0.4,
                columns: [
                  {
                    graph: {
                      title: {
                        en: "Severity_range X_axis",
                        el: "Δριμύτητα range X_axis",
                      },
                      thresholds: {
                        warning: 30.33,
                        critical: 50.32,
                      },
                      type: "simpleTimeseries",
                      iconCode: "favorite",
                      infoText: {
                        en: "example text with info",
                        el: "δείγμα κειμένου με πληροφορίες",
                      },
                      measureUnitCode: {
                        en: "m/sec",
                        el: "μ/δευτ",
                      },
                      assetId: "01GWVZB4MPH1S5VCNVDVMSHP5S",
                      tooltipText: {
                        en: "Spindle-1/Sensor-A",
                        el: "Αυτό είναι ένα εργαλείο συμβουλών στο timeseries",
                      },
                      requestParams: [
                        {
                          serviceName: "questDB_timeseries",
                          url: "http://localhost:9000",
                          requestType: "POST",
                          offset: {
                            value: 0,
                            unit: "hours",
                          },
                          requestBody: {
                            table_name: "levelup",
                            symbols_names: ["pilot", "measurement", "machine"],
                            symbols_values: ["lucchini", "acc", "SM_2"],
                            column_name: "SIGNAL_1_SeverityRange_1",
                            timestamp_name: "LocalFireDepartment",
                            start_time: "2023-06-01T00:00:00.000000Z",
                            end_time: "2023-06-30T00:00:00.000000Z",
                          },
                        },
                        {},
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  },
  organization: {
    organisation_id: "01GWVD0DP01MRFHEWBZT94G27W",
    site_collection: [
      {
        site_id: "01GWVD0DP0JBNYEM58GZM1V72P",
        department_collection: [
          {
            department_id: "01GWVD0DP0473K1HS364S6MRZR",
            production_line_collection: [
              {
                production_line_id: "01GWVD0DP0FGG8T415A8NHRKWW",
                machine_collection: [
                  {
                    machine_id: "01GWVZB4MP7SC13MFV2V8TWE1X",
                    asset_collection: [
                      {
                        asset_id: "01GWVZB4MPH1S5VCNVDVMSHP5S",
                      },
                    ],
                  },
                  {
                    machine_id: "01GWVZB4MPRKSVEFKM39XHMCE6",
                    asset_collection: [
                      {
                        asset_id: "01GWVZB4MP8X7R80MBJHXMGYHG",
                      },
                      {
                        asset_id: "01GWVZX2RY2YPRSTY0ZJR949Z3",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  site: {
    site_id: "01GWVD0DP0JBNYEM58GZM1V72P",
    organisation_id: "01GWVD0DP01MRFHEWBZT94G27W",
    site_address_id: "01GWVD0DP0NDZ00XPZXD6QYDRH",
    site_name: "Pilotiko",
    site_description: null,
    site_timestamp: 1680695891000,
  },
  userInfo: {
    attributes: {
      sub: "804f3225-5409-4d3b-91cb-2097f7f7ce28",
      "custom:name": "George",
      "custom:surnmae": "Gkotsis",
      "custom:client-organisation": "dfa22d06-9d1a-11eb-a8b3-0242ac130003",
      name: "ggkotsis@core-innovation.com",
      "custom:shift": "8",
      "custom:job_title": "FrontEnd Enineer",
      "custom:role": "super-admin",
      email: "ggkotsis@core-innovation.com",
    },
  },
  machinesOverview: [
    { key: "total", value: 4 },
    { key: "normal", value: 3 },
    { key: "warning", value: 1 },
    { key: "critical", value: 0 },
  ],
  machineStateGraphDataWeek: {
    duration_type: "week",
    data: [
      {
        duration_type: {
          period: "day",
          period_index: 4,
        },
        normal: 1,
        warning: 23,
        critical: 5,
      },
      {
        duration_type: {
          period: "day",
          period_index: 5,
        },
        normal: 1,
        warning: 6,
        critical: 7,
      },
      {
        duration_type: {
          period: "day",
          period_index: 1,
        },
        normal: 18,
        warning: 7,
        critical: 14,
      },
      {
        duration_type: {
          period: "day",
          period_index: 1,
        },
        normal: 11,
        warning: 8,
        critical: 23,
      },
      {
        duration_type: {
          period: "day",
          period_index: 5,
        },
        normal: 16,
        warning: 16,
        critical: 0,
      },
      {
        duration_type: {
          period: "day",
          period_index: 2,
        },
        normal: 14,
        warning: 9,
        critical: 21,
      },
      {
        duration_type: {
          period: "day",
          period_index: 1,
        },
        normal: 14,
        warning: 20,
        critical: 8,
      },
    ],
  },
  machineStateGraphDataMonth: {
    duration_type: "month",
    data: [
      {
        duration_type: {
          period: "week",
          period_index: 1,
        },
        normal: 1,
        warning: 10,
        critical: 16,
      },
      {
        duration_type: {
          period: "week",
          period_index: 0,
        },
        normal: 2,
        warning: 18,
        critical: 15,
      },
      {
        duration_type: {
          period: "week",
          period_index: 2,
        },
        normal: 17,
        warning: 11,
        critical: 12,
      },
      {
        duration_type: {
          period: "week",
          period_index: 2,
        },
        normal: 12,
        warning: 0,
        critical: 9,
      },
    ],
  },
  machineStateGraphDataYear: {
    duration_type: "year",
    data: [
      {
        duration_type: {
          period: "month",
          period_index: 3,
        },
        normal: 22,
        warning: 22,
        critical: 8,
      },
      {
        duration_type: {
          period: "month",
          period_index: 5,
        },
        normal: 11,
        warning: 23,
        critical: 6,
      },
      {
        duration_type: {
          period: "month",
          period_index: 3,
        },
        normal: 16,
        warning: 1,
        critical: 22,
      },
      {
        duration_type: {
          period: "month",
          period_index: 1,
        },
        normal: 5,
        warning: 21,
        critical: 13,
      },
      {
        duration_type: {
          period: "month",
          period_index: 1,
        },
        normal: 23,
        warning: 20,
        critical: 9,
      },
      {
        duration_type: {
          period: "month",
          period_index: 1,
        },
        normal: 15,
        warning: 3,
        critical: 7,
      },
      {
        duration_type: {
          period: "month",
          period_index: 5,
        },
        normal: 8,
        warning: 7,
        critical: 14,
      },
      {
        duration_type: {
          period: "month",
          period_index: 3,
        },
        normal: 5,
        warning: 11,
        critical: 21,
      },
      {
        duration_type: {
          period: "month",
          period_index: 3,
        },
        normal: 15,
        warning: 14,
        critical: 10,
      },
      {
        duration_type: {
          period: "month",
          period_index: 2,
        },
        normal: 12,
        warning: 22,
        critical: 10,
      },
      {
        duration_type: {
          period: "month",
          period_index: 1,
        },
        normal: 2,
        warning: 15,
        critical: 10,
      },
      {
        duration_type: {
          period: "month",
          period_index: 3,
        },
        normal: 22,
        warning: 19,
        critical: 15,
      },
    ],
  },
  machineStateDuration: {
    duration: 18602,
    state: "0",
  },
  machineStateNoParams: {
    state: "0",
    health: 19,
  },
  machineStateError: {
    error: "error",
  },
  machineState: {
    state: [
      {
        date: 1680265599,
        value: "0",
      },
      {
        date: 1680265600,
        value: "2",
      },
      {
        date: 1680265601,
        value: "0",
      },
      {
        date: 1680265602,
        value: "0",
      },
      {
        date: 1680265603,
        value: "1",
      },
      {
        date: 1680265604,
        value: "1",
      },
      {
        date: 1680265605,
        value: "1",
      },
      {
        date: 1680265606,
        value: "1",
      },
      {
        date: 1680265607,
        value: "0",
      },
      {
        date: 1680265608,
        value: "0",
      },
      {
        date: 1680265609,
        value: "2",
      },
      {
        date: 1680265610,
        value: "2",
      },
      {
        date: 1680265611,
        value: "1",
      },
      {
        date: 1680265612,
        value: "2",
      },
      {
        date: 1680265613,
        value: "0",
      },
      {
        date: 1680265614,
        value: "2",
      },
      {
        date: 1680265615,
        value: "1",
      },
      {
        date: 1680265616,
        value: "0",
      },
      {
        date: 1680265617,
        value: "1",
      },
      {
        date: 1680265618,
        value: "1",
      },
      {
        date: 1680265619,
        value: "2",
      },
      {
        date: 1680265620,
        value: "1",
      },
      {
        date: 1680265621,
        value: "2",
      },
      {
        date: 1680265622,
        value: "0",
      },
      {
        date: 1680265623,
        value: "1",
      },
      {
        date: 1680265624,
        value: "1",
      },
      {
        date: 1680265625,
        value: "1",
      },
      {
        date: 1680265626,
        value: "2",
      },
      {
        date: 1680265627,
        value: "1",
      },
      {
        date: 1680265628,
        value: "2",
      },
      {
        date: 1680265629,
        value: "0",
      },
      {
        date: 1680265630,
        value: "1",
      },
      {
        date: 1680265631,
        value: "0",
      },
      {
        date: 1680265632,
        value: "2",
      },
      {
        date: 1680265633,
        value: "2",
      },
      {
        date: 1680265634,
        value: "2",
      },
      {
        date: 1680265635,
        value: "2",
      },
      {
        date: 1680265636,
        value: "1",
      },
      {
        date: 1680265637,
        value: "0",
      },
      {
        date: 1680265638,
        value: "2",
      },
      {
        date: 1680265639,
        value: "1",
      },
      {
        date: 1680265640,
        value: "1",
      },
      {
        date: 1680265641,
        value: "0",
      },
      {
        date: 1680265642,
        value: "0",
      },
      {
        date: 1680265643,
        value: "0",
      },
      {
        date: 1680265644,
        value: "1",
      },
      {
        date: 1680265645,
        value: "2",
      },
      {
        date: 1680265646,
        value: "2",
      },
      {
        date: 1680265647,
        value: "1",
      },
      {
        date: 1680265648,
        value: "2",
      },
      {
        date: 1680265649,
        value: "1",
      },
      {
        date: 1680265650,
        value: "1",
      },
      {
        date: 1680265651,
        value: "1",
      },
      {
        date: 1680265652,
        value: "0",
      },
      {
        date: 1680265653,
        value: "1",
      },
      {
        date: 1680265654,
        value: "0",
      },
      {
        date: 1680265655,
        value: "2",
      },
      {
        date: 1680265656,
        value: "2",
      },
      {
        date: 1680265657,
        value: "0",
      },
      {
        date: 1680265658,
        value: "1",
      },
      {
        date: 1680265659,
        value: "2",
      },
      {
        date: 1680265660,
        value: "1",
      },
      {
        date: 1680265661,
        value: "2",
      },
      {
        date: 1680265662,
        value: "0",
      },
      {
        date: 1680265663,
        value: "1",
      },
      {
        date: 1680265664,
        value: "2",
      },
      {
        date: 1680265665,
        value: "0",
      },
      {
        date: 1680265666,
        value: "0",
      },
      {
        date: 1680265667,
        value: "1",
      },
      {
        date: 1680265668,
        value: "2",
      },
      {
        date: 1680265669,
        value: "0",
      },
      {
        date: 1680265670,
        value: "1",
      },
      {
        date: 1680265671,
        value: "0",
      },
    ],
    health: [
      {
        date: 1680265599,
        value: 72,
      },
      {
        date: 1680265600,
        value: 95,
      },
      {
        date: 1680265601,
        value: 8,
      },
      {
        date: 1680265602,
        value: 84,
      },
      {
        date: 1680265603,
        value: 50,
      },
      {
        date: 1680265604,
        value: 45,
      },
      {
        date: 1680265605,
        value: 23,
      },
      {
        date: 1680265606,
        value: 93,
      },
      {
        date: 1680265607,
        value: 30,
      },
      {
        date: 1680265608,
        value: 66,
      },
      {
        date: 1680265609,
        value: 53,
      },
      {
        date: 1680265610,
        value: 49,
      },
      {
        date: 1680265611,
        value: 36,
      },
      {
        date: 1680265612,
        value: 10,
      },
    ],
  },
  assetStateNoParams: {
    state: "0",
    health: 81,
  },
  assetState: {
    state: [
      {
        date: 1680265599,
        value: "1",
      },
      {
        date: 1680265600,
        value: "1",
      },
      {
        date: 1680265601,
        value: "1",
      },
      {
        date: 1680265602,
        value: "1",
      },
      {
        date: 1680265603,
        value: "2",
      },
      {
        date: 1680265604,
        value: "1",
      },
      {
        date: 1680265605,
        value: "1",
      },
      {
        date: 1680265606,
        value: "0",
      },
      {
        date: 1680265607,
        value: "1",
      },
      {
        date: 1680265608,
        value: "1",
      },
      {
        date: 1680265609,
        value: "2",
      },
      {
        date: 1680265610,
        value: "1",
      },
      {
        date: 1680265611,
        value: "0",
      },
      {
        date: 1680265612,
        value: "1",
      },
      {
        date: 1680265613,
        value: "1",
      },
      {
        date: 1680265614,
        value: "1",
      },
      {
        date: 1680265615,
        value: "0",
      },
      {
        date: 1680265616,
        value: "0",
      },
      {
        date: 1680265617,
        value: "2",
      },
      {
        date: 1680265618,
        value: "1",
      },
      {
        date: 1680265619,
        value: "0",
      },
      {
        date: 1680265620,
        value: "1",
      },
      {
        date: 1680265621,
        value: "0",
      },
      {
        date: 1680265622,
        value: "2",
      },
      {
        date: 1680265623,
        value: "0",
      },
      {
        date: 1680265624,
        value: "2",
      },
      {
        date: 1680265625,
        value: "2",
      },
      {
        date: 1680265626,
        value: "1",
      },
      {
        date: 1680265627,
        value: "1",
      },
      {
        date: 1680265628,
        value: "1",
      },
      {
        date: 1680265629,
        value: "2",
      },
      {
        date: 1680265630,
        value: "0",
      },
      {
        date: 1680265631,
        value: "0",
      },
      {
        date: 1680265632,
        value: "2",
      },
      {
        date: 1680265633,
        value: "1",
      },
      {
        date: 1680265634,
        value: "1",
      },
      {
        date: 1680265635,
        value: "1",
      },
      {
        date: 1680265636,
        value: "0",
      },
    ],
    health: [
      {
        date: 1680265599,
        value: 1,
      },
      {
        date: 1680265600,
        value: 59,
      },
      {
        date: 1680265601,
        value: 6,
      },
      {
        date: 1680265602,
        value: 20,
      },
      {
        date: 1680265603,
        value: 39,
      },
      {
        date: 1680265604,
        value: 76,
      },
      {
        date: 1680265605,
        value: 85,
      },
      {
        date: 1680265606,
        value: 87,
      },
      {
        date: 1680265607,
        value: 90,
      },
      {
        date: 1680265608,
        value: 52,
      },
      {
        date: 1680265609,
        value: 43,
      },
      {
        date: 1680265610,
        value: 1,
      },
      {
        date: 1680265611,
        value: 47,
      },
      {
        date: 1680265612,
        value: 73,
      },
      {
        date: 1680265613,
        value: 28,
      },
      {
        date: 1680265614,
        value: 20,
      },
      {
        date: 1680265615,
        value: 45,
      },
      {
        date: 1680265616,
        value: 37,
      },
      {
        date: 1680265617,
        value: 24,
      },
      {
        date: 1680265618,
        value: 11,
      },
      {
        date: 1680265619,
        value: 66,
      },
      {
        date: 1680265620,
        value: 54,
      },
      {
        date: 1680265621,
        value: 2,
      },
      {
        date: 1680265622,
        value: 70,
      },
      {
        date: 1680265623,
        value: 4,
      },
      {
        date: 1680265624,
        value: 24,
      },
      {
        date: 1680265625,
        value: 39,
      },
      {
        date: 1680265626,
        value: 36,
      },
      {
        date: 1680265627,
        value: 55,
      },
      {
        date: 1680265628,
        value: 2,
      },
      {
        date: 1680265629,
        value: 60,
      },
      {
        date: 1680265630,
        value: 99,
      },
      {
        date: 1680265631,
        value: 23,
      },
      {
        date: 1680265632,
        value: 36,
      },
      {
        date: 1680265633,
        value: 63,
      },
      {
        date: 1680265634,
        value: 48,
      },
      {
        date: 1680265635,
        value: 26,
      },
      {
        date: 1680265636,
        value: 74,
      },
      {
        date: 1680265637,
        value: 54,
      },
      {
        date: 1680265638,
        value: 8,
      },
      {
        date: 1680265639,
        value: 30,
      },
      {
        date: 1680265640,
        value: 92,
      },
      {
        date: 1680265641,
        value: 98,
      },
      {
        date: 1680265642,
        value: 40,
      },
      {
        date: 1680265643,
        value: 46,
      },
      {
        date: 1680265644,
        value: 49,
      },
      {
        date: 1680265645,
        value: 81,
      },
      {
        date: 1680265646,
        value: 100,
      },
      {
        date: 1680265647,
        value: 35,
      },
      {
        date: 1680265648,
        value: 77,
      },
      {
        date: 1680265649,
        value: 88,
      },
      {
        date: 1680265650,
        value: 97,
      },
      {
        date: 1680265651,
        value: 11,
      },
    ],
  },
  assetTemperature: {
    values: [
      {
        date: 1677978734,
        value: 99,
      },
      {
        date: 1669491077,
        value: 77,
      },
      {
        date: 1654881372,
        value: 75,
      },
      {
        date: 1664322788,
        value: 74,
      },
      {
        date: 1673650518,
        value: 27,
      },
      {
        date: 1649848255,
        value: 49,
      },
      {
        date: 1672329982,
        value: 21,
      },
      {
        date: 1669390910,
        value: 85,
      },
      {
        date: 1671545305,
        value: 33,
      },
      {
        date: 1656821670,
        value: 21,
      },
      {
        date: 1668383732,
        value: 66,
      },
      {
        date: 1656431779,
        value: 18,
      },
      {
        date: 1667505987,
        value: 90,
      },
      {
        date: 1669382443,
        value: 71,
      },
      {
        date: 1653945119,
        value: 2,
      },
      {
        date: 1659954771,
        value: 35,
      },
      {
        date: 1661976494,
        value: 2,
      },
      {
        date: 1649924754,
        value: 3,
      },
      {
        date: 1670089311,
        value: 98,
      },
      {
        date: 1654649274,
        value: 39,
      },
      {
        date: 1669875694,
        value: 82,
      },
      {
        date: 1654010551,
        value: 91,
      },
      {
        date: 1655888750,
        value: 36,
      },
      {
        date: 1674088750,
        value: 67,
      },
      {
        date: 1658731840,
        value: 89,
      },
      {
        date: 1649435636,
        value: 33,
      },
      {
        date: 1666014122,
        value: 96,
      },
      {
        date: 1653688690,
        value: 24,
      },
      {
        date: 1651566365,
        value: 65,
      },
      {
        date: 1653336259,
        value: 62,
      },
      {
        date: 1671423976,
        value: 41,
      },
      {
        date: 1677310771,
        value: 85,
      },
      {
        date: 1669669823,
        value: 51,
      },
      {
        date: 1660020993,
        value: 75,
      },
      {
        date: 1667635832,
        value: 65,
      },
      {
        date: 1657099931,
        value: 2,
      },
      {
        date: 1676262849,
        value: 71,
      },
      {
        date: 1668358212,
        value: 32,
      },
      {
        date: 1665207266,
        value: 97,
      },
      {
        date: 1652287583,
        value: 2,
      },
      {
        date: 1675046542,
        value: 11,
      },
      {
        date: 1661566538,
        value: 74,
      },
      {
        date: 1655111169,
        value: 3,
      },
      {
        date: 1677637349,
        value: 27,
      },
      {
        date: 1656639900,
        value: 72,
      },
      {
        date: 1670163392,
        value: 11,
      },
      {
        date: 1665047702,
        value: 10,
      },
      {
        date: 1662556117,
        value: 65,
      },
      {
        date: 1666648572,
        value: 81,
      },
      {
        date: 1656890362,
        value: 84,
      },
      {
        date: 1667645416,
        value: 15,
      },
      {
        date: 1662132623,
        value: 90,
      },
      {
        date: 1652030890,
        value: 17,
      },
      {
        date: 1670314642,
        value: 9,
      },
      {
        date: 1679063255,
        value: 93,
      },
      {
        date: 1658658347,
        value: 84,
      },
      {
        date: 1678323641,
        value: 0,
      },
      {
        date: 1666119534,
        value: 13,
      },
      {
        date: 1668007546,
        value: 5,
      },
      {
        date: 1660264759,
        value: 1,
      },
      {
        date: 1677510477,
        value: 29,
      },
      {
        date: 1678707561,
        value: 40,
      },
      {
        date: 1669405073,
        value: 86,
      },
      {
        date: 1674998994,
        value: 28,
      },
      {
        date: 1662723758,
        value: 44,
      },
      {
        date: 1659381397,
        value: 1,
      },
      {
        date: 1665087838,
        value: 64,
      },
      {
        date: 1660028283,
        value: 57,
      },
      {
        date: 1655565606,
        value: 72,
      },
      {
        date: 1669116683,
        value: 49,
      },
      {
        date: 1674339282,
        value: 17,
      },
      {
        date: 1654308610,
        value: 54,
      },
      {
        date: 1650501927,
        value: 46,
      },
      {
        date: 1657576542,
        value: 82,
      },
      {
        date: 1679818944,
        value: 1,
      },
      {
        date: 1656577037,
        value: 62,
      },
      {
        date: 1672625943,
        value: 12,
      },
      {
        date: 1663177855,
        value: 50,
      },
      {
        date: 1657544947,
        value: 13,
      },
      {
        date: 1652907022,
        value: 92,
      },
      {
        date: 1677684288,
        value: 12,
      },
      {
        date: 1673204543,
        value: 86,
      },
      {
        date: 1649081246,
        value: 83,
      },
      {
        date: 1656613136,
        value: 55,
      },
      {
        date: 1679422987,
        value: 6,
      },
      {
        date: 1653977770,
        value: 24,
      },
      {
        date: 1672281300,
        value: 24,
      },
      {
        date: 1668480677,
        value: 45,
      },
      {
        date: 1654249894,
        value: 48,
      },
      {
        date: 1652862583,
        value: 59,
      },
      {
        date: 1662799033,
        value: 22,
      },
      {
        date: 1670333779,
        value: 63,
      },
      {
        date: 1652011579,
        value: 33,
      },
      {
        date: 1669812348,
        value: 72,
      },
    ],
  },
  assetTemperatureNoParams: {
    value: 55,
  },
  department: {
    department_id: "01GWVD0DP0473K1HS364S6MRZR",
    department_name: "Technical",
    department_description: "Where magic happens!!!",
    department_timestamp: 1680695891000,
    department_location: "3rd Floor",
    site_id: "01GWVD0DP0JBNYEM58GZM1V72P",
  },
  machine: {
    machine_id: "01GWVZB4MPRKSVEFKM39XHMCE6",
    machine_name: "ServerRack",
    machine_description: "Keep us Running!",
    machine_timestamp: 1680695892000,
    production_line_id: "01GWVD0DP0FGG8T415A8NHRKWW",
  },
  productionLine: {
    production_line_id: "01GWVD0DP0FGG8T415A8NHRKWW",
    production_line_name: "BackEnd",
    production_line_description: "We make coffee too!",
    production_line_timestamp: 1680695891000,
    production_line_location: "3rd Floor",
    department_id: "01GWVD0DP0473K1HS364S6MRZR",
  },
  asset: {
    asset_id: "01GWVZB4MP8X7R80MBJHXMGYHG",
    asset_name: "Kube0",
    asset_description: "Kubernetes",
    asset_machine_class: "Class_II",
    asset_type: "Other",
    asset_timestamp: 1680695892000,
    asset_location: null,
    asset_location_lat: null,
    asset_location_long: null,
    asset_qr_url: null,
    asset_model: null,
    asset_photo: null,
    asset_critically: null,
    machine_id: "01GWVZB4MPRKSVEFKM39XHMCE6",
  },
  assetAcceleration: {
    rms_x: {
      values: [
        {
          date: "2023-04-19T05:00:45.448401+00:00",
          value: 0.2959870907592973,
        },
        {
          date: "2023-04-19T05:00:50.451804+00:00",
          value: 0.06897191098100042,
        },
        {
          date: "2023-04-19T05:00:55.453479+00:00",
          value: 0.5496698446666034,
        },
        {
          date: "2023-04-19T05:01:00.456693+00:00",
          value: 0.15435544364402232,
        },
        {
          date: "2023-04-19T05:01:05.456972+00:00",
          value: 0.6036951725047001,
        },
        {
          date: "2023-04-19T05:01:10.461653+00:00",
          value: 0.8192095678867604,
        },
        {
          date: "2023-04-19T05:01:15.464157+00:00",
          value: 0.10733940625158522,
        },
        {
          date: "2023-04-19T05:01:20.468414+00:00",
          value: 0.16159289981921143,
        },
        {
          date: "2023-04-19T05:01:25.471556+00:00",
          value: 0.46164118935458875,
        },
        {
          date: "2023-04-19T05:01:30.474727+00:00",
          value: 0.020737692369676042,
        },
        {
          date: "2023-04-19T05:01:35.479637+00:00",
          value: 0.15335513285397706,
        },
        {
          date: "2023-04-19T05:01:40.481250+00:00",
          value: 0.29685936045804195,
        },
        {
          date: "2023-04-19T05:01:45.483696+00:00",
          value: 0.8589708345144319,
        },
        {
          date: "2023-04-19T05:01:50.486957+00:00",
          value: 0.8618072562650666,
        },
        {
          date: "2023-04-19T05:01:55.491911+00:00",
          value: 0.7884942124438322,
        },
        {
          date: "2023-04-19T05:02:00.495617+00:00",
          value: 0.35523482917606497,
        },
        {
          date: "2023-04-19T05:02:05.496871+00:00",
          value: 0.2126481501730586,
        },
        {
          date: "2023-04-19T05:02:10.501731+00:00",
          value: 0.23178053357574147,
        },
        {
          date: "2023-04-19T05:02:15.506651+00:00",
          value: 0.39719769408875927,
        },
        {
          date: "2023-04-19T05:02:20.507776+00:00",
          value: 0.11573455918065478,
        },
        {
          date: "2023-04-19T05:02:25.511657+00:00",
          value: 0.6189988537653629,
        },
        {
          date: "2023-04-19T05:02:30.511885+00:00",
          value: 0.9681141837229256,
        },
        {
          date: "2023-04-19T05:02:35.513630+00:00",
          value: 0.6796972289845129,
        },
        {
          date: "2023-04-19T05:02:40.516775+00:00",
          value: 0.1604347104157563,
        },
        {
          date: "2023-04-19T05:02:45.520146+00:00",
          value: 0.33963085372630863,
        },
      ],
    },
    rms_y: {
      values: [
        {
          date: "2023-04-19T05:04:40.605699+00:00",
          value: 0.6332545266856677,
        },
        {
          date: "2023-04-19T05:04:45.609651+00:00",
          value: 0.6784382229735939,
        },
        {
          date: "2023-04-19T05:04:50.612439+00:00",
          value: 0.2880770464543537,
        },
        {
          date: "2023-04-19T05:04:55.616727+00:00",
          value: 0.5902344374724151,
        },
        {
          date: "2023-04-19T05:05:00.618434+00:00",
          value: 0.45397016459819484,
        },
        {
          date: "2023-04-19T05:05:05.621390+00:00",
          value: 0.9147863824954429,
        },
        {
          date: "2023-04-19T05:05:10.623225+00:00",
          value: 0.30384015638865136,
        },
        {
          date: "2023-04-19T05:05:15.628172+00:00",
          value: 0.9521967029204639,
        },
        {
          date: "2023-04-19T05:05:20.630607+00:00",
          value: 0.392842370851488,
        },
        {
          date: "2023-04-19T05:05:25.723434+00:00",
          value: 0.23932527397624237,
        },
        {
          date: "2023-04-19T05:05:30.724316+00:00",
          value: 0.032497115634006035,
        },
        {
          date: "2023-04-19T05:05:35.724907+00:00",
          value: 0.881807488464458,
        },
        {
          date: "2023-04-19T05:05:40.725648+00:00",
          value: 0.6199944743429606,
        },
        {
          date: "2023-04-19T05:05:45.727369+00:00",
          value: 0.34407736899336183,
        },
        {
          date: "2023-04-19T05:05:50.727374+00:00",
          value: 0.8877628365228428,
        },
        {
          date: "2023-04-19T05:05:55.730375+00:00",
          value: 0.4889539060886492,
        },
        {
          date: "2023-04-19T05:06:00.730918+00:00",
          value: 0.20633966593980735,
        },
      ],
    },
    rms_z: {
      values: [
        {
          date: "2023-04-19T05:02:50.524243+00:00",
          value: 0.44083032151063395,
        },
        {
          date: "2023-04-19T05:02:55.529441+00:00",
          value: 0.5172683229539727,
        },
        {
          date: "2023-04-19T05:03:00.532426+00:00",
          value: 0.7716598221777382,
        },
        {
          date: "2023-04-19T05:03:05.536484+00:00",
          value: 0.6361311356277214,
        },
        {
          date: "2023-04-19T05:03:10.541623+00:00",
          value: 0.3859848619068156,
        },
        {
          date: "2023-04-19T05:03:15.542344+00:00",
          value: 0.11485729592958427,
        },
        {
          date: "2023-04-19T05:03:20.543200+00:00",
          value: 0.15013829999388695,
        },
        {
          date: "2023-04-19T05:03:25.547851+00:00",
          value: 0.6657215574740943,
        },
        {
          date: "2023-04-19T05:03:30.551738+00:00",
          value: 0.11176681523251641,
        },
        {
          date: "2023-04-19T05:03:35.556226+00:00",
          value: 0.8505360408653606,
        },
        {
          date: "2023-04-19T05:03:40.561042+00:00",
          value: 0.8242331197087017,
        },
        {
          date: "2023-04-19T05:03:45.565393+00:00",
          value: 0.3619101773034612,
        },
        {
          date: "2023-04-19T05:03:50.567231+00:00",
          value: 0.5712832968135201,
        },
        {
          date: "2023-04-19T05:03:55.571879+00:00",
          value: 0.8901745416504095,
        },
        {
          date: "2023-04-19T05:04:00.573791+00:00",
          value: 0.8757191682051723,
        },
        {
          date: "2023-04-19T05:04:05.576897+00:00",
          value: 0.9606267769851289,
        },
        {
          date: "2023-04-19T05:04:10.579822+00:00",
          value: 0.9332117559918816,
        },
        {
          date: "2023-04-19T05:04:15.583013+00:00",
          value: 0.8429890015771433,
        },
        {
          date: "2023-04-19T05:04:20.586941+00:00",
          value: 0.6926239899036503,
        },
        {
          date: "2023-04-19T05:04:25.591758+00:00",
          value: 0.7172815768385062,
        },
        {
          date: "2023-04-19T05:04:30.595887+00:00",
          value: 0.5149526255068712,
        },
        {
          date: "2023-04-19T05:04:35.601093+00:00",
          value: 0.7656161701469539,
        },
      ],
    },
  },
}
