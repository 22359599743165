import React from "react"
import Copyright from "core/components/Copyright"
import { RootState } from "store/rootReducer"
import { useSelector } from "react-redux"

import { Box, Divider, Paper } from "@mui/material"

import ErrorMessage from "./subcomponents/ErrorMessage"
import Logo from "./subcomponents/Logo"
import { styles } from "./styles"
import WaitLoadingScreen from "./subcomponents/WaitLoadingScreen"

import LoginForm from "./subcomponents/LoginForm"

const Login = () => {
  const selectStateGlobal = (state: RootState) => state.global
  const { isLogInLoading } = useSelector(selectStateGlobal)

  return (
    <Box sx={styles.root}>
      {isLogInLoading ? (
        <WaitLoadingScreen />
      ) : (
        <Paper sx={styles.paperContainer} elevation={3}>
          <ErrorMessage />
          <Logo />
          <LoginForm />
          <Divider />
          <Box sx={styles.copyright}>
            <Copyright />
          </Box>
        </Paper>
      )}
    </Box>
  )
}

export default Login
