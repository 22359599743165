import { Box, SxProps } from "@mui/system"
import AppTypography from "core/components/AppTypography"
import React from "react"
// eslint-disable-next-line max-len
import AxisSelector from "screens/Facilities/subcomponents/MachineVibrationGraph/subcomponents/AxisSelector"

interface Props {
  title: string
  sx?: SxProps
}

const SimpleHeader = ({ title, sx = {} }: Props) => (
  <Box sx={sx}>
    <AppTypography
      typographyProps={{
        align: "right",
        fontSize: "0.75rem",
        color: "text.secondary",
      }}
    >
      {title}
    </AppTypography>
    <AxisSelector />
  </Box>
)

export default SimpleHeader
