import React from "react"

import LineChart from "core/components/ChartJS"

import { useTheme } from "@mui/material"
import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import { GraphicEq } from "@mui/icons-material"
import AppTypography from "core/components/AppTypography"
import { useTranslation } from "react-i18next"
import { styles } from "./styles"
import AxisSelector from "screens/Facilities/subcomponents/MachineVibrationGraph/subcomponents/AxisSelector"

interface ChartJsAccelerationProps {
  accelData: Array<{ date: string; value: number }>
  axisLabels?: { yLabel: string; xLabel: string }
  zoomEnabled?: boolean
  upperBoundOffset?: number | null | undefined
  onRetry?: () => void
  isLoading?: boolean
  hasError?: boolean
}

const ChartJsAcceleration = ({
  zoomEnabled = false,
  axisLabels = { yLabel: "", xLabel: "" },
  upperBoundOffset = null,
  accelData,
  onRetry,
  isLoading = false,
  hasError = false,
}: ChartJsAccelerationProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const graphLineColor = theme.palette.primary.main

  const graphConfig = {
    scales: {
      y: {
        title: {
          color: theme.palette.text.secondary,
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
      x: {
        ticks: {
          color: theme.palette.text.secondary,
        },
        title: {
          display: false,
        },
      },
    },
  }
  const dataCheck = !accelData || accelData.length === 0
  return (
    <AppGraphWrapper
      subheader={<AxisSelector />}
      isLoading={isLoading}
      hasError={hasError}
      onRetry={onRetry}
      noDataCheck={dataCheck}
      graphContainerStyle={styles.graphContainer}
      baseCardStyle={styles.baseCard}
      restHeaderProps={{
        headerLabel: (
          <AppTypography variant="subtitle3" capitalizeFirstLetter>{`${t(
            "vibration.text",
          )}`}</AppTypography>
        ),
        preTitleIcon: <GraphicEq sx={styles.preTitle} fontSize="small" />,
      }}
    >
      <LineChart
        config={graphConfig}
        lineColor={graphLineColor}
        upperBoundOffset={upperBoundOffset}
        axisLabels={axisLabels}
        graphData={accelData}
        zoomEnabled={zoomEnabled}
      />
    </AppGraphWrapper>
  )
}

export default ChartJsAcceleration
