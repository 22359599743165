import UISlice from "./UI/UISlice"
import { apiSlice } from "./queryApi"
import assetsSlice from "./Assets/assetsSlice"
import { combineReducers } from "redux"

import departmentSlice from "./Department/departmentSlice"
import filtersSlice from "./Filters/filtersSlice"
import machinesSlice from "./Machines/machinesSlice"
import organizationSlice from "./Organisation/organizationSlice"
import productionLineSlice from "./Production_lines/productionLineSlice"

import rootSlice from "./rootSlice"
import sitesSlice from "./Sites/sitesSlice"
import userInfoSlice from "./UserInfo/userInfoSlice"

export const appReducer = combineReducers({
  global: rootSlice,
  filters: filtersSlice,
  assets: assetsSlice,
  machines: machinesSlice,
  organizations: organizationSlice,
  production_lines: productionLineSlice,
  sites: sitesSlice,
  departments: departmentSlice,
  userInfo: userInfoSlice,
  ui: UISlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === "RESET_REDUX") state = undefined
  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
