import React from "react"

import AppTypography from "core/components/AppTypography"
import { useTranslation } from "react-i18next"

import { Box } from "@mui/material"
import { useTheme } from "@mui/system"

interface IDaysLabelProps {
  state: "critical" | "warning" | "normal" | "idle"
  days: number
}

export const DaysLabel = ({ state, days }: IDaysLabelProps) => {
  const { t } = useTranslation()
  const daysLabel = `${days === 1 ? t("day.text") : t("day.text.plural")}`
  const theme = useTheme()
  const textColors = {
    critical: theme.palette.error.main,
    warning: theme.palette.warning.main,
    normal: theme.palette.success.main,
    idle: theme.palette.training.main,
  }

  return (
    <Box
      sx={{
        ml: 1,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <AppTypography
        sx={{
          fontWeight: "bold",
          mr: 0.5,
          color: textColors[state] as string,
        }}
      >
        {days}
      </AppTypography>
      <AppTypography
        sx={{
          mr: 3,
        }}
      >
        {daysLabel}
      </AppTypography>
    </Box>
  )
}
