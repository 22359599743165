import { Duration, milliseconds, millisecondsToMinutes } from "date-fns"

const minutesFromDuration = (duration: Duration) =>
  millisecondsToMinutes(milliseconds(duration))

export const menuOptions = [
  {
    minutes: minutesFromDuration({ days: 1 }),
    labelCode: "last.day",
  },
  {
    minutes: minutesFromDuration({ weeks: 1 }),
    labelCode: "last.week",
  },
  {
    minutes: minutesFromDuration({ months: 1 }),
    labelCode: "last.month",
  },
  {
    minutes: minutesFromDuration({ months: 3 }),
    labelCode: "last.three.months",
  },
  {
    minutes: minutesFromDuration({ months: 12 }),
    labelCode: "last.twelve.months",
  },
]

export const levelUpMenuOptions = [
  {
    minutes: minutesFromDuration({ hours: 12 }),
    labelCode: "last.twelve.hours",
  },
  {
    minutes: minutesFromDuration({ days: 1 }),
    labelCode: "last.day",
  },
  {
    minutes: minutesFromDuration({ days: 3 }),
    labelCode: "last.three.days",
  },
  {
    minutes: minutesFromDuration({ weeks: 1 }),
    labelCode: "last.week",
  },
  {
    minutes: minutesFromDuration({ weeks: 2 }),
    labelCode: "last.two.weeks",
  },
  {
    minutes: minutesFromDuration({ months: 1 }),
    labelCode: "last.month",
  },
  {
    minutes: minutesFromDuration({ months: 2 }),
    labelCode: "last.two.months",
  },
  {
    minutes: minutesFromDuration({ months: 3 }),
    labelCode: "last.three.months",
  },
  {
    minutes: minutesFromDuration({ months: 6 }),
    labelCode: "last.six.months",
  },
  {
    minutes: minutesFromDuration({ months: 12 }),
    labelCode: "last.twelve.months",
  },
  {
    minutes: minutesFromDuration({ months: 15 }),
    labelCode: "last.fifteen.months",
  },
  {
    minutes: minutesFromDuration({ months: 18 }),
    labelCode: "last.eighteen.months",
  },
  {
    minutes: minutesFromDuration({ months: 24 }),
    labelCode: "last.twentyfour.months",
  },
]
