export const styles = {
  buttonDurationContainer: {
    color: "text.secondary",
    fontSize: "12px",
    px: 2,
    textTransform: "none",
  },
  buttonGroup: {
    "&.MuiButtonGroup-root .MuiButtonGroup-grouped": {
      py: 0,
      borderColor: "divider",
    },
  },

  durationsContainer: {
    alignItems: "center",
    display: "flex",
  },
}
