import React from "react"

import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { setIsSettingsOpen } from "store/rootSlice"

import { Box, Drawer } from "@mui/material"

import { styles } from "./styles"
import VersionInfo from "../VersionInfo"

interface IAppDrawerSettings {
  children: React.ReactNode
}

const AppDrawerSettings = (props: IAppDrawerSettings) => {
  const selectState = (state: RootState) => state.global
  const { isSettingsOpen } = useSelector(selectState)
  const dispatch = useDispatch()

  const handleDrawerClose =
    () => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        "type" in event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }

      dispatch(setIsSettingsOpen(false))
    }

  return (
    <Drawer
      anchor="right"
      open={isSettingsOpen}
      variant="temporary"
      onClose={handleDrawerClose()}
    >
      <Box
        sx={styles.drawerContainer}
        role="presentation"
        onClick={handleDrawerClose()}
        onKeyDown={handleDrawerClose()}
      >
        {props.children}
      </Box>
      <VersionInfo />
    </Drawer>
  )
}

export default AppDrawerSettings
