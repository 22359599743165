import React from "react"

import AppTypography from "core/components/AppTypography"
import {
  capitalizeFistLetter,
  getDurationInState,
  getDurationInStateLabel,
} from "core/helpers"
import StatusIcon from "core/components/StatusIcon"
import { useTranslation } from "react-i18next"

import { Box, Skeleton, Tooltip } from "@mui/material"

import { Inventory2Outlined, TimelapseOutlined } from "@mui/icons-material"

import { styles } from "./styles"

interface IMachineSummaryRowProps {
  status: string
  timeInStatus: number
  numOfMachineAssets: number
  isLoading: boolean
}

const MachineSummaryRow = ({
  status = "",
  timeInStatus = 0,
  numOfMachineAssets = 0,
  isLoading = false,
}: IMachineSummaryRowProps) => {
  const { t } = useTranslation()

  const durationInState = getDurationInState(timeInStatus)
  const durationInStateLabel = getDurationInStateLabel(timeInStatus)

  return (
    <Box sx={styles().root}>
      <Box sx={styles().container}>
        {isLoading ? (
          <Skeleton width={100} />
        ) : (
          <Tooltip title={capitalizeFistLetter(t("machine.state.text"))} arrow>
            <Box className="item" sx={styles().item}>
              <StatusIcon state={status} svgIconProps={{ mr: 10 / 8 }} />
              <AppTypography variant="h5" capitalizeFirstLetter>
                {!!status && t(`${status.toLowerCase()}.text`)}
              </AppTypography>
            </Box>
          </Tooltip>
        )}
        <Box className="divider" sx={styles().divider} />
        {isLoading ? (
          <Skeleton width={100} />
        ) : (
          <Tooltip
            title={capitalizeFistLetter(t("time.in.current.state.text"))}
            arrow
          >
            <Box className="item" sx={styles().item}>
              <TimelapseOutlined sx={styles().timelapseoutlined} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <AppTypography variant="h5" sx={{ mr: 0.5 }}>
                  {durationInState}
                </AppTypography>
                <AppTypography variant="h5" sx={{ fontSize: "0.75rem" }}>
                  {t(durationInStateLabel)}
                </AppTypography>
              </Box>
            </Box>
          </Tooltip>
        )}
        <Box className="divider" sx={styles().divider} />
        {isLoading ? (
          <Skeleton width={100} />
        ) : (
          <Tooltip title={capitalizeFistLetter(t("totalAssets.text"))} arrow>
            <Box className="item" sx={styles().item}>
              <Inventory2Outlined sx={styles().assetsIcon} />
              <AppTypography variant="h5">{numOfMachineAssets}</AppTypography>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

export default MachineSummaryRow
