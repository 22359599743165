// import { store } from "store/store"
import { TBackendEvironments } from "types/misc";

const getHttpType = () =>
  window.location.href.includes("unsafe") ? "http" : "https"

export const ENVS = {
  LOCAL: `${getHttpType()}://localhost:3001/`,
  DEV: `${getHttpType()}://backend2.corebeatdev.eu/`,
  PROD: `${getHttpType()}://backend2.corebeatdev.eu/`,
}

export const FIREBASE =  {
  API_KEY : "AIzaSyBu1lcFnL-xIv3ouGDYb3dZT3fdXod2oWg",
  AUTH_DOMAIN : "core-beat-f0d2d.firebaseapp.com",
  PROJECT_ID : "core-beat-f0d2d",
  STORAGE_BUCKET : "core-beat-f0d2d.appspot.com",
  MESSAGING_SENDER_ID : "192496728305",
  APP_ID : "1:192496728305:web:354d16f56934ce6304d150",
  MEASUREMENT_ID : "G-WZB5MSMSR3",
}

export const CONFIG = {
  LOCAL: "http://localhost:3001",
}

export const AWS_COGNITO_CONFIG = {
  Auth: {
    mandatorySignId: true,
    region: "eu-central-1",
  },
}

const environment: TBackendEvironments = "PROD"
// store?.getState()?.global?.backendEnvironment

export default class Config {
  static baseUrl = ENVS[environment]
}
