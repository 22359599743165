import React from "react"

import { RootState } from "store/rootReducer"
import { setHealthTimespan } from "store/Machines/machinesSlice"
import { useSelector } from "react-redux"

import { TopLedgendIcons } from "./TopLedgendIcons"
import DateRangePicker from "core/components/DateRangePicker"

interface Props {
  daysPerType: {
    normal: number
    warning: number
    critical: number
    idle: number
  }
  hidePicker?: boolean
}

const GraphBaseHeader = ({ daysPerType, hidePicker = false }: Props) => {
  const { selectedHealthTimespan } = useSelector(
    (state: RootState) => state.machines,
  )

  return (
    <>
      {!hidePicker && (
        <DateRangePicker
          selectedActionToDispatch={setHealthTimespan}
          timespan={selectedHealthTimespan}
        />
      )}
      <TopLedgendIcons daysPerType={daysPerType} />
    </>
  )
}

export default GraphBaseHeader
