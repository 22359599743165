import { Box } from "@mui/system"
import AppTypography from "core/components/AppTypography"
import React from "react"
import { useDispatch } from "react-redux"
import { setAssetAxisSelected } from "store/Assets/assetsSlice"
import { styles } from "./styles"

export interface SelectorProps {
  label: string
  selected: boolean
  value: string
  sx?: any
}

const Selector = ({ label, selected, value, sx }: SelectorProps) => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(setAssetAxisSelected(value))
  }
  return (
    <Box onClick={onClick}>
      <AppTypography
        capitalizeFirstLetter
        sx={{
          ...(selected ? styles.axisLabelSelected : styles.axisLabel),
          ...sx,
        }}
      >
        {label}
      </AppTypography>
    </Box>
  )
}

export default Selector
