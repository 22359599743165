import React from "react"

import { IStatusIconProps } from "types/cards"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import InfoIcon from "@mui/icons-material/Info"
import ModelTraining from "@mui/icons-material/ModelTraining"
import WarningIcon from "@mui/icons-material/Warning"

import hideSourceIcon from "../../../assets/icons/hide_source_fill.svg"
import styles from "./styles"

const StatusIcon = ({ state, svgIconProps = {} }: IStatusIconProps) => {
  const iconObj = {
    normal: <CheckCircleIcon sx={{ ...styles.normal, ...svgIconProps }} />,
    running: <CheckCircleIcon sx={{ ...styles.normal, ...svgIconProps }} />,
    warning: (
      <WarningIcon
        sx={{ ...styles.warning, ...svgIconProps }}
        color="primary"
      />
    ),
    info: <InfoIcon sx={{ ...styles.info, ...svgIconProps }} />,
    critical: <ErrorIcon sx={{ ...styles.critical, ...svgIconProps }} />,
    stop: <ErrorIcon sx={{ ...styles.critical, ...svgIconProps }} />,
    training: <ModelTraining sx={{ ...styles.training, ...svgIconProps }} />,
    idle: (
      <img src={hideSourceIcon} style={{ ...styles.idle, ...svgIconProps }} />
    ),
  } as any
  const prop = (state + "").toLowerCase()
  const hasState = Object.keys(iconObj).includes(prop)
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return hasState ? iconObj[prop] : <></>
}
export default StatusIcon
