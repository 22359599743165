import {
  addMinutes,
  isSameDay,
  isValid,
  parseISO,
  subMinutes,
  format as dateFormat,
} from "date-fns"

export const convertToUTC = (date: number, tmzOffset: number) => {
  if (tmzOffset === 0) {
    return date
  }
  return tmzOffset > 0
    ? addMinutes(date, tmzOffset)
    : subMinutes(date, tmzOffset)
}

export const reformAssetData = (assetData: any) => {
  if (!assetData) return {}

  return assetData.map(({ date, value }: any) => {
    const parsedDate = parseISO(date).valueOf()
    return isValid(parsedDate)
      ? {
          x: parsedDate,
          y: Number(value),
        }
      : null
  })
}

export const transformWeekLabels = (transformedAssetData: any[] | null) => {
  if (!transformedAssetData || transformedAssetData.length === 0) {
    return []
  }

  const sumArr: any[] = []
  let sum = 0
  const localCopy = transformedAssetData.map(({ x }: any, index: number) => {
    if (index === 0) {
      sum += 1
      return isSameDay(x, transformedAssetData[index + 1]?.x)
        ? ""
        : dateFormat(x, "dd/MM")
    }
    if (index < transformedAssetData.length - 1) {
      const same = isSameDay(x, transformedAssetData[index + 1].x)
      if (index === 0) return dateFormat(x, "dd/MM")
      if (same) {
        sum += 1
        return ""
      }

      const dateRange = Math.floor(sum / 2)
      const midTimeValueIndex = index - dateRange

      if (dateRange === 0) {
        sumArr.push({ index: Math.floor(index / 2), value: x })
      } else {
        sumArr.push({ index: midTimeValueIndex, value: x })
      }

      sum = 0

      return dateFormat(x, "dd/MM")
    }
  })

  sumArr.forEach((d: any) => {
    localCopy[d.index] = dateFormat(d.value, "hh:mm")
  })

  return localCopy
}
