export const styles = {
  boxContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerAndIcons: {
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "17px",
    color: "text.primary",
  },
  icons: {
    color: "text.secondary",
  },
}
