export const styles = {
  small: {
    display: "flex",
    width: 272,
    height: 150,
    flexDirection: "column",
    padding: 3,
  },
  large: {
    width: "auto",
    maxWidth: 580,
    minWidth: 100,
    padding: 3,
  },
  empty: {},
}
