import React from "react"

import { Box, Typography } from "@mui/material"
import { styles } from "./styles"

interface machineRow {
  columns: any[]
  heightFactor: number
  title: {
    el: string
    en: string
  }
}
type MachineNotFoundForDynamicUIProps = {
  selectedMachine: string
  availableMachines: {
    id: string
    rows: machineRow[]
  }[]
}

export const MachineNotFoundForDynamicUI = ({
  selectedMachine,
  availableMachines,
}: MachineNotFoundForDynamicUIProps) => (
  //@ts-ignore

  <Box sx={styles.container}>
    <Typography
      sx={styles.typography}
      variant="h3"
    >{`Selected machine : ${selectedMachine}`}</Typography>
    <Typography sx={styles.typography} variant="h6">
      Machine not found for dynamic UI. Update the config file to support this
      selection.
    </Typography>
    <Typography sx={styles.typography} variant="body1">
      Machines declared on config file and on this screen :
    </Typography>
    {availableMachines.map((machine: any) => (
      <Typography key={machine.id} sx={styles.typography} variant="body1">
        {machine.id}
      </Typography>
    ))}
  </Box>
)
