export const styles = {
  boxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    m: 3,
    ".MuiBox-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  baseCard: {
    height: "11.5rem",
    p: 1,
  },
  alert: {
    mx: 1,
  },
  infoBox: {
    m: "1 auto",
    width: "fit-content",
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  lastUpdate: {
    mr: 1,
    color: "text.secondary",
    fontSize: "0.75rem",
  },
}
