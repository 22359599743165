import React from "react"

import AppCardLoader from "core/components/AppCardLoader"
import BaseCard from "core/components/AppCard/BaseCard"
import { ChartOptions } from "chart.js"
import LineChart from "core/components/ChartJS"
import NoDataFound from "core/components/NoDataFound"
import { RootState } from "store/rootReducer"
import { ROW_HEIGHT } from "core/constants/ui"
import { useAppPostGetTimeseries } from "core/hooks/apiHooks"

import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { useTheme } from "@mui/material"

import { fontSpecs } from "../../helpers"
import GraphBaseHeader from "./subcomponents/GraphBaseHeader"
import { styles } from "./styles"
import { reformAssetData } from "core/dataActions"
import {
  calculateGraphUpperOffsetValue,
  getMaxTicksForGraphs,
  getTimeFormat,
  getTooltip,
} from "core/helpers"
import { minutesToMilliseconds } from "date-fns"
import { IDynamicGraphProps } from "types/dynamicGraph"
import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import { DEFAULT_SPAN_GAPS, getSpanGraph, getSymbolValues } from "../helpers"

interface ISimpleTimeseriesGraph extends IDynamicGraphProps {
  requestParams: any
  graphsInCurrentRow: number
}

const SimpleTimeseriesGraph = ({
  requestParams,
  titleCode: title,
  iconCode,
  measureUnitCode: measureUnit,
  assetId: selectedAssetId,
  heightFactor,
  thresholds,
  spanGaps,
  tooltipText,
  graphsInCurrentRow,
  refresh_interval: interval = 5,
}: ISimpleTimeseriesGraph) => {
  const { t } = useTranslation()

  const { globalSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )

  const theme = useTheme()

  const HEIGHT_OFFSET = 50

  const SPAN_GAPS =
    getSpanGraph({
      spanGaps,
      startTime: globalSelectedRange.fromEpoch,
      endTime: globalSelectedRange.toEpoch,
    }) ?? DEFAULT_SPAN_GAPS

  const GRAPH_MIN_DATE =
    new Date(globalSelectedRange.fromEpoch * 1000).toISOString() ?? null
  const GRAPH_MAX_DATE =
    new Date(globalSelectedRange.toEpoch * 1000).toISOString() ?? null

  const requestBody = {
    ...requestParams[0].requestBody,
    ...{
      end_time: new Date(globalSelectedRange.toEpoch * 1000).toISOString(),
      start_time: new Date(globalSelectedRange.fromEpoch * 1000).toISOString(),
      symbols_values: getSymbolValues({
        requestParams,
        startTime: globalSelectedRange.fromEpoch,
        endTime: globalSelectedRange.toEpoch,
      }),
    },
  }

  const {
    data = [],
    isFetching,
    isError,
    refetch,
  } = useAppPostGetTimeseries(requestBody, {
    skip: !selectedAssetId,
    pollingInterval: minutesToMilliseconds(interval),
  })

  const dataForTooltip = reformAssetData(data).map((item: any) => item.x) as any

  const graphConfig = {
    plugins: {
      tooltip: getTooltip(dataForTooltip),
    },
    scales: {
      x: {
        min: GRAPH_MIN_DATE,
        max: GRAPH_MAX_DATE,
        offset: true,
        title: {
          display: false,
        },
        type: "time",

        ticks: {
          font: {
            size: 10,
          },
          callback: (value: any) => getTimeFormat(value),
          color: theme.palette.text.secondary,
          maxTicksLimit: getMaxTicksForGraphs(graphsInCurrentRow),
        },
      },
      y: {
        ticks: {
          font: fontSpecs,
          maxTicksLimit: 6,
        },
        title: {
          display: !!measureUnit,
          font: fontSpecs,
          text: measureUnit,
        },
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    spanGaps: SPAN_GAPS,
  } as ChartOptions<"line">

  const handleOnRetry = () => refetch()

  const height = ROW_HEIGHT * heightFactor

  const max =
    data.length !== 0 ? Math.max(...data.map((item: any) => item.value)) : 0

  const hasThresholds = !!thresholds

  const upperBoundValue = hasThresholds
    ? calculateGraphUpperOffsetValue(max, thresholds)
    : 0

  if (data.length === 0) {
    return (
      <BaseCard
        sx={styles.baseCard}
        customHeader={
          <GraphBaseHeader
            title={t(title)}
            iconCode={iconCode}
            tooltipText={tooltipText}
          />
        }
      >
        <AppCardLoader
          isLoading={isFetching}
          hasError={isError}
          onRetry={handleOnRetry}
        >
          <NoDataFound
            style={{
              height: height - 30,
            }}
          />
        </AppCardLoader>
      </BaseCard>
    )
  }

  return (
    <AppGraphWrapper
      isLoading={isFetching}
      hasError={isError}
      baseCardStyle={{ ...styles.baseCard, height }}
      graphContainerStyle={{
        ...styles.graphContainer,
        height: height - HEIGHT_OFFSET,
      }}
      restHeaderProps={{
        customHeader: (
          <GraphBaseHeader
            title={title}
            iconCode={iconCode}
            tooltipText={tooltipText}
          />
        ),
      }}
    >
      <LineChart
        datasetLabel={title}
        // unit is not used
        // unit={measureUnit}
        zoomEnabled={false}
        config={graphConfig}
        upperBoundOffset={upperBoundValue}
        lineColor={theme.palette.primary.main}
        graphData={reformAssetData(data)}
        thresholds={thresholds}
      />
    </AppGraphWrapper>
  )
}

export default SimpleTimeseriesGraph
