import { GenericApiCallType } from "types/misc"
import BAPI from ".."

export interface IDepartmentInfo {
  department_id: string
  department_name: string
  department_description: string
  department_timestamp: number
  department_location: string
  site_id: string
}

const DepartmentsAPI = {
  async getDepartmentInfo(deptID: string): GenericApiCallType<IDepartmentInfo> {
    return BAPI.get(`department/${deptID}`, {
      timeout: 3000,
      timeoutErrorMessage: "No response from server",
    })
  },
}

export default DepartmentsAPI
