export const styles = {
  afterIcon: {
    color: "text.secondary",
    fontSize: "0.875rem",
    ml: 1,
  },
  preIcon: {
    color: "text.secondary",
    mr: 1,
    fontSize: "1.25rem",
  },
}
