export const styles = {
  tablist: {
    ".MuiTabs-flexContainer": {
      justifyContent: "flex-start",
      alignItems: "center",
      textAlign: "start",
      display: "flex",
      m: 0,
    },

    ".MuiButtonBase-root": {
      color: "text.secondary",
      height: "20px",
      px: 2,
    },

    button: {
      fontSize: "0.875rem",
    },
    ".MuiTab-root": {
      textTransform: "capitalize",
    },
    "&.Mui-disabled": {
      display: "none",
    },

    ".MuiTabs-indicator": {
      display: "none",
    },
  },

  departmentTabContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mx: 1,
  },
}
