import React, { useRef } from "react"

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  Plugin,
  Tooltip,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { useTheme } from "@mui/material"

import { transformDataForGraph } from "./helpers"
import { alpha } from "@mui/system"

ChartJS.register(CategoryScale, LinearScale, BarElement, BarController, Tooltip)

const BarChart = ({ data }: any) => {
  const chartRef = useRef<ChartJS<"bar">>(null)

  const theme = useTheme()

  /**
   * For reference
   * https://www.chartjs.org/docs/latest/charts/bar.html#barpercentage-vs-categorypercentage
   */
  const CATEGORY_PERCENTAGE = 0.79
  const BAR_PERCENTAGE = 0.9

  const backgroundBar: Plugin = {
    id: "backgroundBar",
    beforeDatasetDraw(chart) {
      const {
        data: { datasets, labels },
        ctx,
        chartArea: { top, width, height },
        scales: { x },
      } = chart
      ctx.save()
      ctx.fillStyle =
          //@ts-ignore
        theme.palette.offline.light10 ?? alpha(theme.palette.offline.main, 0.05)

      const [datasetValues] = datasets[0].data

      if (datasetValues !== undefined && labels !== undefined) {
        const segment = width / labels.length
        const barWidth = segment * CATEGORY_PERCENTAGE * BAR_PERCENTAGE

        for (let i = 0; i < labels.length; i++) {
          ctx.fillRect(
            x.getPixelForValue(i) - barWidth / 2,
            top,
            barWidth,
            height,
          )
        }
      }
      ctx.restore()
    },
  }

  const options: ChartOptions<"bar"> = {
    animation: false,
    events: [],

    plugins: {
      tooltip: {
        // callbacks: {
        //   label: function (tooltipItem) {
        //     console.log(tooltipItem)
        //     // return `${dataset.data.length}`
        //     return ""
        //   },
        // },
      },
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },

    scales: {
      y: {
        grid: {
          display: false,
        },
        display: false,
        stacked: true,
      },
      x: {
        ticks: {
          font: {
            size: 10,
          },

          color: theme.palette.text.secondary,
        },
        grid: {
          drawTicks: false,
          display: false,
        },
        stacked: true,
      },
    },

    responsive: true,
    maintainAspectRatio: false,
  }

  return (
    <Bar
      ref={chartRef}
      options={options}
      plugins={[backgroundBar]}
      data={transformDataForGraph(data, theme)}
    />
  )
}

export default BarChart
