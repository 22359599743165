import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { yupResolver } from "@hookform/resolvers/yup"
import { schema, FormData } from "screens/Login/validation"
import { Box, Button, TextField, Theme } from "@mui/material"
import ShowPasswordIcon from "../ShowPasswordIcon"
import { styles } from "./styles"
import { useNavigate } from "react-router-dom"
import { batch, useDispatch } from "react-redux"
import {
  setOrganizationSelected,
  useLazyGetOrganizationQuery,
} from "store/Organisation/organizationSlice"
import { setSelectedSiteId } from "store/Sites/sitesSlice"
import { signIn } from "core/auth/auth"
import { navigateToLandingPage } from "core/helpers"
import { setLoginError } from "store/rootSlice"

const LoginForm = () => {
  const { t } = useTranslation()
  const {
    control,
    trigger: triggerValidation,
    handleSubmit,
    formState: { isValid },
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  })

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const [trigger] = useLazyGetOrganizationQuery()

  const setOrganizationData = async (orgId: string) => {
    const { data } = await trigger(
      {
        uuid: orgId,
      },
      true,
    )
    batch(() => {
      dispatch(setOrganizationSelected(data))

      if (data?.site_collection?.length === 1) {
        dispatch(setSelectedSiteId(data.site_collection[0].site_id))
      }
    })
  }

  const handleOnLogin = async (data: { email: string; password: string }) => {
    if (!isValid) return
    try {
      const user = await signIn({
        username: data.email,
        password: data.password,
      })
      const organizationId = user.attributes["custom:client-organisation"]
      await setOrganizationData(organizationId)
      navigateToLandingPage(navigate)
    } catch (error) {
      if (error instanceof Error) {
        dispatch(setLoginError(error.message))
      }
    }
  }

  const [showPassword, setShowPassword] = useState(false)
  const handleOnClickRevealPass = () => setShowPassword(!showPassword)
  return (
    <form
      name="form"
      onSubmit={handleSubmit((data) => {
        handleOnLogin(data)
      })}
      autoComplete="off"
    >
      <Box sx={styles.formContainer}>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e)
                triggerValidation("email")
              }}
              onFocus={() => {
                triggerValidation("email")
              }}
              InputLabelProps={{ sx: styles.textFieldLabel }}
              InputProps={{
                sx: { root: styles.inputField },
              }}
              autoFocus={true}
              inputProps={{ "data-testid": "username" }}
              label={t("email.text")}
              type="email"
              error={!!errors.email}
              helperText={
                errors.email?.message && t(`${errors.email?.message}`)
              }
              sx={styles.emailField}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              autoComplete="on"
              onInputCapture={(e) => {
                field.onChange(e)
                triggerValidation(field.name)
              }}
              InputLabelProps={{ sx: styles.textFieldLabel }}
              InputProps={{
                endAdornment: (
                  <ShowPasswordIcon
                    showPassword={showPassword}
                    onClick={handleOnClickRevealPass}
                  />
                ),
                sx: { root: styles.inputField },
              }}
              inputProps={{ "data-testid": "password" }}
              type={showPassword ? "text" : "password"}
              error={!!errors.password}
              helperText={
                errors.password?.message && t(`${errors.password?.message}`)
              }
              label={t("password.text")}
              style={{
                marginTop: "8px",
              }}
            />
          )}
        />

        <Button
          data-testid="loginButton"
          disabled={!isValid}
          sx={(theme: Theme) => styles.loginButton(theme)}
          type="submit"
          color="primary"
          variant="contained"
        >
          {t("login.text")}
        </Button>
      </Box>
    </form>
  )
}

export default LoginForm
