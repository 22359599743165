/* eslint-disable camelcase */
import React from "react"

import { GRID_SPACING } from "core/constants/ui"
import MachineSpecsCard from "core/components/AppCard/MachineSpecsCard"
import { RootState } from "store/rootReducer"
import ScrollTop from "core/components/ScrollToTop"
import { useSelector } from "react-redux"

import { Grid } from "@mui/material"

import AssetButtons from "./subcomponents/AssetButtons"
import AssetsAnalytics from "./subcomponents/AssetsAnalytics"
import MachineHealthChart from "../MachineHealthChart"
import MachineInfo from "../MachineInfoCard"

const MachineDetailScreen = () => {
  const { asset_collection, machine_id: machineId } = useSelector(
    (state: RootState) => state.machines.machineSelected,
  )
  const { conditionMonitoringLastUpdate } = useSelector(
    (state: RootState) => state.global,
  )

  const [selectedAssetId, setSelectedAssetId] = React.useState(
    asset_collection[0].asset_id ?? false,
  )

  return (
    <>
      <Grid container spacing={1}>
        {!!machineId && (
          <Grid item xs={12}>
            <Grid container spacing={GRID_SPACING}>
              <Grid item md={6} sm={12} lg={6}>
                <MachineSpecsCard />
              </Grid>
              <Grid item md={6} sm={12} lg={6}>
                <MachineInfo />
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item md={12} sm={12} lg={12}>
              <MachineHealthChart />
            </Grid>
            <Grid item md={12} sm={12} lg={12}>
              <AssetsAnalytics
                formattedTimestamp={conditionMonitoringLastUpdate ?? 0}
                assetButtons={
                  <AssetButtons
                    selected={selectedAssetId}
                    setAsset={setSelectedAssetId}
                    assets={asset_collection}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ScrollTop />
    </>
  )
}

export default MachineDetailScreen
