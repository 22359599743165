import { Box } from "@mui/material";
import React from "react";
import logoCoreBeat from "../../../../assets/logos/cb_logo_orange.png";

import { styles } from "./styles";

export const NUMBER_OF_CLICKS_REQUIRED = 2
export const CODE_FOR_LOCALHOST = "0000"
export const CODE_FOR_DEV = "1000"
export const CODE_FOR_CALPAK = "1002"
export const CODE_FOR_PROD = "2000"
export const CODE_FOR_DUMMY_DATA_TEST = "3000"
export const CODE_FOR_MOCKED_BACKEND_LOGIN = "5000"
export const CODE_FOR_MOCKED_BACKEND_LOGIN_WITH_DYNAMIC_UI = "5001"

const Logo = () => {
  const logo = logoCoreBeat

  return (
    <Box data-testid="logo" sx={styles.logoContainer}>
      <img src={logo} alt="logo" style={styles.logo} />
    </Box>
  )
}

export default Logo
