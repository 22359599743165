import React from "react";
import { useEffect } from "react";

import { capitalizeFistLetter } from "core/helpers";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import { setLanguage } from "store/rootSlice";

import NativeSelect from "@mui/material/NativeSelect";

const DropdownMenuLanguage = () => {
  const { t } = useTranslation()

  const selectState = (state: RootState) => state.global
  const { language } = useSelector(selectState)
  const dispatch = useDispatch()

  const handleLanguageSwitch = () => {
    dispatch(setLanguage(language === "en" ? "el" : "en"))
  }
  useEffect(() => {
    i18n.changeLanguage(language)
  }, [i18n, language])

  return (
    <NativeSelect
      data-cy="languageButton"
      value={language}
      onChange={handleLanguageSwitch}
      name="language"
      inputProps={{ "aria-label": "language" }}
    >
      <option value="el" data-cy="greekLanguageOption">
        {capitalizeFistLetter(t("greek"))}
      </option>
      <option value="en" data-cy="englishLanguageOption">
        {capitalizeFistLetter(t("english"))}
      </option>
    </NativeSelect>
  )
}

export default DropdownMenuLanguage
