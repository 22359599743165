export const styles = {
  baseCard: {
    boxShadow: 0,
    width: "100%",
    m: 0,
    pt: 0,
  },
  graphContainer: {
    ml: 0,
    mt: 2,
    pr: 1,

    boxShadow: 0,
  },
}
