import React from "react"
import { Box, Tabs } from "@mui/material"
import ApartmentIcon from "@mui/icons-material/Apartment"
import { styles } from "./styles"

import DeptTab from "./subcomponents/DeptTab"
import { useDispatch } from "react-redux"
import { setSelectedDepartmentId } from "store/Department/departmentSlice"
import AppCardLoader from "core/components/AppCardLoader"

interface DepartmentsTabComponentProps {
  departmentsCollection?: any[]
  value?: string | boolean
}

const DepartmentsTabComponent = ({
  departmentsCollection,
  value,
}: DepartmentsTabComponentProps) => {
  const dispatch = useDispatch()
  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    dispatch(setSelectedDepartmentId(newValue))
  }

  return (
    <AppCardLoader
      isLoading={false}
      hasError={!departmentsCollection}
      onRetry={undefined}
    >
      <Box sx={styles.departmentTabContainer}>
        <ApartmentIcon fontSize="small" sx={{ color: "text.secondary" }} />

        <Tabs onChange={handleChange} value={value} sx={styles.tablist}>
          {departmentsCollection?.map((department: any) => (
            <DeptTab
              value={department?.department_id}
              deptId={department?.department_id}
              key={department?.department_id}
            />
          ))}
        </Tabs>
      </Box>
    </AppCardLoader>
  )
}

export default DepartmentsTabComponent
