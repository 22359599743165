import { Tab } from "@mui/material"
import React from "react"
import { styles } from "./styles"
import { useGetDepartmentInfoQuery } from "store/Department/departmentSlice"
import AppTypography from "core/components/AppTypography"

const DeptTab = ({ deptId, onChange, ...rest }: any) => {
  const { departmentName = null } = useGetDepartmentInfoQuery(deptId, {
    selectFromResult: ({ data }) => ({
      departmentName: data?.department_name,
    }),
  })

  return (
    <Tab
      {...rest}
      value={deptId}
      label={<AppTypography variant="h3">{departmentName}</AppTypography>}
      key={deptId}
      onClick={(e) => onChange(e, deptId)}
      sx={styles.tab}
    />
  )
}

export default DeptTab
