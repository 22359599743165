import { Amplify, Auth as AWSAuth } from "aws-amplify"
import axios from "axios"
import { batch } from "react-redux"
import { CORE_ORGANIZATION_ID } from "core/constants/api"
import DashboardAPI from "core/api/DashboardApi"
import { deleteToken, subscribeTokenToTopic } from "libraries/firebaseInit"
import { getDynamicUIFileName, isMockingEnabled } from "core/helpers/dynamic_ui"
import jwt_decode from "jwt-decode"
import {
  logIn,
  logOut,
  setIsLogInLoading,
  setLoginError,
} from "store/rootSlice"
import { millisecondsToSeconds } from "date-fns"
import { raise } from "core/helpers"
import { setDynamicUI } from "store/UI/UISlice"
import { setOrganizationId } from "store/Organisation/organizationSlice"
import { setUserInfo } from "store/UserInfo/userInfoSlice"
import { store } from "store/store"
import { TBackendEvironments } from "types/misc"
import UIAPI from "core/api/UI"

import { AWS_COGNITO_CONFIG, ENVS } from "../config"

interface ICredentials {
  username: string
  password: string
}

type tokenType = Record<string, string>

const config = {
  ...AWS_COGNITO_CONFIG,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_WEB_CLIEND_ID,
}

Amplify.configure({
  Auth: config,
})

type BootstrapLoginParams = {
  user: any
}

export const bootstrapLogin = async ({ user }: BootstrapLoginParams) => {
  try {
    !user && raise("user not found")

    const organizationId = user.attributes["custom:client-organisation"]

    await subscribeTokenToTopic(organizationId)

    const configUIRequestResponse = await UIAPI.getUIConfig({
      yaml_file_name: getDynamicUIFileName(),
    })
    batch(() => {
      store.dispatch(setDynamicUI(configUIRequestResponse.data?.configUI))
      store.dispatch(logIn())
    })
  } catch (error) {
    raise("error while bootstraping login")
  }
}

export type adminSignInParams = {
  username: string
  password: string
  organization_id: string
}

export const adminSignIn = async ({
  username,
  password,
  organization_id,
}: adminSignInParams) => {
  store.dispatch(setLoginError(""))
  const user = AWSAuth.configure({ storage: localStorage })
  store.dispatch(setIsLogInLoading(true))

  sessionStorage.clear()

  try {
    await AWSAuth.signIn(username, password)
    const token = (await AWSAuth.currentSession()).getIdToken().getJwtToken()
    localStorage.setItem("token", token)

    const tokenJSON = jwt_decode<tokenType>(token)

    !!tokenJSON["custom:client-organization"] &&
      raise("organization id detected on current user")

    const org = await DashboardAPI.findOrganisationById({
      uuid: organization_id,
    })

    !org.data && raise("organization id not required")

    store.dispatch(setOrganizationId(organization_id))
    bootstrapLogin({ user })
    return user
  } catch (error) {
    const { message: errorMessage } = error as Error
    if (!!errorMessage) {
      store.dispatch(setLoginError(errorMessage))
    }

    store.dispatch(logOut())

    return error
  }
}

export const decodeStoredToken = async () => {
  if (isMockingEnabled()) {
    return {
      "custom:client-organisation": CORE_ORGANIZATION_ID,
    }
  }

  const storedToken = localStorage.getItem("token")
  if (!storedToken) return
  return jwt_decode(storedToken)
}

export const signIn = async ({ username, password }: ICredentials) => {
  store.dispatch(setLoginError(""))
  AWSAuth.configure({ storage: localStorage })
  store.dispatch(setIsLogInLoading(true))
  sessionStorage.clear()

  try {
    const user = await AWSAuth.signIn(username, password)
    const token = await (await AWSAuth.currentSession())
      .getIdToken()
      .getJwtToken()
    localStorage.setItem("token", token)

    const tokenJSON = jwt_decode<tokenType>(token)

    const tokenDecodedString = JSON.stringify(token)

    const organisationId = tokenJSON["custom:client-organisation"]

    localStorage.setItem("token_decoded", tokenDecodedString)
    batch(() => {
      store.dispatch(setUserInfo(user.attributes))
      store.dispatch(setOrganizationId(organisationId))
    })

    bootstrapLogin({ user })

    return user
  } catch (error) {
    const { message: errorMessage } = error as Error
    if (!!errorMessage) {
      store.dispatch(setLoginError(errorMessage))
    }

    store.dispatch(logOut())

    return error
  }
}

export const logout = async () => {
  axios.defaults.baseURL =
    ENVS[process.env.REACT_APP_BACKEND_ENV as TBackendEvironments]

  try {
    await deleteToken()
    const res = await AWSAuth.signOut()
    batch(() => {
      store.dispatch(setOrganizationId(null))
      store.dispatch(logOut())
    })
    return res
  } catch (error) {
    return error
  }
}

export const autoSignIn = async () => {
  AWSAuth.configure({ storage: localStorage })
  store.dispatch(setIsLogInLoading(true))

  try {
    const user = await AWSAuth.currentAuthenticatedUser().catch(
      async (error: any) => {
        console.error("error", error)

        store.dispatch(logOut())
      },
    )
    const userSession = user?.getSignInUserSession()
    const isSessionValid = userSession?.isValid()

    if (isSessionValid) {
      await bootstrapLogin({ user })
    } else store.dispatch(setIsLogInLoading(false))
  } catch (error) {
    const { message: errorMessage } = error as any
    if (!!errorMessage) {
      store.dispatch(setLoginError(errorMessage))
    }
    store.dispatch(logOut())

    return error
  }
}

export const getNewToken = async () => {
  try {
    const session = await AWSAuth.currentSession()
    const idTokenExpire = session.getIdToken().getExpiration()
    const refreshToken = session.getRefreshToken()
    const currentTimeSeconds = millisecondsToSeconds(new Date().valueOf())
    if (idTokenExpire < currentTimeSeconds) {
      const cognitoUser = await AWSAuth.currentAuthenticatedUser()
      cognitoUser.refreshSession(refreshToken, (err: unknown, data: any) => {
        if (err instanceof Error) {
          AWSAuth.signOut()
        }

        return data.getIdToken().getJwtToken()
      })
    }

    return session.getIdToken().getJwtToken()
  } catch (e) {
    console.error("error", e as Error)
    await AWSAuth.signOut()
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    return ""
  }
}
