import { useTheme } from "@mui/material"
import { ChartDataset, ChartOptions } from "chart.js"

import LineChart from "core/components/ChartJS"
import { reformAssetData } from "core/dataActions"
import React from "react"
import { styles } from "./styles"

import AppTypography from "core/components/AppTypography"

import { useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import { addMinutes } from "date-fns"
import {
  calculateGraphUpperOffsetValue,
  getMaxTicksForGraphs,
  getPredictionTooltip,
  getTimeFormat,
} from "core/helpers"
import {
  DEFAULT_SPAN_GAPS,
  getSpanGraph,
} from "screens/Facilities/subcomponents/Dynamic/helpers"

type ChartJSPredictionProps = {
  dataArray?: [{ date: string; value: number }] | []
  prediction?: [{ date: string; value: number }] | []
  thresholds?: { warning: number; critical: number }
  title?: string
  measureUnitCode?: string
  isLoading: boolean
  isError: boolean
  offset?: number
  graphsInCurrentRow: number
  onRetry?: () => void
}

const ChartJSPrediction = ({
  dataArray = [],
  prediction = [],
  thresholds = undefined,
  title,
  measureUnitCode,
  isLoading,
  isError,
  graphsInCurrentRow,
  offset = 60,
  onRetry,
}: ChartJSPredictionProps) => {
  const { globalSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )
  const GRAPH_MIN_DATE = globalSelectedRange.fromEpoch * 1000 ?? null
  const GRAPH_MAX_DATE =
    addMinutes(globalSelectedRange.toEpoch * 1000, offset).valueOf() ?? null

  const theme = useTheme()

  const yAxisMax = Math.max(...dataArray.map((item) => item.value))
  const yAxisMin = Math.min(...dataArray.map((item) => item.value))
  const pretictAxisMax = Math.max(...prediction.map((item) => item.value))
  const predictAxisMin = Math.min(...prediction.map((item) => item.value))

  const hasThresholds = !!thresholds
  const maxValue = yAxisMax > pretictAxisMax ? yAxisMax : pretictAxisMax
  const upperBoundValue = !!hasThresholds
    ? calculateGraphUpperOffsetValue(maxValue, thresholds)
    : 0

  const data = [
    {
      data: reformAssetData(dataArray ?? []),
      borderColor: theme.palette.primary.main,
      yAxisID: "y",
      borderWidth: 2,
      drawActiveElementsOnTop: true,
      radius: 0,
    },
    {
      yAxisID: "y1",
      data: reformAssetData(prediction ?? []),
      borderColor: theme.palette.error.main,
      borderWidth: 2,
      borderDash: [5, 5],
      // radius: 2,
    },
  ] as ChartDataset<"line">[]

  const SPAN_GAPS =
    getSpanGraph({
      DEFAULT_SPAN_GAPS,
      startTime: globalSelectedRange.fromEpoch,
      endTime: globalSelectedRange.toEpoch,
    }) ?? DEFAULT_SPAN_GAPS

  const options: ChartOptions<"line"> = {
    spanGaps: SPAN_GAPS,
    clip: { left: 0, right: 0, top: 0, bottom: 1 },
    layout: { padding: { left: 10 } },
    elements: {
      point: {
        pointStyle: "circle",
      },
    },

    scales: {
      y: {
        min: yAxisMin,
        max: hasThresholds ? upperBoundValue : yAxisMax,
        title: {
          display: false,
          text: `${measureUnitCode}`,
        },
      },
      y1: {
        min: predictAxisMin,
        max: hasThresholds ? upperBoundValue : pretictAxisMax,
        display: false,
      },

      x: {
        type: "time",
        ticks: {
          callback: (value: any) => getTimeFormat(value),
          maxTicksLimit: getMaxTicksForGraphs(graphsInCurrentRow),
          autoSkip: true,
        },
        min: GRAPH_MIN_DATE,
        max: GRAPH_MAX_DATE,
      },
    },

    plugins: {
      tooltip: getPredictionTooltip(dataArray, prediction),
      // legend: { display: true },
    },
  }

  return (
    <AppGraphWrapper
      onRetry={onRetry}
      restHeaderProps={{
        customHeader: (
          <AppTypography
            variant="h4"
            sx={styles.title}
            textTransform="capitalize"
          >
            {title}
          </AppTypography>
        ),
      }}
      baseCardStyle={styles.predictionContainer}
      graphContainerStyle={styles.graphContainer}
      isLoading={isLoading}
      hasError={isError}
    >
      <LineChart
        upperBoundOffset={upperBoundValue}
        zoomEnabled={false}
        multiLines
        graphData={data}
        config={options}
        thresholds={thresholds}
      />
    </AppGraphWrapper>
  )
}

export default ChartJSPrediction
