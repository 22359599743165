import { IPostGetTimeseriesParams } from "types/assetsApi";
import { minutesToMilliseconds } from "date-fns";
import { useGetMachineStateQuery } from "store/Machines/machinesSlice";
import { useGetOrganizationQuery } from "store/Organisation/organizationSlice";
import {
  useGetRMSQuery, useGetTempQuery,
  useGetVelocityQuery, usePostGetPredictDataQuery,
  usePostGetTsDataQuery,
} from "store/Assets/assetsSlice";

type UseQuerySubscriptionOptions = {
  skip?: boolean
  refetchOnMountOrArgChange?: boolean | number
  pollingInterval?: number
  refetchOnReconnect?: boolean
  refetchOnFocus?: boolean
}

export type UseAppGetMachineStateQueryParams = {
  machine_id: string
  type?: "state"
  start_time?: number
  end_time?: number
}

export const useAppGetOrganizationQuery = (
  organizationId: string,
  options?: UseQuerySubscriptionOptions,
) =>
  useGetOrganizationQuery(
    {
      uuid: organizationId,
    },
    {
      ...options,
      skip: options?.skip ?? false,
      selectFromResult: ({
        data,
        isFetching,
        fulfilledTimeStamp,
        isError,
        isSuccess,
      }) => ({
        isOrganizationFetching: isFetching,
        organizationData: data,
        isSuccess,
        fulfilledTimeStamp,
        isOrganizationError: isError,
      }),
    },
  )

export const useAppGetVibrationQuery = (
  params: any,
  vibrationType: string,
  dataSelector: string,
  options?: UseQuerySubscriptionOptions,
) => {
  if (vibrationType === "velocity")
    return useGetVelocityQuery(params, {
      skip: options?.skip ?? false,
      pollingInterval: options?.pollingInterval ?? minutesToMilliseconds(15),
      selectFromResult: ({ data, isFetching, isError }) => ({
        vibrationData: data?.[dataSelector]?.values,
        warning: data?.warning_threshold,
        critical: data?.critical_threshold,
        isFetching,
        isError,
      }),
    })
  return useGetRMSQuery(params, {
    skip: options?.skip ?? false,
    pollingInterval: options?.pollingInterval ?? minutesToMilliseconds(15),
    selectFromResult: ({ data, isFetching, isError }) => ({
      vibrationData: data?.[dataSelector]?.values,
      warning: data?.warning_threshold,
      critical: data?.critical_threshold,
      isFetching,
      isError,
    }),
  })
}

export const useAppGetTempQuery = (
  params: any,
  options?: UseQuerySubscriptionOptions,
) =>
  useGetTempQuery(params, {
    skip: options?.skip ?? false,
    pollingInterval: minutesToMilliseconds(15),
    selectFromResult: ({ data, isFetching, isError, fulfilledTimeStamp }) => ({
      temperatureData: data?.values,
      warning: data?.warning_threshold,
      critical: data?.critical_threshold,
      isFetching,
      isError,
      fulfilledTimeStamp,
    }),
  })

export const useAppPostGetTimeseries = (
  params: Partial<IPostGetTimeseriesParams>,
  options?: UseQuerySubscriptionOptions,
) =>
  usePostGetTsDataQuery(params, {
    skip: options?.skip ?? false,
    pollingInterval: options?.pollingInterval ?? minutesToMilliseconds(15),
    selectFromResult: ({
      data,
      isFetching,
      isSuccess,
      isError,
      fulfilledTimeStamp,
    }) => ({
      data: data?.data ?? [],
      isFetching,
      isError,
      isSuccess,
      fulfilledTimeStamp,
    }),
  })

export const useAppGetPrediction = (
  { originalDataParams, predictionDataParams }: any,
  options?: UseQuerySubscriptionOptions,
) =>
  usePostGetPredictDataQuery(
    {
      originalDataBody: originalDataParams,
      predictionRequestBody: predictionDataParams,
    },
    {
      skip: options?.skip ?? false,
      pollingInterval: options?.pollingInterval ?? minutesToMilliseconds(15),
      selectFromResult: ({
        data,
        isFetching,
        isSuccess,
        isError,
        fulfilledTimeStamp,
      }) => ({
        data: data ?? [],
        isFetching,
        isError,
        isSuccess,
        fulfilledTimeStamp,
      }),
    },
  )

export const useAppGetRMSQuery = (
  params: any,
  options?: UseQuerySubscriptionOptions,
) =>
  useGetRMSQuery(params, {
    skip: options?.skip ?? false,
    pollingInterval: options?.pollingInterval ?? minutesToMilliseconds(15),
    selectFromResult: ({ data, isFetching, isError, fulfilledTimeStamp }) => ({
      data: data?.[`rms_${params.axis}`]?.values ?? null,
      isFetching,
      isError,
      fulfilledTimeStamp,
    }),
  })

export const useAppGetMachineStateQuery = (
  params: UseAppGetMachineStateQueryParams,
  options?: UseQuerySubscriptionOptions,
) =>
  useGetMachineStateQuery(params, {
    skip: options?.skip ?? false,
    pollingInterval: minutesToMilliseconds(60),
    selectFromResult: ({ data, isFetching, isError }) => ({
      healthData: data?.state ?? [],
      isFetching,
      isError,
    }),
  })
