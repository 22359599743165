import React from "react"

import AppTypography from "core/components/AppTypography"
import { DefaultTFuncReturn } from "i18next"

import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined"
import { Box } from "@mui/material"

import { styles } from "./styles"
import { isUrlAndImage } from "core/helpers"

interface ImgWithPlaceholderProps {
  imgSrc?: string
  placeholderText?: string | DefaultTFuncReturn
}

const ImgWithPlaceholder = ({
  imgSrc,
  placeholderText,
}: ImgWithPlaceholderProps) => {
  if (isUrlAndImage(imgSrc))
    return (
      <Box sx={styles.container}>
        <img
          style={{
            padding: "8px",
            borderRadius: "4px",
            boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.25)",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          src={imgSrc}
          alt="machine-img"
        />
      </Box>
    )
  return (
    <Box sx={styles.container}>
      <AddPhotoAlternateOutlinedIcon sx={{ width: "32px", height: "32px" }} />
      {placeholderText && (
        <AppTypography capitalizeFirstLetter sx={styles.text}>
          {placeholderText}
        </AppTypography>
      )}
    </Box>
  )
}

export default ImgWithPlaceholder
