export const styles = {
  container: { paddingLeft: 1, display: "flex", flexDirection: "column" },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerLabel: {
    display: "flex",
    alignItems: "center",
  },
  headerTempLabel: {
    color: "text.primary",
    fontSize: "0.938rem",
    display: "flex",
    alignItems: "center",
  },
  headerTemperature: {
    display: "flex",
    alignItems: "center",
  },
  headerLastValue: {
    display: "flex",
    alignItems: "center",
  },
  subHeaderContainer: {
    mt: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  currentContainer: {
    display: "flex",
    alignItems: "center",
  },
  formController: {
    width: "fit-content",
    ".MuiSvgIcon-root": {
      color: "text.secondary",
    },
  },

  lastUpdate: {
    color: "text.secondary",
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center",
  },

  currentLabel: {
    color: "text.secondary",
    fontSize: "14px",
    mr: "5px",
  },
  currentValue: {
    fontWeight: 700,
    fontSize: "14px",
    color: "text.primary",
  },
  infoIcon: { color: "text.secondary", height: "15px" },

  divider: { mx: 2, height: "18px", alignSelf: "center" },
}
