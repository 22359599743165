import React from "react";

import { RootState } from "store/rootReducer";
import { setAssetTypeVelocity } from "store/Assets/assetsSlice";
import { useDispatch, useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";

import { styles } from "./styles";

type VelocityTypeProps = {
  //eslint-disable-next-line
  capitalizeFistLetter: (text: string) => string
  //eslint-disable-next-line
  t: (text: string) => string
}

const VelocityType = ({ capitalizeFistLetter, t }: VelocityTypeProps) => {
  const { assetTypeVelocity } = useSelector((state: RootState) => state.assets)

  const dispatch = useDispatch()

  const onClick = (selection: string) => {
    dispatch(setAssetTypeVelocity(selection))
  }

  const getStyle = (type: string) =>
    assetTypeVelocity === type
      ? styles.selectorContainerSelected
      : styles.selectorContainer

  return (
    <Box sx={styles.typeSelector}>
      <Typography
        itemID="velocity"
        onClick={() => onClick("velocity")}
        sx={() => getStyle("velocity")}
      >
        {capitalizeFistLetter(t("velocity.text"))} (mm/s)
      </Typography>
      <Typography
        itemID="acceleration"
        onClick={() => onClick("acceleration")}
        sx={() => getStyle("acceleration")}
      >
        {capitalizeFistLetter(t("acceleration.text"))} (mm/s²)
      </Typography>
    </Box>
  )
}

export default VelocityType
