import { Tabs } from "@mui/material"

import React from "react"
import { styles } from "./styles"
import AssetTab from "../AssetTab"
import { useDispatch } from "react-redux"
import { setSelectedAsset } from "store/Assets/assetsSlice"

const AssetButtons = ({ selected, assets: availableAssets, setAsset }: any) => {
  const dispatch = useDispatch()
  const handleOnChange = (_event: React.SyntheticEvent, newValue: string) => {
    dispatch(setSelectedAsset({ asset_id: newValue }))
    setAsset(newValue)
  }

  React.useEffect(() => {
    dispatch(
      setSelectedAsset({ asset_id: availableAssets[0]?.asset_id ?? false }),
    )
    setAsset(availableAssets[0]?.asset_id ?? false)
  }, [availableAssets])

  return (
    <Tabs
      sx={styles.tabsContainer}
      centered
      value={selected}
      onChange={handleOnChange}
    >
      {availableAssets.map(({ asset_id }: any) => (
        <AssetTab sx={styles.buttonTab} key={asset_id} value={asset_id} />
      ))}
    </Tabs>
  )
}

export default AssetButtons
