import AppTypography from "core/components/AppTypography"
import { format } from "date-fns"
import { t } from "i18next"
import React from "react"
import { BaseCardHeaderProps } from "../BaseCardHeader"

const LastUpdate = ({
  lastUpdate,
}: Pick<BaseCardHeaderProps, "lastUpdate">) => {
  const text = !!lastUpdate
    ? `${t("last.update.text")}: ${format(
        new Date(lastUpdate),
        "dd/MM, HH:mm",
      )}`
    : `${t("last.update.text")}: -`

  return (
    <AppTypography
      capitalizeFirstLetter
      typographyProps={{
        fontSize: "0.75rem",
        color: "text.secondary",
      }}
    >
      {text}
    </AppTypography>
  )
}

export default LastUpdate
