export const styles = {
  container: {
    display: "flex",
    height: "100%",
    // minHeight: 240,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 3,
  },
}
