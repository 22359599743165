const selectorContainer = {
  px: 2,
  borderRight: "1px solid",
  borderColor: "divider",
  ":first-of-type": {
    pl: 1,
  },
  ":last-of-type": {
    borderRight: "none",
  },
  ":hover": {
    cursor: "pointer",
  },
}

export const styles = {
  axisContainer: {
    alignContent: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  axisLabel: {
    mx: 2,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 5,
  },
  currentContainer: {
    display: "flex",
  },
  currentLabel: {
    color: "text.secondary",
    fontSize: "14px",
    mr: "5px",
  },
  currentValue: {
    color: "text.primary",
    fontSize: "14px",
    fontWeight: 700,
  },
  divider: {
    alignSelf: "center",
    height: "18px",
    mx: 2,
  },
  formContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%",
  },
  formControl: {
    width: "fit-content",
    ".MuiSvgIcon-root": {
      color: "text.secondary",
    },
  },
  graphicEq: {
    color: "#7E7E7E",
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  headerLabel: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTempLabel: {
    color: "text.primary",
    fontSize: "0.938rem",
  },
  infoIcon: {
    color: "text.secondary",
    height: "15px",
  },
  lastUpdate: {
    color: "text.secondary",
    fontSize: "0.75rem",
  },
  typeSelector: {
    display: "flex",
    flexDirection: "row",
    my: 3,
    height: "20px",
  },
  selectorContainer,
  selectorContainerSelected: {
    ...selectorContainer,
    fontWeight: 700,
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
  },
  subHeaderContainer: {
    alignItems: "right",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    minWidth: 120,
    mt: 2,
  },
}
