import BAPI from ".."
import { GenericApiCallType } from "types/misc"

export interface IProductionLineInfo {
  production_line_name: string
  production_line_description: string
  production_line_timestamp: number
  production_line_location: string
  department_id: string
}

const ProductionLineAPI = {
  async getProductionLineInfo(
    productionLineId: string,
  ): GenericApiCallType<IProductionLineInfo> {
    return BAPI.get(`production_line/${productionLineId}`, {
      timeout: 3000,
      timeoutErrorMessage: "No response from server",
    })
  },
}
export default ProductionLineAPI
