import React from "react"

import { ROW_HEIGHT } from "core/constants/ui"
import { useInView } from "react-intersection-observer"

import { CircularProgress, Grid, Paper } from "@mui/material"

import DynamicTimeseriesGraph from "../../../DynamicTimeseriesGraph"
import DynamicVibrationGraph from "../../../DynamicVibrationGraph"
import SimpleTimeseriesGraph from "../../../SimpleTimeseriesGraph"
import { styles } from "./styles"
import DynamicHealthBarChartGraph from "../../../HealthBarChartGraph"
import DynamicPredictionGraph from "../../../DynamicPredictionGraph"

const ComponentSelector = (props: any) => {
  const { dimension, row, column } = props

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  const selectGraph = () => {
    switch (column.graph.type) {
      case "timeseries":
        return (
          <DynamicTimeseriesGraph
            {...column.graph}
            graphsInCurrentRow={row.columns.length}
            heightFactor={row.heightFactor}
          />
        )
      case "vibration":
        return (
          <DynamicVibrationGraph
            {...column.graph}
            graphsInCurrentRow={row.columns.length}
            heightFactor={row.heightFactor}
          />
        )

      case "simpleTimeseries":
        return (
          <SimpleTimeseriesGraph
            {...column.graph}
            graphsInCurrentRow={row.columns.length}
            heightFactor={row.heightFactor}
          />
        )

      case "healthBarChart":
        return (
          <DynamicHealthBarChartGraph
            {...column.graph}
            graphsInCurrentRow={row.columns.length}
            heightFactor={row.heightFactor}
          />
        )
      case "twoLineTimeseries":
        return (
          <DynamicPredictionGraph
            {...column.graph}
            graphsInCurrentRow={row.columns.length}
            heightFactor={row.heightFactor}
          />
        )
    }
  }

  const height = row.heightFactor * ROW_HEIGHT

  return (
    <Grid ref={ref} item md={dimension} sm={dimension} lg={dimension}>
      {inView ? (
        selectGraph()
      ) : (
        <Paper elevation={0} sx={{ ...styles.baseCard, height }}>
          <CircularProgress />
        </Paper>
      )}
    </Grid>
  )
}

export default ComponentSelector
