import { DEFAULT_SPACING_FACTOR } from "core/constants/ui"
export const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  titleContainer: { flexBasis: "100%" },
  bodyContainer: { flex: 1, height: "auto" },
  image: { width: "100%" },
  title: {
    textAlign: "center",
  },
  body: {
    marginTop: 10 / DEFAULT_SPACING_FACTOR,
    marginBottom: 10 / DEFAULT_SPACING_FACTOR,
    textAlign: "left",
  },
}
