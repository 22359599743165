import { createSlice } from "@reduxjs/toolkit"
import { IUserInfo } from "types/store"

const initialState: IUserInfo = {
  user: {
    sub: "",
    "custom:name": "",
    "custom:surnmae": "",
    "custom:client-organisation": "",
    name: "",
    "custom:shift": "",
    "custom:job_title": "",
    "custom:role": "",
    email: "",
  },
}

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.user = action.payload
    },
  },
})

export const { setUserInfo } = userInfoSlice.actions

export default userInfoSlice.reducer
