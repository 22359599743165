import React from "react"

import { Typography } from "@mui/material"

import packageJson from "../../../../package.json"

const VersionInfo = () => {
  const isInTest = process.env.NODE_ENV === "test"
  const buildNumber = isInTest ? 0 : packageJson.buildNumber
  const version = isInTest ? 0 : packageJson.version

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Version: ${version}, Build: ${buildNumber}`}
    </Typography>
  )
}

export default VersionInfo
