import React from "react"

import AppTypography from "core/components/AppTypography"
import { useTranslation } from "react-i18next"

import { Box, useTheme } from "@mui/material"

import { styles } from "./styles"
import { getStatusColor, replaceUnderscores } from "core/helpers"

export interface IMachineTitle {
  machineName: string
  status?: string
  onClick?: () => void
}

const MachineTitle = ({
  machineName,
  status = "",
  onClick: handleOnClick,
}: IMachineTitle) => {
  const theme = useTheme()

  const { t } = useTranslation()

  const statusLabel = status.trim().toLowerCase()
  return (
    <Box sx={styles.container} onClick={handleOnClick}>
      <Box sx={styles.titleIconContainer}>
        <AppTypography variant="h6" sx={styles.machineNameLabel}>
          {replaceUnderscores(machineName)}
        </AppTypography>
        {/* <Tooltip title={capitalizeFistLetter(t("important.machine.text"))}>
          <BookmarkIcon sx={styles.bookmarkIcon} />
        </Tooltip> */}
      </Box>
      {!!status ? (
        <Box
          sx={{
            ...styles.machineStatusContainer,
            backgroundColor: getStatusColor(status, theme).main,
          }}
        >
          <AppTypography
            sx={{
              ...styles.machineStatusLabel,
              color: getStatusColor(status, theme).contrastText,
            }}
            capitalizeFirstLetter
          >
            {t(`${statusLabel}.text`)}
          </AppTypography>
        </Box>
      ) : null}
    </Box>
  )
}
export default MachineTitle
