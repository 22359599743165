/* eslint-disable camelcase */
import {
  IFindOrganizationById,
  IGenerateHistoryMachineStatus,
  IGenerateInformationCard,
  IGenerateMachineCard,
  IGetMachineStateBarDuration,
} from "types/dashboardApi"

import BAPI from ".."

const DashboardAPI = {
  async findOrganisationById(props: IFindOrganizationById) {
    const { uuid } = props
    return BAPI.get(`organisation/${uuid}`, {
      timeout: 3000,
      timeoutErrorMessage: "No response from server",
    })
  },
  generateInformatonCard(props: IGenerateInformationCard) {
    const { siteUUID } = props
    return BAPI.get(`site/${siteUUID}/cards/overview`)
  },
  generateMachineCard(props: IGenerateMachineCard) {
    const { machineId } = props
    return BAPI.get(`machine/${machineId}`)
  },
  generateHistoryMachineStatus(props: IGenerateHistoryMachineStatus) {
    const { machineId, siteUUID, duration_number, duration_type } = props
    return BAPI.get(
      `site/${siteUUID}/machine/${machineId}/machineStatusHistory?find_by_${duration_type}s=${duration_number}`,
    )
  },
  getMachineBarStateDuration(props: IGetMachineStateBarDuration) {
    const { machine_id, start_time, end_time, duration } = props
    let url = `bar_duration/${machine_id}`
    if (duration) {
      url += `?duration=${duration}`
    }

    if (start_time) {
      url += `&start_time=${start_time}`
    }

    if (end_time) {
      url += `&end_time=${end_time}`
    }

    return BAPI.get(url)
  },
  generateMachinesOverview(props: IGenerateInformationCard) {
    const { siteUUID } = props
    return BAPI.get(`site/${siteUUID}/machines/overview`)
  },
}

export default DashboardAPI
