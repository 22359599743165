export const styles = {
  root: { width: "100%", pb: 3 },
  tablist: {
    mb: 2,
    ".MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    button: {
      fontSize: "0.875rem",
      color: "text.secondary",
      padding: 0,
    },
    ".MuiTab-root": {
      textTransform: "none",
    },
    "&.Mui-disabled": {
      display: "none",
    },

    ".MuiTabs-indicator": {
      display: "none",
    },
  },

  divider: {
    mt: 1,
    mb: 4,
  },
}
