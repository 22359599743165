import ChartJSPrediction from "core/components/AppGraphViews/ChartJSPrediction"
import { useAppGetPrediction } from "core/hooks/apiHooks"
import { minutesToMilliseconds } from "date-fns"

import { useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { IDynamicGraphProps } from "types/dynamicGraph"

import { createPredictionBody } from "core/helpers"

interface IDynamicPredictionGraphProps extends IDynamicGraphProps {
  requestParams: any
  graphsInCurrentRow: number
  offset?: number
}

const DynamicPredictionGraph = ({
  requestParams,
  thresholds,
  assetId: selectedAssetId,
  refresh_interval: interval = 0,
  titleCode,
  graphsInCurrentRow,
  measureUnitCode,
}: IDynamicPredictionGraphProps) => {
  const { globalSelectedRange } = useSelector(
    (state: RootState) => state.global,
  )

  const { requestBody, predictBody } = requestParams[0]

  const payload = createPredictionBody({
    globalSelectedRange,
    requestBody,
    predictBody,
  })

  const {
    data,
    isError: isPredictionError,
    isFetching: isPredictionFetching,
    isSuccess: isPredictionSuccess,
    refetch: refetchPrediction,
  } = useAppGetPrediction(payload, {
    skip: !selectedAssetId,
    pollingInterval: minutesToMilliseconds(interval),
  })

  return isPredictionSuccess ? (
    <ChartJSPrediction
      onRetry={refetchPrediction}
      isLoading={isPredictionFetching}
      isError={isPredictionError}
      title={titleCode}
      thresholds={thresholds}
      dataArray={data.originalData}
      prediction={data.predictionData}
      measureUnitCode={measureUnitCode}
      graphsInCurrentRow={graphsInCurrentRow}
      offset={predictBody.offset?.value ?? 60}
    />
  ) : null
}

export default DynamicPredictionGraph
