import { useTheme } from "@mui/material"

const icon = { marginRight: 10 / 8, color: "text.secondary" }
export const styles = (theme = useTheme()) => ({
  root: {
    display: "flex",
    alignItem: "space-between",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  item: {
    [theme.breakpoints.down("md")]: {
      " > .item": {
        mx: 1,
        justifyContent: "flex-start",
      },
    },
    [theme.breakpoints.down("sm")]: {
      " > .item": {
        justifyContent: "flex-start",
      },
    },

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  container: {
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
  },
  divider: {
    backgroundColor: "divider",
    width: "1px",
  },
  showchartoutlined: {
    ...icon,
  },
  timelapseoutlined: {
    ...icon,
  },
  assetsIcon: {
    ...icon,
  },
})
