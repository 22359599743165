export const styles = {
  boxStyles: {
    display: "flex",
    my: 2,
    alignItems: "baseline",
    justifyContent: "center",
  },
  tempText: {
    mx: 0.5,
    fontSize: "0.938rem",
    fontWeight: "bold",
  },
}
