export const styles = {
  card: {
    px: 1,
    pt: 1,
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  gridContainer: {
    /* alignContent will be needed in the future when the indicator under each element is visible*/
    alignContent: "stretch",
    paddingTop: 2,
    height: "100%",
  },
}
