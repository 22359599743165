import * as yup from "yup";

const schema = yup.object().shape({
  username: yup.string().required("error.required").email("error.invalidMail"),
  organization_id: yup
    .string()
    .required("error.required")
    .min(25, "invalid organization id length")
    .max(26, "invalid organization id length"),
  password: yup
    .string()
    .required("error.required")
    .min(5, "error.passwordLength"),
})

export const validateForm = async (input: any) =>
  schema.validate(input, { abortEarly: true })

export const validateField = async (input: any) => {
  const { field, value } = input
  //@ts-ignore
  const subSchema = schema.pick([`${field}`])
  return subSchema.validate({ [field]: value })
}

export const isFormValid = (form: any) => schema.isValidSync(form)
