export const styles = {
  root: {
    display: "flex",
  },
  formControlLabel: {
    textTransform: "capitalize",
    width: "100%",
    margin: "5px",
    justifyContent: "space-between",
  },
}
