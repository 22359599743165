const axisLabel = {
  // fontSize: "0.75rem",
  px: 2,
  cursor: "pointer",
  borderRight: "1px solid",
  borderColor: "divider",
}

export const styles = {
  axisLabel: axisLabel,
  axisLabelSelected: {
    ...axisLabel,
    fontWeight: "bold",
  },
  axisContainer: {
    alignContent: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
}
