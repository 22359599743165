const commonStyles = {
  width: "20px",
}

const styles = {
  normal: { ...commonStyles, color: "success.main" },
  info: { ...commonStyles, color: "info.main" },
  warning: { ...commonStyles, color: "warning.main" },
  critical: { ...commonStyles, color: "error.main" },
  idle: { ...commonStyles, color: "text.secondary", width: "16px" },
  training: { ...commonStyles, color: "text.primary" },
}
export default styles
