export const styles = {
  container: {
    pt: 2,
    display: "flex",
    alignItems: "center",
    ".MuiButtonBase-root": {
      fontSize: "0.813rem",
      fontFamily: "Roboto-Light",
    },
  },
  statusIndicator: {
    mx: 2,
    borderRadius: 50,
    height: "5px",
    width: "5px",
    backgroundColor: "text.secondary",
  },
  customTabSelected: {
    ".MuiButtonBase-root": {
      color: "text.primary",
      fontWeight: "bold",
      textDecoration: "underline",
      fontSize: "0.813rem",
    },
  },
}
