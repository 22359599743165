import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./i18n";

import React, { Suspense } from "react";

import AppRoutes from "core/navigation/AppRoutes";
import { RootState } from "store/rootReducer";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

import { autoSignIn } from "./core/auth/auth";
import bootstrapApp from "./core/config/bootstrapApp";
import FmcNotifications from "./components/FmcNotifications";
import ThemeProvider from "./theme/ThemeProvider";

bootstrapApp()

const handleAutoSignIn = async () => {
  (await localStorage.getItem("token")) && autoSignIn()
}

function App() {
  const selectState = (state: RootState) => state.global
  const { themeType } = useSelector(selectState)

  React.useEffect(() => {
    handleAutoSignIn();
  }, [])

  return (
    <ThemeProvider>
      <Suspense fallback={null}>
        <AppRoutes />
        <ToastContainer
          theme={themeType}
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
      </Suspense>
      <FmcNotifications />
    </ThemeProvider>
  )
}

export default App
