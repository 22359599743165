const labelStyles = {
  marginRight: "5px",
  color: "text.secondary",
  fontSize: "0.75rem",
  paddingTop: 2,
  overflow: "hidden",
}
export const styles = {
  specItemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  label: labelStyles,
  text: {
    ...labelStyles,
    fontWeight: 700,
  },
}
