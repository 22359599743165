import React from "react"
import { logout } from "core/auth/auth"

import { useTranslation } from "react-i18next"

import { Button } from "@mui/material"

import { styles } from "./styles"

const LogoutButton = () => {
  const { t } = useTranslation()

  const [isDisabled, setIsDisabled] = React.useState(false)

  const handleOnClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setIsDisabled(true)
    await logout()
  }

  return (
    <Button
      sx={(theme) => styles.button(theme)}
      disabled={isDisabled}
      variant="contained"
      fullWidth
      onClick={handleOnClick}
    >
      {t("logout.text")}
    </Button>
  )
}

export default LogoutButton
