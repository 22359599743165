import { FormControlLabel, Checkbox } from "@mui/material"
import { Box } from "@mui/system"
import AppTypography from "core/components/AppTypography"
import DateRangePicker from "core/components/DateRangePicker"

import React from "react"
import { setShowThresholds, setGlobalTimespan } from "store/rootSlice"

import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { styles } from "./styles"
import { useTranslation } from "react-i18next"

const ActionBar = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { globalTimespan, showThresholds } = useSelector(
    (state: RootState) => state.global,
  )

  const textColor = showThresholds ? "primary" : "text.secondary"
  return (
    <Box sx={styles.container}>
      <FormControlLabel
        sx={styles.formControlLabel}
        control={
          <Checkbox
            checked={showThresholds}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch(setShowThresholds(e.target.checked))
            }
          />
        }
        label={
          <AppTypography
            variant="h5"
            textTransform="capitalize"
            typographyProps={{
              noWrap: true,
              color: textColor,
            }}
          >
            {t("show.thresholds.text")}
          </AppTypography>
        }
        labelPlacement="start"
      />
      <DateRangePicker
        selectedActionToDispatch={setGlobalTimespan}
        timespan={globalTimespan}
      />
    </Box>
  )
}

export default ActionBar
