import React, { useContext, useEffect } from "react"

import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/rootReducer"
import { setTheme } from "store/rootSlice"
import { ColorModeContext } from "theme/ThemeProvider"
import { availableThemes } from "theme/Themes"

import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material"

import LogoutButton from "../LogoutButton"
import { styles } from "./styles"
import DropdownMenuLanguage from "./subcomponents/DropdownMenuLanguage"

const AppSettings = () => {
  const { t } = useTranslation()

  const selectState = (state: RootState) => state.global
  const { themeType } = useSelector(selectState)
  const dispatch = useDispatch()

  const colorMode = useContext(ColorModeContext)

  const stopPropagation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation()
  }

  const handleThemeChange = (event: { target: { checked: boolean } }) => {
    const themeIndex = event.target.checked === true ? 1 : 0
    const themeId = availableThemes[themeIndex].id

    dispatch(setTheme(themeId))
    localStorage.setItem("themeMode", themeId)
  }

  const onMountRender = async () => {
    const savedAppTheme = await localStorage.getItem("themeMode")
    colorMode.setColorMode(savedAppTheme)
  }

  useEffect(() => {
    onMountRender()
  }, [themeType])

  return (
    <Box sx={styles.root} onClick={stopPropagation}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          sx={styles.formControlLabel}
          value="theme"
          control={
            <Switch
              checked={themeType === "dark"}
              onChange={handleThemeChange}
              color="primary"
            />
          }
          label={t("dark.theme")}
          labelPlacement="start"
        />
        <FormControlLabel
          value="language"
          sx={styles.formControlLabel}
          control={<DropdownMenuLanguage />}
          label={t("language")}
          labelPlacement="start"
        />

        <FormControlLabel
          value="logout"
          sx={styles.formControlLabel}
          control={<LogoutButton />}
          label=""
        />
      </FormGroup>
    </Box>
  )
}

export default AppSettings
