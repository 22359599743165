/* eslint-disable react/jsx-key */
import React from "react"

import BaseCard from "core/components/AppCard/BaseCard"

import { useTranslation } from "react-i18next"

import AppGraphContainer from "../../AppGraphContainer"
import { styles } from "./styles"
import ActionBar from "../ActionBar"
import MachineVibrationGraph from "screens/Facilities/subcomponents/MachineVibrationGraph"

import { useSelector } from "react-redux"
import { RootState } from "store/rootReducer"

import MachineTemperatureGraph from "screens/Facilities/subcomponents/MachineTemperatureGraph"
import { isIncludedInUrl } from "core/helpers"

interface AssetAnalyticsProps {
  formattedTimestamp: number
  assetButtons: React.ReactNode
}

const AssetAnalytics = ({
  formattedTimestamp,
  assetButtons,
}: AssetAnalyticsProps) => {
  const { assetSelected } = useSelector((state: RootState) => state.assets)

  const { t } = useTranslation()

  return (
    <BaseCard
      sx={styles.graphsContainer}
      lastUpdate={formattedTimestamp}
      title={t("asset.analytics.text")}
    >
      {assetButtons}
      <ActionBar />
      {!isIncludedInUrl(["localhost", "helexpo"]) && (
        <AppGraphContainer
          key="prediction"
          graphsToRender={{
            prediction: [],
          }}
          accordionTitle="prediction"
        />
      )}

      <AppGraphContainer
        key="monitoring"
        // eslint-disable-next-line react/jsx-key
        graphsToRender={{
          vibration: [
            <MachineVibrationGraph
              title="sensor0"
              selectedAssetId={assetSelected.asset_id || ""}
            />,
          ],
          temperature: [
            <MachineTemperatureGraph
              selectedAssetId={assetSelected.asset_id}
            />,
            // shouldShowGraph && (
            //   <MachinePowerGraph selectedAssetId={assetSelected.asset_id} />
            // ),
          ],
          // humidity: [shouldShowGraph && <MachineHumidityGraph />],
        }}
        accordionTitle="monitoring"
      />
    </BaseCard>
  )
}

export default AssetAnalytics
