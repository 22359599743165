export const styles = {
  root: {},
  card: {
    borderRadius: "0.5rem",
  },
  container: {
    mlr: 2,
    mt: 2,
    display: "flex",
    flexDirection: "column",
    p: 1,
    minHeight: "calc(100vh - 376px)",
  },
  accordionContainer: {
    "::before": {
      opacity: 0,
    },
    boxShadow: "none",
    ".MuiAccordionSummary-root": {
      minHeight: "2rem",
    },
  },
  titleText: {
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "1rem",
    letterSpacing: "0.031rem",
    boxShadow: "none",
    ".MuiAccordionRoot": {
      p: 0,
    },
  },
  departmentTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    height: "2rem",
  },
  prodLineTab: {
    backgroundColor: "background.default",
    borderRadius: "0.5rem",
    display: "flex",
    justifyItems: "space-between",
    width: "100%",
    my: 1,
    maxHeight: "2rem",
  },
  prodLineTitles: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  machineContainer: {
    boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.25)",
    mt: 1,
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
  },
  accordionInfo: {
    mr: 3,
    fontSize: "0.875rem",
  },
  accordionPadding: {
    p: 0,
  },
}
