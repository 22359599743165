import React from "react"

import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Zoom,
} from "@mui/material"

import { RootState } from "../../../store/rootReducer"
import AppTypography from "../AppTypography"

import { styles } from "./styles"
import SelectedIcon from "./subcomponents/SelectedIcon"
import { NavLink } from "react-router-dom"
import { categoryTitle, exportCategoryTitles } from "./constants/categoryTitles"

const AppDrawerNavigation = () => {
  const { t } = useTranslation()

  const selectState = (state: RootState) => state.global
  const { isDrawerOpen, language } = useSelector(selectState)

  const CATEGORY_TITLES = exportCategoryTitles()

  const getScreenTitle = (item: categoryTitle) => typeof item.titleTranslations === "object"
        ? item.titleTranslations[language as any]
        : t(item.titleTranslations)

  return (
    <Drawer
      variant="permanent"
      sx={(theme) => ({
        ...styles.drawer,
        ...(isDrawerOpen && {
          ...styles.drawerOpen(theme),
          "& .MuiDrawer-paper": styles.drawerOpen(theme),
        }),
        ...(!isDrawerOpen && {
          ...styles.drawerClose(theme),
          "& .MuiDrawer-paper": styles.drawerClose(theme),
        }),
      })}
    >
      <Toolbar />
      <Box sx={styles.listContainer}>
        <List>
          {CATEGORY_TITLES.map((item, i) => (
            <NavLink
              style={styles.navLink}
              to={item.idsAvailable[0]}
              key={item.idsAvailable[0]}
            >
              {({ isActive }) => (
                <ListItem
                  disablePadding
                  disableGutters
                  data-cy={`MenuItem${i}`}
                  sx={(theme) =>
                    isActive
                      ? {
                          ...styles.listItem(theme),
                          ...styles.listItemSelected(theme),
                        }
                      : styles.listItem(theme)
                  }
                >
                  <ListItemButton disableGutters>
                    <Tooltip
                      title={
                        !isDrawerOpen ? (
                          <AppTypography capitalizeFirstLetter>
                            {getScreenTitle(item)}
                          </AppTypography>
                        ) : (
                          ""
                        )
                      }
                      placement="right-start"
                      arrow
                    >
                      <ListItemIcon sx={styles.ListIcon}>
                        <SelectedIcon
                          iconCode={item.iconCode}
                          isActive={isActive}
                        />
                      </ListItemIcon>
                    </Tooltip>

                    <Zoom
                      in={isDrawerOpen}
                      style={{
                        transitionDelay: isDrawerOpen ? "100ms" : "0ms",
                      }}
                    >
                      <ListItemText
                        sx={styles.listItemText}
                        primary={getScreenTitle(item)}
                      />
                    </Zoom>
                  </ListItemButton>
                </ListItem>
              )}
            </NavLink>
          ))}
        </List>
      </Box>
    </Drawer>
  )
}

export default AppDrawerNavigation
