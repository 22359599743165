import * as React from "react"

import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom"

import AdminLogin from "screens/AdminLogin"
import AppMainViewContainer from "core/components/AppMainViewContainer"
import Dashboard from "screens/Dashboard"
import DynamicMachineDetailScreen from "screens/Facilities/subcomponents/Dynamic/DynamicMachineDetailScreen"
import Facilities from "screens/Facilities"
import Login from "screens/Login"
import MachineDetailScreen from "screens/Facilities/subcomponents/MachineDetailScreen"
import ProtectedRoute from "../ProtectedRoute"
import { isDynamicUIEnabled } from "core/helpers/dynamic_ui"
import { getAllScreensFromConfigUI } from "core/helpers"

const AppRoutes = () => {
  const allScreens = getAllScreensFromConfigUI()

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="login" element={<Login />} />
        <Route path="kalinoches" element={<AdminLogin />} />
        <Route element={<AppMainViewContainer />}>
          <Route index element={<Navigate to="dashboard" />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="facilities"
            element={
              <ProtectedRoute>
                <Facilities />
              </ProtectedRoute>
            }
          >
            <Route
              path="machineDetailScreen"
              element={
                <ProtectedRoute>
                  <MachineDetailScreen />
                </ProtectedRoute>
              }
            />
          </Route>
          {isDynamicUIEnabled() &&
            allScreens.map((screen: any) => {
              const screenName = Object.keys(screen)[0]
              const screenConfig = screen[screenName]
              return (
                <Route
                  key={screenName}
                  path={screenName}
                  element={
                    <ProtectedRoute>
                      <DynamicMachineDetailScreen config={screenConfig} />
                    </ProtectedRoute>
                  }
                />
              )
            })}
        </Route>
        ,
      </>,
    ),
  )

  return <RouterProvider router={router} />
}

export default AppRoutes

export const getLocalStorageScreenNames = (screenName: string): string => {
  const lookup = {
    test: "test",
    AdminLogin: "adminLogin",
    dashboard: "dashboard",
    facilities: "facilities",
    machineDetailScreen: "facilities",
    overview: "overview",
    assets: "assets",
    assetTabView: "assetTabView",
    departments: "departments",
  }

  //@ts-ignore
  return lookup[screenName]
}
