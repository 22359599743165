import React from "react"

import { Box, Link, Typography } from "@mui/material"

import defaultLogo from "../../../assets/icons/core_black.png"

import VersionInfo from "../VersionInfo"

interface ICopyrightProps {
  text?: string
  link?: ILinkProps
  logo?: any
}

export interface ILinkProps {
  url?: string
  linkText?: string
}

const handleTextWhiteSpace = (text: string) => text.trimEnd().concat(" ")

const imgStyle = { height: "50px" }

const Copyright = ({ text, link, logo }: ICopyrightProps) => {
  const footerText = text
    ? handleTextWhiteSpace(text)
    : handleTextWhiteSpace(`Copyright © ${new Date().getFullYear()}`)
  const footerLinkText = link ? link.linkText : "Core-Innovation"
  const footerLinkUrl = link ? link.url : "https://www.core-innovation.com/"
  const footerLogo = logo || defaultLogo

  return (
    <Box
      sx={{
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Typography variant="body2" color="textPrimary" align="center">
          {footerText}
          <Link color="inherit" target="#" href={footerLinkUrl}>
            {footerLinkText}
          </Link>{" "}
        </Typography>
        <Link target="#" href={footerLinkUrl}>
          <img src={footerLogo} style={imgStyle} alt="Logo" />
        </Link>
      </Box>
      <VersionInfo />
    </Box>
  )
}

export default Copyright
