import React from "react"

import { Box } from "@mui/material"

import { DaysLabel } from "./DaysLabel"

export const TopIcon = ({ icon, days }: any) => (
  <Box
    sx={{
      mr: 1,
      display: "flex",
    }}
  >
    {icon}
    <DaysLabel state={icon.props.state} days={days} />
  </Box>
)
