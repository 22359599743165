import React from "react"
import { useScrollTrigger, Zoom, Fab } from "@mui/material"
import StraightIcon from "@mui/icons-material/Straight"
import { styles } from "./styles"

const ScrollTopButton: React.FC = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 300,
  })

  return (
    <Zoom in={trigger} unmountOnExit>
      <Fab
        onClick={handleClick}
        color="primary"
        size="large"
        aria-label="scroll back to top"
        sx={styles.button}
      >
        <StraightIcon sx={{ color: "#ffffff" }} />
      </Fab>
    </Zoom>
  )
}

export default ScrollTopButton
