import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material"
import AppTypography from "core/components/AppTypography"
import React from "react"
import MachineStatus from "screens/Facilities/subcomponents/MachineStatusComponent"
import { styles } from "./styles"
import { useDispatch } from "react-redux"
import ReactNotificationComponent from "components/ReactNotificationComponent"
import { showNotification } from "store/rootSlice"
import { useTranslation } from "react-i18next"
import { useGetProductionLineInfoQuery } from "store/Production_lines/productionLineSlice"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import NoDataFound from "core/components/NoDataFound"
import AppCardLoader from "core/components/AppCardLoader"
import { Machine, MachineCollection } from "models/organization"

const ProdLinesAccordion = ({
  prodLineId,
  index,
  machineCollection,
}: {
  prodLineId: string
  index: number
  machineCollection?: MachineCollection[]
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const getMachinesLabel = (numberOfMachines: number) =>
    numberOfMachines === 1
      ? `1 ${t("machine.text")}`
      : `${numberOfMachines} ${t("machines.text")}`

  const onSelectProductionLine = (noOfMachines: number) => {
    if (noOfMachines === 0) {
      dispatch(
        showNotification({
          type: "warning",
          content: (
            <ReactNotificationComponent
              {...{
                title: t("no.machines.in.selected.production.line"),
                body: "",
              }}
            />
          ),
        }),
      )
    }
  }

  const { productionLineName, prodLineError, isProdLineFetching, refetch } =
    useGetProductionLineInfoQuery(prodLineId ?? "", {
      selectFromResult: ({ data, isError, isFetching }) => ({
        productionLineName: data?.production_line_name,
        prodLineError: isError,
        isProdLineFetching: isFetching,
      }),
    })

  const numberOfMachines: number = machineCollection
    ? machineCollection.length
    : 0

  return (
    <AppCardLoader
      isLoading={isProdLineFetching}
      hasError={prodLineError}
      onRetry={refetch}
    >
      {numberOfMachines === 0 && <NoDataFound />}
      <Accordion
        key={prodLineId}
        sx={styles.accordionContainer}
        defaultExpanded={index === 0}
        disableGutters
        onClick={() => {
          onSelectProductionLine(numberOfMachines)
        }}
      >
        <AccordionSummary
          sx={styles.prodLineTab}
          expandIcon={<ArrowDropDownIcon sx={{ color: "text.secondary" }} />}
        >
          <Box sx={styles.prodLineTitles}>
            <AppTypography variant="h3" capitalizeFirstLetter>
              {productionLineName}
            </AppTypography>
            <AppTypography variant="h5" sx={styles.accordionInfo}>
              {getMachinesLabel(numberOfMachines)}
            </AppTypography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordionPadding}>
          <Box>
            {machineCollection?.map((machine: Machine) => (
              <Box key={machine.machine_id} sx={styles.machineContainer}>
                <MachineStatus machine={machine} />
              </Box>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </AppCardLoader>
  )
}

export default ProdLinesAccordion
