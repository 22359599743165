import React from "react"

import { ChartOptions } from "chart.js"
import LineChart from "core/components/ChartJS"
import { reformAssetData } from "core/dataActions"
import { RootState } from "store/rootReducer"
import { secondsToMilliseconds, toDate } from "date-fns"
import { useAppGetTempQuery } from "core/hooks/apiHooks"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { useTheme } from "@mui/material"

import { fontSpecs } from "../helpers"
import { styles } from "./styles"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Thermostat } from "@mui/icons-material"

import AppGraphWrapper from "core/components/AppCard/AppGraphWrapper"
import AppTypography from "core/components/AppTypography"

const labelsScaleFactor = 120

const MachineTemperatureGraph = ({ selectedAssetId: assetId }: any) => {
  const { t } = useTranslation()

  const {
    showThresholds,
    globalGraphUnit,
    globalSelectedRange: temperatureGraphSelectedRange,
  } = useSelector((state: RootState) => state.global)

  const [_, setMaxTicksGraphLabels] = React.useState(
    Math.floor(window.innerWidth / labelsScaleFactor),
  )
  const handleWindowResize = () => {
    setMaxTicksGraphLabels(Math.floor(window.innerWidth / labelsScaleFactor))
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize)

    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [window.innerWidth])

  const theme = useTheme()

  const minDateToDisplay = toDate(
    secondsToMilliseconds(temperatureGraphSelectedRange.fromEpoch),
  ).toISOString()
  const maxDateToDisplay = toDate(
    secondsToMilliseconds(temperatureGraphSelectedRange.toEpoch),
  ).toISOString()

  const graphConfig = {
    scales: {
      x: {
        min: minDateToDisplay,
        max: maxDateToDisplay,
        title: {
          display: false,
        },
        type: "time",
        time: {
          unit: globalGraphUnit,
          displayFormats: {
            hour: "HH:mm",
            day: "dd/MM/yyyy",
          },
        },
        ticks: {
          autoSkip: false,
          font: {
            size: 10,
          },
          color: theme.palette.text.secondary,
          maxTicksLimit: 9,
        },
      },
      y: {
        ticks: {
          display: false,
          font: fontSpecs,
          maxTicksLimit: 6,
        },
        color: theme.palette.text.secondary,
        title: {
          display: true,
          font: fontSpecs,
          text: "°C",
        },
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
  } as ChartOptions<"line">
  const {
    temperatureData = [],
    isFetching,
    isError,
    refetch,
  } = useAppGetTempQuery(
    {
      assetId,
      start_time: temperatureGraphSelectedRange.fromEpoch,
      end_time: temperatureGraphSelectedRange.toEpoch,
    },
    {
      skip: !assetId,
    },
  )

  const handleOnRetry = () => refetch()

  return (
    <AppGraphWrapper
      isLoading={isFetching}
      hasError={isError}
      onRetry={handleOnRetry}
      noDataCheck={temperatureData.length === 0}
      restHeaderProps={{
        headerLabel: (
          <AppTypography capitalizeFirstLetter variant="h4">
            {t("temperature.text")}
          </AppTypography>
        ),

        preTitleIcon: <Thermostat sx={styles.preIcon} />,
        afterTitleIcon: <InfoOutlinedIcon sx={styles.afterIcon} />,
      }}
    >
      <LineChart
        datasetLabel={t("temperature.text")}
        zoomEnabled={false}
        config={graphConfig}
        upperBoundOffset={10}
        lineColor={theme.palette.primary.main}
        graphData={reformAssetData(temperatureData)}
        thresholds={showThresholds ? { warning: 40, critical: 60 } : null}
      />
    </AppGraphWrapper>
  )
}

export default MachineTemperatureGraph
