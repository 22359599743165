import React from "react"

interface ArcComponentProps {
  center: { x: number; y: number }
  strokeWidth?: number
  radius: number
  color?: string
  start?: number
  end?: number
  rotate?: number
}

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) => {
  // const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
  const angleInRadians = angleInDegrees

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

const createSVGArc = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number,
) => {
  const start = polarToCartesian(x, y, radius, endAngle)
  const end = polarToCartesian(x, y, radius, startAngle)

  const largeArcFlag = endAngle - startAngle <= Math.PI ? "0" : "1"

  const d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ")

  return d
}

const ArcComponent = ({
  center,
  strokeWidth = 6,
  radius,
  color = "#000",
  start = 0,
  end = 2 * Math.PI,
  rotate = 0,
}: ArcComponentProps) => (
  <path
    aria-label="arc"
    style={{ transform: `rotate(${rotate}deg)`, transformOrigin: "center" }}
    strokeWidth={strokeWidth}
    stroke={color}
    fill="none"
    d={createSVGArc(center.x, center.y, radius, start, end)}
  />
)

export default ArcComponent
