export const styles = {
  container: {
    boxShadow: 0,
    marginTop: 4,
  },
  predictionContainer: {
    m: 0,
    p: 1,
  },
  title: { mb: 2, textAlign: "left" },
  graphContainer: { m: 0, height: "176px" },
}
