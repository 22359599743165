import React from "react"
import Tab, { TabProps } from "@mui/material/Tab"
import { useAssetInfoQuery } from "store/Assets/assetsSlice"
import AppTypography from "core/components/AppTypography"

type AssetTabProps = TabProps & {}

const AssetTab = ({ value, onChange, ...rest }: AssetTabProps) => {
  const { assetName } = useAssetInfoQuery(value, {
    skip: !value,
    selectFromResult: ({ data }) => ({
      assetName: data?.asset_name,
    }),
  })
  return (
    <Tab
      value={value}
      label={
        <AppTypography capitalizeFirstLetter variant="button">
          {assetName}
        </AppTypography>
      }
      onChange={onChange}
      {...rest}
    />
  )
}

export default AssetTab
