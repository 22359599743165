import { UseAppGetMachineStateQueryParams } from "core/hooks/apiHooks"
import BAPI from ".."
import { MachineStateType } from "store/Machines/machinesSlice"
import { GenericApiCallType } from "types/misc"

const MachinesAPI = {
  async getMachineDurationInState(machineID: string) {
    return BAPI.get(`duration_in_state/${machineID}`, {
      timeout: 3000,
      timeoutErrorMessage: "No response from server",
    })
  },
  async getMachineState({
    machine_id,
    type,
    start_time,
    end_time,
  }: IMachineState): GenericApiCallType<
    MachineStateType,
    UseAppGetMachineStateQueryParams
  > {
    let url = `machine_state/${machine_id}`

    if (type) {
      url += `?type=${type}`
    }

    if (start_time) {
      url += `&start_time=${start_time}`
    }

    if (end_time) {
      url += `&end_time=${end_time}`
    }

    return BAPI.get(url, {
      timeout: 3000,
      timeoutErrorMessage: "No response from server",
    })
  },
}

export default MachinesAPI

interface IMachineState {
  machine_id: string
  type?: "health" | "state"
  start_time?: number
  end_time?: number
}
