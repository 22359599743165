import { useTheme } from "@mui/material";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "store/rootReducer";

export const useIsDarkTheme = () => {
  const theme = useTheme()
  const isDarkTheme = theme.palette.mode === "dark"
  return { isDarkTheme, theme }
}

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
