export const styles = {
  preTitle: {
    color: "text.secondary",
    mr: 1,
  },
  graphContainer: {
    boxShadow: 0,
    height: "10rem",
    p: 0,
    mx: 0,
  },
  baseCard: {
    height: "14.25rem",
  },
}
