import React from "react"

import { RootState } from "store/rootReducer"

import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { Box } from "@mui/material"

import { styles } from "./styles"
import Selector from "./subcomponents/Selector"

const AxisSelector = () => {
  const { assetAxisSelected } = useSelector((state: RootState) => state.assets)
  const { t } = useTranslation()

  return (
    <Box sx={styles.axisContainer}>
      <Selector
        value="x"
        label={t("axis.x.text")}
        selected={assetAxisSelected === "x"}
      />
      <Selector
        value="y"
        label={t("axis.y.text")}
        selected={assetAxisSelected === "y"}
      />
      <Selector
        sx={{ borderRight: "none" }}
        value="z"
        label={t("axis.z.text")}
        selected={assetAxisSelected === "z"}
      />
    </Box>
  )
}

export default AxisSelector
