export const styles = {
  container: {
    borderColor: "background.default",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: 1,
    display: "flex",
    flex: 1,

    height: "100%",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    color: "text.secondary",
  },
  text: { fontSize: "0.75rem", mt: 1, fontWeight: 700 },
}
