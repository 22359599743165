import React, { useEffect } from "react"

import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/rootReducer"

import { Box, IconButton, Typography } from "@mui/material"

import { styles } from "./styles"
import { HighlightOff } from "@mui/icons-material"
import { setLoginError } from "store/rootSlice"

const ErrorMessage = () => {
  const selectState = (state: RootState) => state.global
  const dispatch = useDispatch()
  const { loginError } = useSelector(selectState)

  const errorClose = () => {
    dispatch(setLoginError(""))
  }

  useEffect(() => {
    if (!!loginError) {
      const timeOut = setTimeout(() => errorClose(), 3000)
      return () => {
        clearTimeout(timeOut)
      }
    }
  }, [loginError])

  return !!loginError ? (
    <Box sx={styles.errorContainer}>
      <Typography sx={styles.errorMessage}>{loginError}</Typography>
      <IconButton sx={styles.icon} onClick={errorClose}>
        <HighlightOff />
      </IconButton>
    </Box>
  ) : (
    <></>
  )
}

export default ErrorMessage
