import React from "react"

import { Box } from "@mui/material"
import Icon from "@mui/material/Icon"

import { AppIconProps } from "../.."
import { styles } from "./styles"

export const AppIcon = ({ iconCode, sx }: AppIconProps) => (
  <Box sx={styles.appIcon}>
    {iconCode ? (
      <Icon sx={{ ...styles.icon, ...sx }}>{iconCode}</Icon>
    ) : (
      <Icon>question_mark</Icon>
    )}
  </Box>
)
