import React from "react"

import AppTypography from "core/components/AppTypography"

import { Box, BoxProps, Divider } from "@mui/material"

import { styles } from "./styles"

import LastUpdate from "../LastUpdate"

export interface BaseCardHeaderProps {
  headerLabel?: string | React.ReactNode
  boxStyle?: BoxProps
  showDivider?: boolean
  customHeader?: React.ReactNode
  preTitleIcon?: React.ReactNode
  afterTitleIcon?: React.ReactNode
  lastUpdate?: number | undefined
}

const BaseCardHeader = ({
  headerLabel = "",
  showDivider = false,
  customHeader,
  preTitleIcon,
  afterTitleIcon,
  lastUpdate = undefined,
  boxStyle = {
    pb: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}: BaseCardHeaderProps) => {
  const hasTitleIcons = !!preTitleIcon || !!afterTitleIcon
  const isHeaderString = typeof headerLabel === "string"
  const customHeaderProvided = !!customHeader

  return (
    <>
      {customHeaderProvided ? (
        customHeader
      ) : (
        <div>
          <Box {...boxStyle}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flex: 1,
              }}
            >
              <Box
                sx={
                  hasTitleIcons
                    ? styles.boxContainer
                    : {
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }
                }
              >
                <>
                  {!!preTitleIcon && <> {preTitleIcon} </>}

                  {isHeaderString ? (
                    <AppTypography
                      variant="h2"
                      sx={hasTitleIcons ? styles.headerAndIcons : {}}
                    >
                      {headerLabel}
                    </AppTypography>
                  ) : (
                    headerLabel
                  )}
                  {!!afterTitleIcon && afterTitleIcon}
                </>
              </Box>
              {lastUpdate && <LastUpdate lastUpdate={lastUpdate} />}
            </Box>
          </Box>
          {showDivider ? <Divider /> : null}
        </div>
      )}
    </>
  )
}

export default BaseCardHeader
