import React from "react"
import { Box, CircularProgress, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { styles } from "./styles"

const WaitLoadingScreen = () => {
  const { t } = useTranslation()

  return (
    <Box sx={styles.container}>
      <CircularProgress size={200} />
      <Typography paragraph>{t("pleaseWait.text")}</Typography>
    </Box>
  )
}

export default WaitLoadingScreen
