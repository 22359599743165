export const styles = {
  buttonTab: {
    mx: 1,
    borderColor: "text.primary",
    border: "1px solid",
    minHeight: "32px",
    p: 1,
    textTransform: "none",
    color: "text.primary",
    borderRadius: 2,
  },
  tabsContainer: {
    ".MuiTab-root.Mui-disabled": { opacity: 0.5, color: "text.secondary" },
    ".MuiTab-root.Mui-selected": { color: "#fff" },
    ".MuiTab-root.Mui-selected:hover": {
      borderColor: "primary.main",
      backgroundColor: "primary.main",
    },
    ".MuiButtonBase-root": {
      ":hover": {
        backgroundColor: "#67AAFF",
        borderColor: "#67AAFF",
        color: "#ffffff",
      },
    },
    minHeight: "32px",
    ".MuiTabs-indicator": { display: "none" },
    ".Mui-selected": {
      borderColor: "primary.main",
      backgroundColor: "primary.main",
    },
  },
}
