import React from "react"
import AppCardLoader from "core/components/AppCardLoader"
import {
  selectMachinesOverview,
  selectSiteMachines,
} from "store/Machines/machinesSlice"
import { getStatusColor } from "core/helpers"
import { MachineCollection } from "models/organization"
import { RootState } from "store/rootReducer"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

/* eslint-disable @typescript-eslint/no-empty-function */
import { Grid, useTheme } from "@mui/material"

import BaseCard from "../BaseCard"
import MachineOverviewItem from "./subcomponents/MachineOverviewItem"
import { styles } from "./styles"
import { minutesToMilliseconds } from "date-fns"
import { useAppGetOrganizationQuery } from "core/hooks/apiHooks"

const MachinesOverview = () => {
  const { t } = useTranslation()
  const theme = useTheme()

  const selectState = (state: RootState) => state.global

  const { isUserLogged } = useSelector(selectState)

  const { organizationId } = useSelector(
    (state: RootState) => state.organizations,
  )

  const {
    organizationData,
    fulfilledTimeStamp: timestamp,
    isOrganizationFetching,
    isOrganizationError,
  } = useAppGetOrganizationQuery(organizationId, {
    skip: !isUserLogged && !organizationId,
    pollingInterval: minutesToMilliseconds(60),
  })

  const totalMachines: MachineCollection[] = useSelector((state: RootState) =>
    selectSiteMachines(state, { ...organizationData }),
  )
  const totalNumberOfMachines = totalMachines.length

  const [selectedOverviewItem, _] =
    React.useState("machines")

  const machinesOverviewData = useSelector((state: RootState) =>
    selectMachinesOverview(state, totalMachines),
  )

  const hasDataAvailable =
    !!machinesOverviewData && !!Object.keys(machinesOverviewData).length

  const individualOverviewStatusItem = hasDataAvailable
    ? machinesOverviewData.overview.map((el: any, index: number) => ({
        ...el,
        color: getStatusColor(el.key, theme).main,
        selected: index === 1,
      }))
    : []

  const totalMachinesOverviewStatusItem = hasDataAvailable
    ? machinesOverviewData.machines
    : []

  const overviewItemsArray = hasDataAvailable
    ? [totalMachinesOverviewStatusItem, ...individualOverviewStatusItem]
    : []

  const handleOnRetry = () => console.debug("retry")

  const hasError = isOrganizationError || !hasDataAvailable

  return (
    <BaseCard
      lastUpdate={timestamp}
      sx={styles.card}
      title={t("machines.overview.text")}
    >
      <AppCardLoader
        isLoading={isOrganizationFetching}
        hasError={hasError}
        onRetry={handleOnRetry}
      >
        <Grid
          container
          justifyContent="space-between"
          spacing={1}
          sx={styles.gridContainer}
        >
          {overviewItemsArray.map(({ key: label, color, value }: any) => (
            <Grid key={label} item xs={6} md={2}>
              <MachineOverviewItem
                selected={label === selectedOverviewItem}
                label={label}
                key={label}
                color={color}
                value={value}
                total={totalNumberOfMachines}
                showPercentage
                // onClick={handleOnChange}
              />
            </Grid>
          ))}
        </Grid>
      </AppCardLoader>
    </BaseCard>
  )
}

export default MachinesOverview
