export const styles = {
  boxContainers: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  title: {
    textAlign: "justify",
    mb: 2,
    color: "text.secondary",
    lineHeight: "0.875rem",
  },
  icons: {
    mr: 1,
    height: "1.25rem",
    width: "1.25rem",
    alignItems: "center",
    display: "flex",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
  text: {
    fontSize: "0.938rem",
    lineHeight: "1.125rem",
  },
  locationText: {
    fontSize: "0.625rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    textTransform: "capitalize",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
} as const
