import { ZoomPluginOptions } from "chartjs-plugin-zoom/types/options"

export const zoomOptions = {
  zoom: {
    pan: {
      enabled: true,
      mode: "x",
      modifierKey: "shift",
    },
    zoom: {
      mode: "x",
      wheel: { enabled: true },
      drag: {
        enabled: true,
        borderColor: "rgb(54, 162, 235)",
        borderWidth: 1,
        backgroundColor: "rgba(54, 162, 235, 0.3)",
      },
    },
  },
} as ZoomPluginOptions

export const getGradient = (
  gradientFill: CanvasGradient,
  warningGradient: number,
  criticalGradient: number,
) => {
  if (
    warningGradient > 0 &&
    warningGradient <= 1 &&
    criticalGradient > 0 &&
    criticalGradient <= 1
  ) {
    gradientFill.addColorStop(0, "rgb(255,0,0)")
    gradientFill.addColorStop(criticalGradient, "rgb(255,0,0)")
    gradientFill.addColorStop(criticalGradient, "orange")
    gradientFill.addColorStop(warningGradient, "orange")
    gradientFill.addColorStop(warningGradient, "rgb(50, 186, 50)")
    gradientFill.addColorStop(1, "rgb(50, 186, 50)")

    return gradientFill
  }

  gradientFill.addColorStop(0, "rgb(255,0,0)")
  gradientFill.addColorStop(1, "rgb(50, 186, 50)")
  return gradientFill
}

export const lineColoring = (
  context: any,
  warnThreshold: number | null,
  critThreshold: number | null,
) => {
  const { chart } = context
  const { ctx, chartArea, scales } = chart
  if (!chartArea) return null
  if (!warnThreshold && typeof warnThreshold !== "number") return null
  if (!critThreshold && typeof critThreshold !== "number") return null

  const { right, bottom } = chartArea
  const warningGradient = scales.y.getPixelForValue(warnThreshold) / bottom
  const criticalGradient = scales.y.getPixelForValue(critThreshold) / bottom

  const angle = (90 * Math.PI) / 180
  const x2 = right * Math.cos(angle)
  const y2 = bottom * Math.sin(angle)
  const gradientFill = ctx.createLinearGradient(0, 0, x2, y2)
  return getGradient(gradientFill, warningGradient, criticalGradient)
}

export const onResizeColoring = (
  chart: any,
  warnThreshold: number | null,
  critThreshold: number | null,
) => {
  const { ctx, chartArea, scales } = chart
  if (!chartArea) return null
  const { right, bottom } = chartArea

  const warningGradient = scales.y.getPixelForValue(warnThreshold) / bottom
  const criticalGradient = scales.y.getPixelForValue(critThreshold) / bottom

  const angle = (90 * Math.PI) / 180
  const x2 = right * Math.cos(angle)
  const y2 = bottom * Math.sin(angle)

  const gradientFill = ctx.createLinearGradient(0, bottom, x2, y2)

  const model = chart?.data?.datasets[0]
  model.borderColor = getGradient(
    gradientFill,
    warningGradient,
    criticalGradient,
  )
  chart.update()
}

export const thresholdLine = (
  chart: any,
  _options: any,
  text: string,
  threshold: number | null,
) => {
  const {
    ctx,
    chartArea: { right, left },
    scales: { y },
  } = chart
  if (!threshold) return null
  const thresholdPixelValue = y.getPixelForValue(threshold)

  if (thresholdPixelValue < 0) return null

  ctx.save()
  ctx.beginPath()
  ctx.strokeStyle = text === "warning" ? "orange" : "red"
  ctx.lineWidth = 1
  ctx.moveTo(left, thresholdPixelValue)
  ctx.lineTo(right, thresholdPixelValue)
  ctx.stroke()
  ctx.closePath()
  ctx.restore()
}
